/* eslint @typescript-eslint/no-var-requires: "off" */
const plugin = require('tailwindcss/plugin')

/**
 * Creates a CSS adjacent sibling tailwind plugin.
 *
 * Example usage, set the next siblings' width to 10px:
 * className="next-sibling:w-10"
 *
 * The plugin also supports setting the properties of adjacent siblings that match an arbitrary class.
 * Use it like this to only apply the width for next siblings with the class 'example':
 * className="next-sibling-[example]:w-10"
 *
 */
const nextSibling = plugin(({ matchVariant }) => {
  matchVariant(
    'next-sibling',
    value => (value === 'default' ? '& + *' : `& + .${value}`),
    {
      values: {
        // The 'matchVariant' API is a bit experimental - need to set the undocumented DEFAULT values option here
        // to also match cases without an arbitrary class value (ie the 'default' case).
        DEFAULT: 'default',
      },
    }
  )
})

module.exports = nextSibling
