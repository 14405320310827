import React from 'react'
import LogoEqtFoundation from '../assets/brand/eqt-foundation.svg'
import LogoEqt from '../assets/brand/eqt.svg'

export interface LogoProps {
  className?: string
}

export const Logo = ({ className }: LogoProps) => {
  switch (process.env.GATSBY_SITE) {
    case 'eqt-funds':
      return <LogoEqt className={className} width="50" height="15" />
    case 'eqt-foundation':
      return <LogoEqtFoundation className={className} width="150" height="35" />
    case 'eqt-web':
    default:
      return <LogoEqt className={className} width="50" height="15" />
  }
}
