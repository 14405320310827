import React, { PropsWithChildren } from 'react'
import { InternalExternalLink } from '../../types/InternalExternalLink'
import { isInternalLink } from '../../utilities/string'
import { PillButton } from '../PillButton'

export interface NavigationPillButtonProps {
  currentPageUrl: string
  link: InternalExternalLink
  tabIndex?: number
}

export const NavigationPillButton = ({
  children,
  currentPageUrl,
  link,
  tabIndex,
}: PropsWithChildren<NavigationPillButtonProps>) => {
  // Use isInternalLink instead of link.isExternal here, just in case a manual url has to be entered in Sanity that
  // points to an internal page.
  const isExternal = !isInternalLink(link.url ?? '')
  const active = isExternal === false && currentPageUrl === link.url

  return (
    <PillButton
      active={active}
      isExternalUrl={isExternal}
      tabIndex={tabIndex}
      url={link.url}
    >
      {children}
    </PillButton>
  )
}
