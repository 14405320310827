import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export interface SectionProps {
  additionalClassName?: string
  as?: keyof JSX.IntrinsicElements
  id?: string
}

export const Section = ({
  additionalClassName,
  as: Root = 'section',
  children,
  id,
}: PropsWithChildren<SectionProps>) => (
  <Root
    className={classNames('my-60', 'sm:my-90', 'lg:my-120', additionalClassName)}
    id={id}
  >
    {children}
  </Root>
)
