import { IS_EQT_NEXUS_ATH } from './site'

export const getLinkUrl = (url: string): string => {
  if (!IS_EQT_NEXUS_ATH || process.env.NODE_ENV === 'development') {
    return url ?? ''
  }

  const redirectsMap = {
    '/investment-strategy':
      'https://pw.eqtgroup.com/private-wealth/private-equity/eqt-nexus',
    '/fund-information':
      'https://pw.eqtgroup.com/private-wealth/private-equity/eqt-nexus',
    '/contact': 'https://pw.eqtgroup.com/private-wealth/private-equity/eqt-nexus',
  }

  const findKey = Object.keys(redirectsMap).find(
    redirect => redirect === url || `${redirect}/` === url
  )

  return findKey ? redirectsMap[findKey] : url ?? ''
}
