import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby-link'
import React, { PropsWithChildren } from 'react'
import ExternalLinkIcon from '../assets/icons/external-link.svg'
import { CustomAttributes } from '../types/CustomAttributes'
import { getLinkUrl } from '../utilities/getLinkUrl'
import { hasFileExtension, isInternalLink } from '../utilities/string'
import { useSiteWideDisclaimer } from './Disclaimer/SiteWide/useSiteWideDisclaimer'

export enum ExternalIconSize {
  None,
  Sm,
  Md,
  Lg,
}

export interface LinkProps {
  className?: string
  customAttributes?: CustomAttributes
  download?: boolean | string
  externalIconSize?: ExternalIconSize
  hideContentIfNoAccess?: boolean
  id?: string
  isExternal?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  tabIndex?: number
  title?: string
  url: string
}

export const Link = ({
  children,
  customAttributes,
  externalIconSize = ExternalIconSize.None,
  hideContentIfNoAccess,
  id,
  isExternal = false,
  url,
  ...linkProps
}: PropsWithChildren<LinkProps>) => {
  const { hasAccessToPath } = useSiteWideDisclaimer()

  // GatsbyLink shouldnt be used for calendar files since it will add
  // a trailingslash to it and therefore breaking the calendar link
  // Treat them as external links with no icon and download attribute
  const isFileLink = hasFileExtension(url)

  if (isExternal || isFileLink) {
    // Sometimes relative urls are entered as external links in Sanity
    // for deep linking to filtered pages. Treat these as internal links (visually).
    const isActuallyInternal = isInternalLink(url)

    const showExternalLinkIcon =
      isExternal && externalIconSize !== ExternalIconSize.None && !isActuallyInternal

    let iconSizeClasses = ''

    if (showExternalLinkIcon) {
      switch (externalIconSize) {
        case ExternalIconSize.Sm:
          iconSizeClasses = 'h-8 w-8'
          break
        case ExternalIconSize.Md:
          iconSizeClasses = 'h-10 w-10'
          break
        case ExternalIconSize.Lg:
          iconSizeClasses = 'h-[12px] w-[12px]'
          break
        default:
          break
      }
    }

    const download = isFileLink ? true : linkProps.download

    return (
      <a
        {...linkProps}
        download={download}
        href={url}
        rel={isExternal && !isActuallyInternal ? 'nofollow' : undefined}
        {...customAttributes}
      >
        {children}

        {showExternalLinkIcon && (
          <ExternalLinkIcon
            className={classNames(
              'inline-block ml-5 align-baseline fill-current',
              iconSizeClasses
            )}
          />
        )}
      </a>
    )
  } else {
    if (hasAccessToPath(url)) {
      const href = getLinkUrl(url)
      if (customAttributes?.target === '_blank') {
        // GatsbyLink does not work with target: _blank (doesn't open the link in a new tab)
        return (
          <a {...linkProps} href={href} id={id} {...customAttributes}>
            {children}
          </a>
        )
      } else {
        return (
          <GatsbyLink {...linkProps} id={id} to={href} {...customAttributes}>
            {children}
          </GatsbyLink>
        )
      }
    } else {
      if (hideContentIfNoAccess) {
        // Hide links to pages that are behind a disclaimer / gated entry
        return null
      } else {
        return (
          <span className={linkProps.className} id={id}>
            {children}
          </span>
        )
      }
    }
  }
}
