import { SanityFileAsset } from '../../types/generated/graphql-types'
import siteConfig from '../gatsby/siteConfig'
import { isNullOrWhiteSpace } from './string'

// Sanity CDN sends a
//   content-security-policy: default-src 'self'; script-src 'none'
// header, which prevents ESEF XBRL xhtml files from displaying correctly (requires scripts).
// It also sends
//   content-disposition: inline
// which prevents <a href="..." download> from working.
// Adding ?dl to the url will change content-disposition to attachment.
// If no file name is specified in the dl parameter, the file name from Sanity will be used.
export const fixSanityCdnAttachmentUrl = (url: string): string =>
  url?.match(/cdn\.sanity\.io.*(\.zip|\.xhtml)$/) ? `${url}?dl` : url

export const rewriteDisclaimerFileUrl = (
  asset: SanityFileAsset,
  disclaimerPath?: string
) => {
  const disclaimerFilesPath = siteConfig.DisclaimerFilesPath

  if (!asset?.originalFilename) {
    console.error('Failed to rewrite file URLs, originalFilename missing')
    return null
  }

  if (isNullOrWhiteSpace(disclaimerFilesPath)) {
    console.error('Failed to rewrite file URLs, disclaimerFilesPath missing')
    return null
  }

  const url = `${disclaimerFilesPath}?fileId=${asset.originalFilename}`

  if (!isNullOrWhiteSpace(disclaimerPath)) {
    return `${url}&p=${disclaimerPath}`
  } else {
    return url
  }
}
