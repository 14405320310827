import { useCallback, useLayoutEffect, useRef } from 'react'
import { Breakpoints, compareToBreakpoint } from '../utilities/breakpoints'

export const useBreakpoint = (
  breakpoint: Breakpoints,
  callback: (eqAbove: boolean) => void
): void => {
  const state = useRef(false)

  const handleResize = useCallback(() => {
    const aboveBreakpoint = compareToBreakpoint(breakpoint)

    if (aboveBreakpoint === state.current) {
      return
    }

    state.current = aboveBreakpoint

    callback(aboveBreakpoint)
  }, [breakpoint, state, callback])

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    handleResize()

    window.addEventListener('resize', handleResize, { passive: true })
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])
}
