import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { InternalExternalLink } from '../../types/InternalExternalLink'
import { MainNavigationNode } from '../../types/MainNavigationNode'
import { findCurrentPageUrlNodeIndex } from '../../utilities/header'
import { isNullOrWhiteSpace } from '../../utilities/string'
import { ExternalIconSize, Link } from '../Link'
import { Transition } from '../Transition'
import { NavigationFlyoutLevel } from './NavigationFlyoutLevel'
import { NavigationFlyoutNode } from './NavigationFlyoutNode'
import { NavigationPillButton } from './NavigationPillButton'
import { SecondaryNavigationNode } from './SecondaryNavigationNode'

export interface NavigationFlyoutProps {
  currentPageUrl: string
  mainNavigationNodes: MainNavigationNode[]
  onNavigate?: (url: string) => void
  open: boolean
  pillLink1?: InternalExternalLink
  pillLink2?: InternalExternalLink
  searchBarOpen: boolean
  secondaryNavigationNodes: SecondaryNavigationNode[]
}

export const NavigationFlyout = ({
  currentPageUrl,
  mainNavigationNodes,
  onNavigate,
  open,
  pillLink1,
  pillLink2,
  searchBarOpen,
  secondaryNavigationNodes,
}: NavigationFlyoutProps): JSX.Element | null => {
  // Stop auto-opening the current page node if the user opens or closes a node
  const [autoOpen, setAutoOpen] = useState(true)

  // Find the index for this flyout level leading to the current page node
  const currentPageNodeIndex = findCurrentPageUrlNodeIndex(
    mainNavigationNodes,
    currentPageUrl
  )

  // Automatically open up the current page node when the flyout opens, if the node has any children.
  const initialSelectedNodeIndex =
    currentPageNodeIndex !== undefined &&
    mainNavigationNodes[currentPageNodeIndex].subitems.length > 0
      ? currentPageNodeIndex
      : undefined

  const [selectedNode, setSelectedNode] = useState<MainNavigationNode>()
  const [selectedNodeIndex, setSelectedNodeIndex] = useState<number | undefined>(
    initialSelectedNodeIndex
  )

  const [animationDurationClass, setAnimationDurationClass] = useState('duration-300')
  const closeFlyoutLevelAnimationDuration = 200

  // Keep selectedNode in sync with selectedNodeIndex
  useEffect(() => {
    if (selectedNodeIndex !== undefined) {
      const node = mainNavigationNodes[selectedNodeIndex]
      setSelectedNode(node)
    } else {
      setSelectedNode(undefined)
    }
  }, [mainNavigationNodes, selectedNodeIndex])

  const searchBarOpenTimer = useRef<NodeJS.Timeout>()
  const onSelectNodeTimer = useRef<NodeJS.Timeout>()

  const onSelectNode = (index: number) => {
    setAutoOpen(false)

    if (selectedNodeIndex === undefined) {
      setSelectedNodeIndex(index)
    } else {
      setSelectedNodeIndex(undefined)

      if (selectedNodeIndex !== index) {
        if (onSelectNodeTimer.current) {
          clearTimeout(onSelectNodeTimer.current)
        }
        onSelectNodeTimer.current = setTimeout(() => {
          setSelectedNodeIndex(index)
        }, closeFlyoutLevelAnimationDuration)
      }
    }
  }

  useEffect(() => {
    if (searchBarOpen) {
      setAnimationDurationClass('duration-200')
    } else {
      searchBarOpenTimer.current = setTimeout(() => {
        setAnimationDurationClass('duration-300')
      }, 200)
    }
  }, [searchBarOpen])

  useEffect(
    () => () => {
      if (onSelectNodeTimer.current) {
        clearTimeout(onSelectNodeTimer.current)
      }
      if (searchBarOpenTimer.current) {
        clearTimeout(searchBarOpenTimer.current)
      }
    },
    // Cleanup timer on unmount
    []
  )

  const pillButtonsVisible =
    (!isNullOrWhiteSpace(pillLink1?.title) && !isNullOrWhiteSpace(pillLink1?.url)) ||
    (!isNullOrWhiteSpace(pillLink2?.title) && !isNullOrWhiteSpace(pillLink2?.url))

  const selectedNodeHasSubitems = !!selectedNode?.subitems.length

  return (
    <Transition
      className={classNames(
        'fixed w-full h-full top-0 flex flex-col z-navbar-flyout-wrapper overflow-hidden col-none transform',
        'border-b border-neutral-lighter bg-site-background',
        animationDurationClass
      )}
      duration={parseInt(animationDurationClass.substring('duration-'.length))}
      enterFrom="-translate-y-full"
      enterTo={classNames('translate-y-0', {
        'top-90': searchBarOpen,
        'top-0': !searchBarOpen,
      })}
      leaveTo="-translate-y-full"
      onLeft={() => {
        // Reset the state when the flyout has fully closed
        setAutoOpen(true)
        setSelectedNodeIndex(initialSelectedNodeIndex)
      }}
      show={open}
    >
      <div className="overflow-x-hidden overflow-y-auto flex-grow">
        {/* For lg and above, change the grid column dimensions to fit each slide.
            This is due to absolute/relative grid columns seemingly not supporting negative margins,
            making it impossible to increase their width according to the design. */}
        <div
          className={classNames(
            'relative gap-x-30 justify-center h-full',
            'lg:grid lg:grid-cols-navbar-flyout-lg lg:auto-rows-auto lg:gap-x-0 lg:pt-0 lg:min-h-full',
            'xl:grid-cols-navbar-flyout-xl',
            'xxl:grid-cols-navbar-flyout-xxl'
          )}
        >
          <div
            // Setting background color on this grid cell for lg is a hacky way of making sure that it will overlap other flyout levels
            className={classNames(
              'grid grid-rows-navbar-flyout grid-cols-mobile justify-center gap-x-30 auto-rows-min',
              'tb:grid-cols-tb',
              'sm:grid-cols-sm',
              'md:grid-cols-md',
              'lg:col-start-1 lg:col-span-1 lg:grid-cols-1 lg:grid-rows-navbar-flyout-lg lg:h-full lg:pb-30 lg:ml-240 lg:z-navbar-flyout-0-lg',
              'lg:bg-site-background lg:border-r lg:border-neutral-lighter',
              'xl:ml-240',
              'xxl:ml-[24.375rem]'
            )}
          >
            {pillButtonsVisible && (
              <div
                className={classNames(
                  'col-span-full h-90 flex items-center mt-15 space-x-5 -ml-10',
                  'tb:items-start tb:h-auto tb:mt-30 tb:space-x-15 tb:ml-0',
                  'sm:col-start-2 sm:col-span-2',
                  'md:col-start-1 md:col-span-2',
                  'lg:col-span-full'
                )}
              >
                {pillLink1 &&
                  !isNullOrWhiteSpace(pillLink1.title) &&
                  !isNullOrWhiteSpace(pillLink1.url) && (
                    <NavigationPillButton
                      currentPageUrl={currentPageUrl}
                      link={pillLink1}
                    >
                      {pillLink1.title}
                    </NavigationPillButton>
                  )}

                {pillLink2 &&
                  !isNullOrWhiteSpace(pillLink2.title) &&
                  !isNullOrWhiteSpace(pillLink2.url) && (
                    <NavigationPillButton
                      currentPageUrl={currentPageUrl}
                      link={pillLink2}
                    >
                      {pillLink2.title}
                    </NavigationPillButton>
                  )}
              </div>
            )}

            <ul
              className={classNames(
                'row-start-2 flex flex-col col-span-full mx-45',
                'sm:col-start-2 sm:col-span-5 sm:mr-150',
                'md:col-start-1 md:col-span-6 md:-mr-15',
                'lg:col-span-full lg:mx-0 lg:bg-site-background'
              )}
            >
              {mainNavigationNodes.map((node, index) => (
                <NavigationFlyoutNode
                  active={index === selectedNodeIndex || index === currentPageNodeIndex}
                  key={index}
                  level={0}
                  node={node}
                  onClick={() => onSelectNode(index)}
                  onNavigate={onNavigate}
                />
              ))}
            </ul>

            {!!secondaryNavigationNodes.length && (
              <ul
                className={classNames(
                  'row-start-3 flex flex-col col-start-1 col-span-1 ml-45 -mr-120',
                  'sm:col-start-2 sm:col-span-5 sm:mr-150',
                  'md:col-start-1 md:col-span-6',
                  'lg:col-span-full lg:mx-0 lg:mt-15'
                )}
              >
                {secondaryNavigationNodes.map((node, index) => (
                  <li
                    className={classNames(
                      'mb-30 text-secondary-darker font-nav-flyout-complementary text-nav-flyout-complementary',
                      'lg:mb-10'
                    )}
                    key={index}
                  >
                    <Link
                      className="pb-2 transition-hover border-b border-transparent hover:border-quinary"
                      customAttributes={node.link.customAttributes}
                      externalIconSize={ExternalIconSize.Md}
                      hideContentIfNoAccess
                      isExternal={node.link.isExternal}
                      onClick={() => onNavigate?.(node.link.url ?? '')}
                      url={node.link.url ?? ''}
                    >
                      {node.link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <NavigationFlyoutLevel
            autoOpen={autoOpen}
            currentPageUrl={currentPageUrl}
            level={1}
            node={selectedNodeHasSubitems ? selectedNode : undefined}
            onGoBack={() => setSelectedNodeIndex(undefined)}
            onNavigate={onNavigate}
          />
        </div>
      </div>
    </Transition>
  )
}
