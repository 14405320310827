export const getCookieValue = (name: string) =>
  document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1]

export const setCookie = (name: string, value: string, maxAgeInSeconds?: number) => {
  let cookieStr = `${name}=${value};path=/`

  if (maxAgeInSeconds !== undefined) {
    cookieStr += `;max-age=${maxAgeInSeconds}`
  }

  document.cookie = cookieStr
}
