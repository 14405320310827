import {
  SanityBackgroundVideo,
  SanityBasicBackgroundVideo,
  SanityBasicImage,
  SanityVideo,
  SanityVideoAutoplayPreview,
  SanityVideoCampaignHero,
  SanityVideoHeader,
  SanityVideoHero,
} from '../../types/generated/graphql-types'
import { ImageProps } from '../components/Image'
import { AspectRatio } from '../components/VideoAspectRatio'
import { VideoCampaignHeroProps } from '../components/VideoCampaignHero'
import { VideoComponentProps } from '../components/VideoComponent'
import { VideoComponentWithHeaderProps } from '../components/VideoComponentWithHeader'
import { VideoHeroProps } from '../components/VideoHero'
import { VimeoVideoProps } from '../components/VimeoVideo'
import { Breakpoints } from '../utilities/breakpoints'
import { validImage } from '../utilities/imageFilters'
import { Presets } from '../utilities/imagePreset'
import { getImagePropsWithSources } from '../utilities/imagePropUtils'
import { mapSanityLinkToInternalExternalLink } from './LinkMapper'

export enum VideoType {
  Large,
  Small,
}

export const getVideoHeaderPreviewImageProps = (
  image: SanityBasicImage
): ImageProps | undefined =>
  validImage(image)
    ? getImagePropsWithSources(
        image,
        {
          small: Presets.videoPreviewHeader.small,
          medium: Presets.videoPreviewHeader.medium,
          large: Presets.videoPreviewHeader.large,
          extraLarge: Presets.videoPreviewHeader.extraLarge,
        },
        Breakpoints.tb,
        Breakpoints.lg,
        Breakpoints.xxl,
        100
      )
    : undefined

export const getVideoPreviewImagePropsLarge = (
  image: SanityBasicImage
): ImageProps | undefined =>
  validImage(image)
    ? getImagePropsWithSources(
        image,
        {
          small: Presets.videoPreviewLarge.small,
          medium: Presets.videoPreviewLarge.medium,
          large: Presets.videoPreviewLarge.large,
          extraLarge: Presets.videoPreviewLarge.extraLarge,
        },
        Breakpoints.tb,
        Breakpoints.lg,
        Breakpoints.xxl,
        100
      )
    : undefined

export const getVideoPreviewImagePropsSmall = (
  image: SanityBasicImage
): ImageProps | undefined =>
  validImage(image)
    ? getImagePropsWithSources(
        image,
        {
          small: Presets.videoPreviewSmall.small,
          medium: Presets.videoPreviewSmall.medium,
          large: Presets.videoPreviewSmall.large,
          extraLarge: Presets.videoPreviewSmall.extraLarge,
        },
        Breakpoints.sm,
        Breakpoints.lg,
        Breakpoints.xxl,
        100
      )
    : undefined

export const sanityVideoToVideoComponentProps = (
  video: SanityVideo,
  type: VideoType
): VideoComponentProps => {
  let imageProps: ImageProps | undefined = undefined

  if (video.image) {
    switch (type) {
      case VideoType.Large:
        imageProps = getVideoPreviewImagePropsLarge(video.image)
        break
      case VideoType.Small:
        imageProps = getVideoPreviewImagePropsSmall(video.image)
        break
    }
  }

  return {
    previewImage: imageProps,
    title: video.title ?? '',
    videoUrl: video.url ?? '',
  }
}

export const sanityVideoAutoplayPreviewToVideoComponentProps = (
  video: SanityVideoAutoplayPreview
): VideoComponentProps => ({
  previewVideoTitle: video.autoplayVideoTitle ?? undefined,
  previewVideoUrl: video.autoplayVideoUrl ?? undefined,
  title: video.title ?? '',
  videoUrl: video.url ?? undefined,
})

export const sanityBackgroundVideoToFlashcardVideo = (
  video: SanityBackgroundVideo,
  titleFallback?: string
): VimeoVideoProps => ({
  title: video.title ?? titleFallback ?? '',
  videoUrl: video.url ?? '',
  previewImage: validImage(video.image)
    ? getImagePropsWithSources(
        video.image,
        {
          small: Presets.flashcardPreviewImage.small,
          medium: Presets.flashcardPreviewImage.large,
        },
        Breakpoints.sm
      )
    : undefined,
})

export const sanityBasicBackgroundVideoToVideoTeaserPreviewImage = (
  video: SanityBasicBackgroundVideo
): ImageProps | undefined =>
  validImage(video?.image)
    ? getImagePropsWithSources(
        video.image,
        {
          small: Presets.videoTeaser.small,
          medium: Presets.videoTeaser.medium,
          large: Presets.videoTeaser.large,
          extraLarge: Presets.videoTeaser.extraLarge,
        },
        Breakpoints.sm,
        Breakpoints.md,
        Breakpoints.lg
      )
    : undefined

export const sanityBasicBackgroundVideoToVideoHeroPreviewImage = (
  video: SanityBasicBackgroundVideo
): ImageProps | undefined =>
  validImage(video?.image)
    ? getImagePropsWithSources(
        video.image,
        {
          small: Presets.videoHero.small,
          medium: Presets.videoHero.medium,
          large: Presets.videoHero.large,
          extraLarge: Presets.videoHero.extraLarge,
        },
        Breakpoints.sm,
        Breakpoints.md,
        Breakpoints.xl
      )
    : undefined

export const sanityVideoHeaderToVideoComponentWithHeaderProps = (
  video: SanityVideoHeader
): VideoComponentWithHeaderProps => ({
  aspectRatio: AspectRatio['64/27'],
  hideControlsAndAutoplay: video.autoplay ?? undefined,
  previewImage: video.image ? getVideoHeaderPreviewImageProps(video.image) : undefined,
  subtitle: video.subtitle ?? '',
  theme: video.theme
    ? mapSanityLinkToInternalExternalLink(video.theme) ?? undefined
    : undefined,
  title: video.title ?? '',
  videoUrl: video.url ?? '',
})

export const sanityVideoHeroToVideoHeroProps = ({
  preamble,
  image,
  title,
  video,
  videoMobile,
}: SanityVideoHero): VideoHeroProps => ({
  preamble: preamble ?? '',
  previewImage: image ? getVideoHeaderPreviewImageProps(image) : undefined,
  title: title ?? '',
  videoUrl: video?.url ?? undefined,
  videoUrlMobile: videoMobile?.url ?? undefined,
})

export const sanityVideoCampaignHeroToVideoHeroProps = (
  { buttonLabel, previewVideo, subtitle, theme, title, video }: SanityVideoCampaignHero,
  pageTitle?: string
): VideoCampaignHeroProps => ({
  buttonLabel: buttonLabel ?? '',
  subtitle: subtitle ?? '',
  theme: theme ?? '',
  previewImage: previewVideo
    ? sanityBasicBackgroundVideoToVideoHeroPreviewImage(previewVideo)
    : undefined,
  title: title ?? pageTitle,
  videoUrl: video?.url ?? undefined,
  previewVideoUrl: previewVideo?.url ?? undefined,
})
