import { PortableTextListItemComponent } from '@portabletext/react'
import classNames from 'classnames'
import React from 'react'

export const DefaultListItemSerializer: PortableTextListItemComponent = ({
  children,
  value,
}) => {
  let className: string

  if (value?.listItem === 'number') {
    className = classNames(
      'number-item',
      value.level !== undefined
        ? {
            'li-level-2': value.level % 3 === 2,
            'li-level-3': value.level % 3 === 0,
          }
        : undefined
    )
  } else {
    className = 'bullet-item before:text-primary'
  }

  return (
    <li className={className}>
      {value?.listItem === 'number' && <span className="li-placeholder"></span>}
      {children}
    </li>
  )
}
