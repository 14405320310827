import classNames from 'classnames'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTimer } from '../hooks/useTimer'

export type TransitionProps = {
  appear?: boolean
  as?: React.ElementType
  className?: string
  duration: number
  enter?: string
  enterFrom?: string
  enterTo?: string
  leave?: string
  leaveFrom?: string
  leaveTo?: string
  onEnter?: () => void
  onEntered?: () => void
  onLeave?: () => void
  onLeft?: () => void
  show?: boolean
} & Omit<React.HTMLProps<HTMLDivElement>, 'as'>

/**
 * Generic transition component. Also handles mount/unmount transitions gracefully. Make sure to set the appear
 * prop to true if the enter animation should play during mount.
 * @returns
 */
export const Transition = ({
  appear,
  as: Root = 'div',
  children,
  className,
  duration: durationProps,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  onEnter,
  onEntered,
  onLeave,
  onLeft,
  show,
  ...rest
}: PropsWithChildren<TransitionProps>) => {
  const getInitialAnimationClassName = () => {
    if (appear) {
      return show ? enterFrom : leaveFrom
    } else {
      return show ? enterTo : leaveTo
    }
  }

  const [animationClassName, setAnimationClassName] = useState<string | undefined>(
    getInitialAnimationClassName()
  )

  const [skipAnimation, setSkipAnimation] = useState(!appear)

  const timer = useTimer()

  const triggerAnimation = useCallback(
    (
      from: string | undefined,
      during: string | undefined,
      to: string | undefined,
      callback: (() => void) | undefined,
      doneCallback: (() => void) | undefined,
      duration: number
    ) => {
      if (timer.isRunning()) {
        timer.stop()
      }

      callback?.()

      setAnimationClassName(classNames(from, during))

      window.requestAnimationFrame(() => {
        setAnimationClassName(classNames(to, during))
      })

      timer.start(() => {
        setAnimationClassName(to)
        doneCallback?.()
      }, duration)
    },
    [timer]
  )

  useEffect(() => {
    if (skipAnimation) {
      // skip initial animation
      setSkipAnimation(false)
    } else if (show) {
      triggerAnimation(enterFrom, enter, enterTo, onEnter, onEntered, durationProps)
    } else {
      triggerAnimation(leaveFrom, leave, leaveTo, onLeave, onLeft, durationProps)
    }

    // No need to trigger the effect if classes or callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Root {...rest} className={classNames(className, animationClassName)}>
      {children}
    </Root>
  )
}
