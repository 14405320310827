import { PortableTextListComponent } from '@portabletext/react'
import classNames from 'classnames'
import React from 'react'

export const commonListClassName = 'font-body text-body mb-30 last:mb-0'

export const DefaultListSerializer: PortableTextListComponent = ({ children, value }) =>
  value.listItem === 'bullet' ? (
    <ul
      className={classNames(
        commonListClassName,
        'text-secondary-darker bullet align-middle'
      )}
    >
      {children}
    </ul>
  ) : (
    <ol className={classNames(commonListClassName, 'text-secondary-darker number')}>
      {children}
    </ol>
  )
