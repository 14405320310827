import classNames from 'classnames'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslations } from '../hooks/useTranslations'
import { InternalExternalLink } from '../types/InternalExternalLink'
import { isNullOrWhiteSpace } from '../utilities/string'
import { getValidVideo } from '../utilities/video'
import { Button } from './Button'
import { Grid } from './Grid'
import { Image, ImageProps } from './Image'
import {
  AspectRatio,
  videoAspectRatioClasses,
  VideoAspectRatioWrapper,
} from './VideoAspectRatio'

export interface VideoComponentProps {
  aspectRatio?: AspectRatio
  aspectRatioSm?: AspectRatio
  aspectRatioLg?: AspectRatio
  autoPlayWithSound?: boolean
  displayDivider?: boolean
  hideControlsAndAutoplay?: boolean
  loading?: 'eager' | 'lazy'
  /**
   * Show this link instead of the watch button. Used on ShareholdersMainPage.
   */
  overrideLink?: InternalExternalLink
  previewImage?: ImageProps
  previewVideoTitle?: string
  previewVideoUrl?: string
  title?: string
  videoUrl?: string
  imgClassName?: string
}

export const VideoComponent = ({
  aspectRatio = AspectRatio['16/9'],
  aspectRatioSm,
  aspectRatioLg,
  autoPlayWithSound = false,
  children,
  displayDivider,
  hideControlsAndAutoplay,
  loading,
  overrideLink,
  previewImage,
  previewVideoTitle,
  previewVideoUrl,
  title,
  videoUrl,
  imgClassName = '',
}: PropsWithChildren<VideoComponentProps>): JSX.Element => {
  const translations = useTranslations()

  const previewVideo = getValidVideo(previewVideoUrl, ['vimeo'])
  if (previewVideo) {
    previewVideo.url.searchParams.append('background', '1')
  }

  const hasPreview = previewImage || previewVideo
  const [showVideo, setShowVideo] = useState(!hasPreview)

  const video = getValidVideo(videoUrl)

  if (video) {
    if (video.service === 'youtube') {
      video.url.searchParams.append('rel', '0')
      video.url.searchParams.append('modestbranding', '1')
    }

    if (autoPlayWithSound) {
      video.url.searchParams.append('autoplay', '1')
    } else {
      video.url.searchParams.append('mute', '1')

      if (hasPreview) {
        video.url.searchParams.append('autoplay', '1')
      }

      if (hideControlsAndAutoplay) {
        video.url.searchParams.append('background', '1')
      }
    }
  }

  const playVideo = () => {
    setShowVideo(true)
  }

  const hasOverrideLink =
    overrideLink &&
    !isNullOrWhiteSpace(overrideLink?.url) &&
    !isNullOrWhiteSpace(overrideLink?.title)

  return (
    <>
      <VideoAspectRatioWrapper
        aspectRatio={aspectRatio}
        aspectRatioSm={aspectRatioSm}
        aspectRatioLg={aspectRatioLg}
      >
        {/* Hide overlay if video is playing on youtube */}
        {video?.service === 'youtube' && showVideo ? null : (
          <Grid
            additionalClassName={classNames(
              'hidden z-10 pointer-events-none',
              'tb:grid tb:absolute tb:inset-0 tb:w-full tb:pt-30 tb:justify-center',
              'sm:pt-60'
            )}
            contentAlignment="place-content-start"
          >
            {(hideControlsAndAutoplay || !showVideo) && children}
          </Grid>
        )}

        {!showVideo && (
          <>
            {previewImage && (
              <Image
                {...previewImage}
                applyLqip
                caption=""
                className={videoAspectRatioClasses}
                copyright=""
                imgClassName={classNames('object-cover w-full h-full', imgClassName)}
                loading="lazy"
              />
            )}

            {previewVideo && (
              <iframe
                allow="autoplay"
                className="absolute w-full h-full left-0 top-0"
                loading={loading}
                src={previewVideo.url.toString()}
                title={!isNullOrWhiteSpace(previewVideoTitle) ? previewVideoTitle : title}
              />
            )}

            {(video || hasOverrideLink) && (
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
                {hasOverrideLink ? (
                  <Button url={overrideLink.url} isExternalUrl={overrideLink.isExternal}>
                    {overrideLink.title}
                  </Button>
                ) : (
                  <Button onClick={playVideo}>{translations.watch ?? ''}</Button>
                )}
              </div>
            )}
          </>
        )}

        {showVideo && video && (
          <iframe
            width="100%"
            height="100%"
            allow="autoplay; fullscreen"
            className="absolute w-full h-full left-0 top-0"
            loading={loading}
            src={video.url.toString()}
            title={title}
          />
        )}
      </VideoAspectRatioWrapper>

      {!!displayDivider && <div className="w-full bg-primary h-60 sm:h-90"></div>}
    </>
  )
}
