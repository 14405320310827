import { PortableTextBlockComponent } from '@portabletext/react'
import React from 'react'
import { getBlockStyleWithFallback } from '../../../../utilities/portableText'

export const TableBlockContentSerializer: PortableTextBlockComponent = ({
  children,
  value,
}) => {
  let HtmlElement: keyof JSX.IntrinsicElements | null = null
  let classes: string | undefined = undefined

  if (!value) {
    return null
  }

  const style = getBlockStyleWithFallback(value.style)

  switch (style) {
    case 'normal':
      HtmlElement = 'p'
      classes = 'text-secondary-darker font-t-light text-t-200 sm:text-t-300'
      break

    default:
      // In most use cases, the "style" maps to an actual HTML tag name
      HtmlElement = style as keyof JSX.IntrinsicElements
      break
  }

  return <HtmlElement className={classes}>{children}</HtmlElement>
}
