import React from 'react'
import UpArrow from '../assets/icons/arrow.svg'

export interface ArrowIconProps {
  additionalClassName?: string
  direction?: 'down' | 'left' | 'right' | 'up'
}

export const ArrowIcon = ({
  additionalClassName = '',
  direction,
}: ArrowIconProps): JSX.Element => {
  let rotation: string

  switch (direction) {
    case 'right':
      rotation = 'rotate-270'
      break
    case 'up':
      rotation = 'rotate-180'
      break
    case 'left':
      rotation = 'rotate-90'
      break
    default:
    case 'down':
      rotation = ''
      break
  }

  return (
    <UpArrow className={`fill-current transform ${rotation} ${additionalClassName}`} />
  )
}
