import classNames from 'classnames'
import React from 'react'
import { MainNavigationNode } from '.'
import { isInternalLink, isNullOrWhiteSpace } from '../../utilities/string'
import { ArrowIcon } from '../ArrowIcon'
import { Chevron } from '../Chevron'
import { ExternalIconSize, Link } from '../Link'

export interface NavigationFlyoutNodeProps {
  active?: boolean
  children?: React.ReactNode
  hideSubitems?: boolean
  level: number
  node: MainNavigationNode
  onClick: () => void
  onNavigate?: (url: string) => void
  showChevronOnInternalLink?: boolean
  variant?: 'normal' | 'inline'
}

export const NavigationFlyoutNode = ({
  active,
  children,
  hideSubitems = false,
  level,
  node,
  onClick,
  onNavigate,
  showChevronOnInternalLink = false,
  variant = 'normal',
}: NavigationFlyoutNodeProps): JSX.Element => {
  let linkClassName = 'relative group'

  let listItemMargin: string

  let accentClassName = classNames('absolute top-full left-0 block w-full h-2', {
    'bg-quinary': active,
    'bg-transparent transition-hover group-hover:bg-neutral-lighter-alt group-focus:bg-primary-alpha-25':
      !active,
  })

  if (variant === 'inline') {
    linkClassName = classNames(
      linkClassName,
      'text-secondary-dark uppercase font-nav-flyout-subsegment-link text-nav-flyout-subsegment-link'
    )

    listItemMargin = 'mt-15 lg:mt-10'

    accentClassName = classNames(accentClassName, 'mt-2')
  } else {
    linkClassName = classNames(
      linkClassName,
      'inline-block text-secondary-darker transition-hover cursor-pointer focus:outline-none',
      {
        'font-nav-flyout-level-1 text-nav-flyout-level-1': level === 0,
        'font-nav-flyout-sublevels text-nav-flyout-sublevel': level !== 0,
      }
    )

    listItemMargin = classNames({
      'mb-30': level === 0,
      'mb-20': level !== 0,
    })

    accentClassName = classNames(accentClassName, {
      'mt-10': level === 0,
      'mt-5': level !== 0,
    })
  }

  const hasSubitems = !!node.subitems.length

  return (
    <li className={classNames('flex flex-wrap', listItemMargin)}>
      {hasSubitems && !hideSubitems ? (
        <button className={classNames(linkClassName, 'text-left')} onClick={onClick}>
          {node.link.title}

          <ArrowIcon
            additionalClassName={classNames(
              'w-10 h-10 mb-2 inline-block align-middle',
              variant === 'inline' ? 'ml-8' : 'ml-15'
            )}
            direction="right"
          />

          <span className={accentClassName} aria-hidden="true" />
        </button>
      ) : isNullOrWhiteSpace(node.link.url) && hideSubitems ? (
        <span className={linkClassName}>{node.link.title}</span>
      ) : (
        <Link
          className={linkClassName}
          customAttributes={node.link.customAttributes}
          externalIconSize={ExternalIconSize.Lg}
          hideContentIfNoAccess
          isExternal={node.link.isExternal}
          onClick={() => onNavigate?.(node.link.url ?? '')}
          url={node.link.url ?? ''}
        >
          {node.link.title}

          {showChevronOnInternalLink &&
            !isNullOrWhiteSpace(node.link.url) &&
            isInternalLink(node.link.url) && (
              <Chevron
                additionalClassName="ml-10 mb-2"
                direction="right"
                width="w-7"
                height="h-7"
              />
            )}

          <span className={accentClassName} aria-hidden="true" />
        </Link>
      )}
      {children}
    </li>
  )
}
