import classNames from 'classnames'
import React from 'react'
export interface ChevronProps {
  additionalClassName?: string
  borderColor?: string
  direction?: 'down' | 'left' | 'right' | 'up'
  height?: string
  transitionClassName?: string
  width?: string
}

export const Chevron: React.FC<ChevronProps> = ({
  additionalClassName,
  direction = 'down',
  height = 'h-10',
  transitionClassName = 'duration-200',
  width = 'w-10',
}) => {
  let rotation: string

  switch (direction) {
    case 'left':
      rotation = 'rotate-45'
      break
    case 'up':
      rotation = 'rotate-135'
      break
    case 'right':
      rotation = 'rotate-225'
      break
    default:
    case 'down':
      rotation = 'rotate-315 -translate-y-1/2'
      break
  }

  return (
    <span
      className={classNames(
        'flex-none inline-block border-current border-l-2 border-b-2 transform',
        width,
        height,
        rotation,
        transitionClassName,
        additionalClassName
      )}
    ></span>
  )
}
