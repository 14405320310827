import {
  Maybe,
  SanityBasicImage,
  SanityImage,
  SanityPhoto,
} from '../../types/generated/graphql-types'
import { ImageProps, Source } from '../components/Image'
import imageBuilder from '../sanity/imageBuilder'
import { NullCheckedSanityImage } from '../types/NullCheckedSanityImage'
import { Breakpoints, resolveBreakpointName } from './breakpoints'
import { PresetDefinition, SourceDefinition } from './imagePreset'

/**
 * Get ImageProps for a processed image based on a preset
 * @param image The image to process
 * @param preset Predefined source dimensions
 * @param mediumBreakpoint Breakpoint for medium source, default Breakpoints.tb
 * @param largeBreakpoint Breakpoint for large source, default Breakpoints.tg
 * @param extraLargeBreakpoint Breakpoint for extralarge source, default Breakpoints.xl
 * @param quality (optional) Quality of the image, a number with the range 0-100
 * @param altText (optional) Custom altText for image instead of retrieving alt from image
 */
export const getImagePropsWithSources = (
  image: NullCheckedSanityImage<SanityPhoto | SanityImage>,
  preset: SourceDefinition,
  mediumBreakpoint: Breakpoints = Breakpoints.tb,
  largeBreakpoint: Breakpoints = Breakpoints.lg,
  extraLargeBreakpoint: Breakpoints = Breakpoints.xl,
  quality?: number | null,
  altText?: string | null
): ImageProps => {
  const sources: Source[] = []

  if (preset.extraLarge) {
    sources.push(
      getImageSource(
        image,
        preset.extraLarge,
        resolveBreakpointName(extraLargeBreakpoint),
        quality
      )
    )
  }

  if (preset.large) {
    sources.push(
      getImageSource(image, preset.large, resolveBreakpointName(largeBreakpoint), quality)
    )
  }

  if (preset.medium) {
    sources.push(
      getImageSource(
        image,
        preset.medium,
        resolveBreakpointName(mediumBreakpoint),
        quality
      )
    )
  }

  const imageProps = getImageProps(image, preset.small, quality, altText)
  imageProps.sources = sources

  return imageProps
}

const getAltText = (
  image: NullCheckedSanityImage<SanityPhoto | SanityImage>,
  altText?: string | null
) => {
  if (altText && altText?.length > 0) {
    return altText
  }

  return (
    image.asset.altText ??
    (image as SanityPhoto)?.altText ??
    (image as SanityPhoto)?.caption ??
    image.asset.title ??
    image._key ??
    ''
  )
}

/**
 * Get ImageProps for a processed image based on a preset
 * @param image The image to process
 * @param preset Predefined dimensions
 * @param quality (optional) Quality of the image, a number with the range 0-100
 * @param altText (optional) Custom altText for image instead of retrieving alt from image
 */
export const getImageProps = (
  image: NullCheckedSanityImage<SanityPhoto | SanityImage>,
  preset: PresetDefinition | null,
  quality?: number | null,
  altText?: string | null
): ImageProps => {
  let width: number | undefined
  let height: number | undefined
  let builder = imageBuilder.image(image)

  if (preset) {
    switch (preset.fit) {
      case 'crop':
        builder = builder.size(preset.width, preset.height).fit(preset.fit)

        width = preset.width
        height = preset.height

        if (preset.gravity) {
          builder = builder.crop(preset.gravity)
        }
        break
      case 'fill':
      case 'fillmax':
        builder = builder
          .size(preset.width, preset.height)
          .fit(preset.fit)
          .focalPoint(0.5, 0.5) // Center
          .bg(preset.backgroundColor)

        width = preset.width
        height = preset.height

        if (preset.pad) {
          builder = builder.pad(preset.pad)
        }
        break
      case 'scale':
        builder = builder.maxWidth(preset.maxWidth).fit(preset.fit)
        break
    }

    if (quality && quality >= 0 && quality <= 100) {
      builder = builder.quality(quality)
    }
  }

  if (!width) {
    width = image.asset?.metadata?.dimensions?.width ?? undefined
  }

  if (!height) {
    height = image.asset?.metadata?.dimensions?.height ?? undefined
  }

  return {
    altText: getAltText(image, altText),
    lqip: image.asset.metadata?.lqip ?? undefined,
    src: builder.auto('format').url() ?? '',
    width,
    height,
  }
}

/**
 * Get ImageProps for a processed image based on a preset
 * @param image The image to process
 * @param preset Predefined dimensions
 * @param breakpointNumber (optional) minimumBreakpoint to set for source, defaults to Breakpoints.lg
 * @param quality (optional) Quality of the image, a number with the range 0-100
 */
export const getImageSource = (
  image: NullCheckedSanityImage<SanityPhoto | SanityImage>,
  preset: PresetDefinition | null,
  breakpointNumber?: number,
  quality?: number | null
) => {
  const imageProps = getImageProps(image, preset, quality)

  const source: Source = {
    srcSet: imageProps.src ?? '',
    minimumBreakpoint: breakpointNumber ?? resolveBreakpointName(Breakpoints.lg),
  }

  if (imageProps.width && imageProps.height) {
    source.width = imageProps.width
    source.height = imageProps.height
  }

  return source
}

export const generateImagesPreloadData = (
  images?: Array<SanityBasicImage | Maybe<SanityPhoto> | undefined>
) => {
  if (!images || !Array.isArray(images)) {
    return []
  }

  return images.reduce((res: { source: string; type: string }[], image) => {
    const asset = image?.asset
    const source = asset?.url
    const type = asset?.metadata?.lqip?.match('image/[a-zA-Z0-9]+')?.[0] ?? ''

    if (source && type) {
      res.push({ source, type })
    }

    return res
  }, [])
}
