import { getCookieValue } from '../../utilities/cookie'

export interface VisibilityRules {
  key
}

export interface CountryRule extends VisibilityRules {
  countries: string[]
}

const instanceOfCountryRule = (object: VisibilityRules): object is CountryRule =>
  'countries' in object

const getVisitorCountry = (query?: string) => {
  const queryParams = query
    ? new URLSearchParams(query.slice(1))
    : new URLSearchParams('')

  if (!!queryParams && queryParams.get('cf-country')) {
    return queryParams.get('cf-country')
  }

  const countryCookie = getCookieValue('eqt_country')
  return countryCookie ?? ' '
}

export const checkBannerVisibility = (rules?: VisibilityRules[], query?: string) => {
  if (!rules || rules.length === 0) {
    return true
  }

  const matches = rules.filter(rule => {
    if (instanceOfCountryRule(rule)) {
      const visitorCountry = getVisitorCountry(query)
      if (!!visitorCountry) {
        const countryMatches = rule.countries.filter(
          country =>
            country.toLocaleLowerCase() === visitorCountry?.toLocaleLowerCase() ?? ''
        )

        return !!countryMatches && countryMatches.length > 0
      }
    }

    return false
  })

  return !!matches && matches.length > 0 && matches.length === rules.length
}
