import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { MainNavigationNode } from '../../types/MainNavigationNode'
import { findCurrentPageUrlNodeIndex } from '../../utilities/header'
import { isInternalLink, isNullOrWhiteSpace } from '../../utilities/string'
import { ArrowIcon } from '../ArrowIcon'
import { Chevron } from '../Chevron'
import { ExternalIconSize } from '../Link'
import { LinkWrapper } from '../LinkWrapper'
import { NavigationFlyoutNode } from './NavigationFlyoutNode'

export interface NavigationFlyoutLevelProps {
  autoOpen: boolean
  currentPageUrl: string
  level: number
  node: MainNavigationNode | undefined
  onGoBack: () => void
  onNavigate?: (url: string) => void
}

export const NavigationFlyoutLevel = ({
  autoOpen: autoOpenProps,
  currentPageUrl,
  level,
  node,
  onGoBack,
  onNavigate,
}: NavigationFlyoutLevelProps): JSX.Element => {
  // Stop auto-opening the current page node if the user opens or closes a node
  const autoOpen = useRef(autoOpenProps)

  // Find the index for this flyout level leading to the current page node
  const currentPageNodeIndex = node
    ? findCurrentPageUrlNodeIndex(node.subitems, currentPageUrl)
    : undefined

  let currentPageSubNodeIndex: number | undefined = undefined
  if (currentPageNodeIndex !== undefined) {
    const parentNode = node?.subitems[currentPageNodeIndex]
    if (parentNode && parentNode.renderSubitemsInline && !!parentNode.subitems.length) {
      currentPageSubNodeIndex = findCurrentPageUrlNodeIndex(
        parentNode.subitems,
        currentPageUrl
      )
    }
  }

  // Keep a local copy of the main node so that we can keep it around while animating closing animations
  const [nodeCopy, setNodeCopy] = useState<MainNavigationNode | undefined>(node)
  const [selectedNode, setSelectedNode] = useState<MainNavigationNode>()
  const [selectedNodeIndex, setSelectedNodeIndex] = useState<number>()
  const [selectedSubNodeIndex, setSelectedSubNodeIndex] = useState<number>()
  const animationDuration = 200

  useEffect(() => {
    autoOpen.current = autoOpenProps
  }, [autoOpenProps])

  // Clear selected node when closing or changing the main node
  // Clear the main node copy after a delay if closing
  useEffect(() => {
    if (node) {
      setNodeCopy(node)
      if (autoOpen.current) {
        setSelectedNodeIndex(currentPageNodeIndex)
        setSelectedSubNodeIndex(currentPageSubNodeIndex)
      }
    } else {
      setSelectedNodeIndex(undefined)
      setSelectedSubNodeIndex(undefined)

      const timer = setTimeout(() => {
        setNodeCopy(undefined)
      }, animationDuration)

      return () => clearTimeout(timer)
    }
  }, [currentPageNodeIndex, currentPageSubNodeIndex, node])

  // Keep selectedNode in sync with selectedNodeIndex
  useEffect(() => {
    if (selectedNodeIndex !== undefined) {
      let subNode: MainNavigationNode | undefined = undefined
      if (selectedSubNodeIndex !== undefined) {
        subNode = nodeCopy?.subitems[selectedNodeIndex]?.subitems[selectedSubNodeIndex]
      } else {
        subNode = nodeCopy?.subitems[selectedNodeIndex]
        if (subNode?.renderSubitemsInline && !!subNode.subitems.length) {
          // don't expand parent of inline subitems, even if it's the current page
          subNode = undefined
        }
      }

      setSelectedNode(subNode)
    } else {
      setSelectedNode(undefined)
    }
  }, [nodeCopy, selectedNodeIndex, selectedSubNodeIndex])

  const onSelectNodeTimer = useRef<NodeJS.Timeout>()

  const onSelectNode = (index: number) => {
    autoOpen.current = false
    setSelectedSubNodeIndex(undefined)

    if (selectedNodeIndex === undefined) {
      setSelectedNodeIndex(index)
    } else {
      if (!!selectedNode?.subitems.length) {
        setSelectedNodeIndex(undefined)

        if (selectedNodeIndex !== index) {
          if (onSelectNodeTimer.current) {
            clearTimeout(onSelectNodeTimer.current)
          }
          onSelectNodeTimer.current = setTimeout(() => {
            setSelectedNodeIndex(index)
          }, animationDuration)
        }
      } else {
        setSelectedNodeIndex(index)
      }
    }
  }

  const onSelectSubNode = (index: number, subItemIndex: number) => {
    autoOpen.current = false

    if (selectedNodeIndex === undefined) {
      setSelectedSubNodeIndex(subItemIndex)
      setSelectedNodeIndex(index)
    } else {
      setSelectedSubNodeIndex(undefined)
      setSelectedNodeIndex(undefined)

      if (selectedNodeIndex !== index || selectedSubNodeIndex !== subItemIndex) {
        if (onSelectNodeTimer.current) {
          clearTimeout(onSelectNodeTimer.current)
        }
        onSelectNodeTimer.current = setTimeout(() => {
          setSelectedSubNodeIndex(subItemIndex)
          setSelectedNodeIndex(index)
        }, animationDuration)
      }
    }
  }

  useEffect(
    () => () => {
      if (onSelectNodeTimer.current) {
        clearTimeout(onSelectNodeTimer.current)
      }
    },
    // Cleanup timer on unmount
    []
  )

  let levelClasses: string

  // In mobile, a level slides in from the right. In lg it slides in from the left.
  const stateClasses = node
    ? `
      left-0 
      md:ml-0
      lg:right-0 lg:opacity-100`
    : `
      left-full 
      md:ml-30
      lg:right-full lg:opacity-0
    `

  switch (level) {
    default:
    case 1:
      levelClasses = 'lg:col-start-2 lg:z-navbar-flyout-1-lg'
      break
    case 2:
      levelClasses = 'lg:col-start-3 lg:z-navbar-flyout-2-lg'
      break
    case 3:
      levelClasses = 'lg:col-start-4 lg:z-navbar-flyout-3-lg'
      break
  }

  const selectedNodeHasSubitems = !!selectedNode?.subitems.length

  return (
    <>
      <div
        className={`
          absolute z-navbar top-0 w-full h-full transition-all duration-200 bg-site-background
          grid grid-rows-navbar-flyout grid-cols-mobile justify-center gap-x-30
          tb:grid-cols-tb tb:ml-0
          sm:grid-cols-sm
          md:grid-cols-md
          lg:col-span-1 lg:grid-cols-1 lg:row-start-1 lg:grid-rows-navbar-flyout-lg lg:border-r lg:left-auto lg:mr-0 lg:auto-rows-min lg:border-neutral-lighter
          ${stateClasses}
          ${levelClasses}
        `}
      >
        {nodeCopy && (
          <>
            <div
              className="
                col-start-1 col-span-1 flex flex-col items-start ml-45 -mr-45 mt-30
                sm:col-start-2 sm:col-span-1
                md:col-start-1 md:col-span-1
                lg:col-span-full lg:row-start-2 lg:mt-0 lg:ml-30 lg:mr-30
              "
            >
              <LinkWrapper
                additionalClassName={classNames(
                  'font-nav-flyout-sublevel-title text-nav-flyout-sublevel-title uppercase',
                  'lg:w-full lg:border-b lg:border-neutral-lighter lg:pb-15',
                  !isNullOrWhiteSpace(nodeCopy.link.url)
                    ? 'text-secondary-darker'
                    : 'text-neutral-dark pointer-events-none'
                )}
                additionalLinkClassName="
                  transition-hover hover:text-primary focus:outline-none focus:text-primary
                  lg:focus:border-quinary
                "
                customAttributes={nodeCopy.link.customAttributes}
                externalIconSize={ExternalIconSize.Md}
                fallbackElement="span"
                isExternal={nodeCopy.link.isExternal}
                onClick={() => onNavigate?.(nodeCopy.link.url ?? '')}
                url={nodeCopy.link.url}
              >
                <span className="inline-flex items-center">
                  <span>{nodeCopy.link.title}</span>

                  {!isNullOrWhiteSpace(nodeCopy.link.url) &&
                    isInternalLink(nodeCopy.link.url) && (
                      <Chevron
                        additionalClassName="ml-10 lg:ml-15"
                        direction="right"
                        width="w-7"
                        height="h-7"
                      />
                    )}
                </span>
              </LinkWrapper>

              {/* 'Go back' arrow */}
              <div className="flex flex-1 flex-col justify-center -ml-45 lg:hidden">
                <button
                  className="focus:outline-none transition-hover hover:text-primary"
                  onClick={onGoBack}
                >
                  <ArrowIcon additionalClassName="w-15 h-15" direction="left" />
                </button>
              </div>
            </div>

            <ul
              className="
                row-start-2 flex flex-col col-span-full mx-45
                sm:col-start-2 sm:col-span-5 sm:mr-150
                md:col-start-1 md:col-span-6 md:-mr-15 md:bg-site-background
                lg:col-span-full lg:row-start-3 lg:mt-45 lg:mx-30
              "
            >
              {nodeCopy.subitems.map((subNode, index) => {
                const hasInlineSubitems =
                  subNode.renderSubitemsInline && !!subNode.subitems.length

                return (
                  <NavigationFlyoutNode
                    active={
                      (hasInlineSubitems &&
                        index === currentPageNodeIndex &&
                        currentPageSubNodeIndex === undefined) ||
                      (!hasInlineSubitems &&
                        (index === selectedNodeIndex || index === currentPageNodeIndex))
                    }
                    hideSubitems={subNode.renderSubitemsInline || level >= 3}
                    key={index}
                    level={level}
                    node={subNode}
                    onClick={() => onSelectNode(index)}
                    onNavigate={onNavigate}
                    showChevronOnInternalLink={hasInlineSubitems}
                  >
                    {hasInlineSubitems && (
                      <ul className="w-full">
                        {subNode.subitems.map((subItem, subItemIndex) => (
                          <NavigationFlyoutNode
                            active={
                              (index === selectedNodeIndex &&
                                subItemIndex === selectedSubNodeIndex) ||
                              (index === currentPageNodeIndex &&
                                subItemIndex === currentPageSubNodeIndex)
                            }
                            hideSubitems={level >= 3}
                            key={subItemIndex}
                            level={level}
                            node={subItem}
                            onClick={() => onSelectSubNode(index, subItemIndex)}
                            variant="inline"
                          />
                        ))}
                      </ul>
                    )}
                  </NavigationFlyoutNode>
                )
              })}
            </ul>
          </>
        )}
      </div>

      {nodeCopy && level < 3 && (
        <NavigationFlyoutLevel
          autoOpen={autoOpen.current}
          currentPageUrl={currentPageUrl}
          level={level + 1}
          node={selectedNodeHasSubitems ? selectedNode : undefined}
          onGoBack={() => setSelectedNodeIndex(undefined)}
          onNavigate={onNavigate}
        />
      )}
    </>
  )
}
