import React, { FC, PropsWithChildren, RefObject, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface PortalWrapperProps extends PropsWithChildren {
  // Allow 'any' type here as we don't want to depend on
  // type of element associated with the ref object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portalRef?: RefObject<any>
}

/**
 * Wrapper component for elements which should be rendered elsewhere
 * in the DOM with help from ReactDOM.createPortal()
 */
export const PortalWrapper: FC<PortalWrapperProps> = ({ children, portalRef }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setState] = useState<any>(portalRef?.current)

  useEffect(() => {
    setState(portalRef?.current)
  }, [portalRef])

  // Ref defined? Render children at target container
  if (portalRef?.current) {
    return createPortal(children, portalRef.current)
  }

  // Otherwise render where PortalWrapper is inserted
  return <>{children}</>
}
