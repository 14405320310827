import { SanityTableBlock } from '../../types/generated/graphql-types'
import { TableBlockProps, TableHeadingType } from '../components/TableBlock'
import { notEmpty } from '../utilities/filters'

export const sanityTableBlockToTableBlockProps = ({
  headingType,
  rows,
}: SanityTableBlock): TableBlockProps => ({
  headingType: (headingType as TableHeadingType) ?? undefined,
  rows:
    rows?.filter(notEmpty).map(row => ({
      cells:
        row.cells?.filter(notEmpty).map(cell => ({
          content: cell.content?.filter(notEmpty) ?? [],
        })) ?? [],
    })) ?? [],
})
