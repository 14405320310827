import { ClientConfig, createClient } from '@sanity/client'

export const sanityClientConfig: ClientConfig = {
  apiVersion: '2021-05-05',
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  token: process.env.GATSBY_SANITY_READ_TOKEN,
  useCdn: false,
}

export const sanityClient = createClient(sanityClientConfig)
