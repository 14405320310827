import { IS_EQT_MARKET } from '../utilities/site'

const siteConfig = {
  DisclaimerFilesPath: process.env.GATSBY_DISCLAIMER_FILES_PATH ?? '/_files',
  /** Used to fetch any new press release that may not have built successfully yet
   * (i.e. before browsable page actually exists)
   */
  PressReleaseServiceUrl: !IS_EQT_MARKET
    ? process.env.GATSBY_PRESSRELEASE_SERVICE_URL
    : undefined,
  CisionReCaptchaKey: process.env.GATSBY_CISION_RECAPTCHA_KEY,
  FormsReCaptchaKey: process.env.GATSBY_FORMS_RECAPTCHA_KEY,
  SubscriptionServiceUrl: process.env.GATSBY_SUBSCRIPTION_SERVICE_URL,
  ShowSdgColumnAndFilter: false,
}

export default siteConfig
