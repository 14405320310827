import classNames from 'classnames'
import React, { PropsWithChildren, useState } from 'react'

export interface Source {
  srcSet: string
  minimumBreakpoint: number
  width?: number
  height?: number
}

export interface ImageProps {
  altText: string

  /**
   * Since lqip requires setting position relative on the picture element we
   * only apply lqip if this prop is set for now, to not accidentally break
   * existing components.
   */
  applyLqip?: boolean

  caption?: string
  copyright?: string

  /**
   * Apply CSS classes to the <figure> wrapper
   */
  className?: string

  loading?: 'eager' | 'lazy'

  /**
   * Low quality image from Sanity, to be appended directly to the html
   */
  lqip?: string

  /**
   * Apply CSS classes to the <picture> element containing the responsive image
   */
  pictureClassName?: string

  /**
   * Apply CSS classes directly on the image
   */
  imgClassName?: string

  /**
   * Add additional classes to caption wrapper
   */
  captionAdditionalClassName?: string

  /**
   * Add additional classes to copyright wrapper
   */
  copyrightAdditionalClassName?: string

  src: string
  sources?: Source[]
  width?: number
  height?: number
}

export const Image = ({
  altText,
  applyLqip,
  caption,
  captionAdditionalClassName = '',
  children,
  className,
  copyright,
  copyrightAdditionalClassName = '',
  height,
  imgClassName,
  loading,
  lqip,
  pictureClassName,
  sources,
  src,
  width,
}: PropsWithChildren<ImageProps>) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  return (
    <figure className={className}>
      <picture
        className={classNames(pictureClassName, { 'relative': applyLqip && lqip })}
      >
        {!!sources?.length &&
          sources.map((source, i) => (
            <source
              key={i}
              media={`(min-width: ${source.minimumBreakpoint}px)`}
              srcSet={source.srcSet}
              width={source.width}
              height={source.height}
            />
          ))}

        <img
          alt={altText}
          className={imgClassName}
          loading={loading}
          onLoad={() => setImgLoaded(true)}
          src={src}
          width={width}
          height={height}
        />

        {applyLqip && lqip && (
          <div
            className={classNames('absolute inset-0 duration-200', {
              'opacity-100': !imgLoaded,
              'opacity-0': imgLoaded,
            })}
            style={{
              backgroundImage: `url(${lqip})`,
              backgroundSize: 'cover',
            }}
          ></div>
        )}
      </picture>

      {caption && (
        <figcaption
          className={classNames(
            'font-t-bold text-t-300 text-secondary-darker mt-5 align-middle max-w-caption',
            captionAdditionalClassName
          )}
        >
          {caption}
        </figcaption>
      )}

      {copyright && (
        <footer className={copyrightAdditionalClassName}>
          <small className="block font-t-light text-t-100 uppercase text-secondary-darker">
            {copyright}
          </small>
        </footer>
      )}

      {children}
    </figure>
  )
}
