const spacing = {
  '-480': '-30rem', // 480px
  '-330': '-20.625rem', // 330px
  '-240': '-15rem', // 240px
  '-150': '-9.375rem', // 150px
  '-120': '-7.5rem', // 120px
  '-90': '-5.625rem', // 90px
  '-75': '-4.6875rem', // 75px
  '-60': '-3.75rem', // 60px
  '-45': '-2.8125rem', // 45px
  '-30': '-1.875rem', // 30px
  '-20': '-1.25rem', // 20px
  '-15': '-0.9375rem', // 15px
  '-15-1/2': '-0.4688rem', // 50% of "15px" used for items with margins on both sides
  '-10': '-0.625rem', // 10px
  '-8': '-0.5rem', // 8px
  '-7': '-0.4375rem', // 7px
  '-5': '-0.3125rem', // 5px
  '-3': '-0.1875rem', // 3px
  '-2': '-0.125rem', // 2px
  '-1': '-0.0625rem', // 1px
  0: 0,
  '1': '0.0625rem', // 1px
  '2': '0.125rem', // 2px
  '3': '0.1875rem', // 3px
  '4': '0.25rem', // 4px
  '5': '0.3125rem', // 5px
  '6': '0.375rem', // 6px
  '7': '0.4375rem', // 7px
  '8': '0.5rem', // 8px
  '9': '0.5625rem', // 9px
  '10': '0.625rem', // 10px
  '15-1/2': '0.4688rem', // 50% of "15px" used for items with margins on both sides
  '15': '0.9375rem', // 15px
  '20': '1.25rem', // 20px
  '30': '1.875rem', // 30px
  '45': '2.8125rem', // 45px
  '60': '3.75rem', // 60px
  '75': '4.6875rem', // 75px
  '90': '5.625rem', // 90px
  '120': '7.5rem', // 120px
  '150': '9.375rem', // 150px
  '240': '15rem', // 240px
  '330': '20.625rem', // 330px
  '480': '30rem', // 480px

  // Keywords
  'auto': 'auto',
  'full': '100%',

  // Percentages
  '1/4': '25%',
  '1/3': '33.3333%',
  '1/2': '50%',
  '2/3': '66.6666%',
  '3/4': '75%',
}

const extendedSizes = {
  'max-content': 'max-content',
  'min-content': 'min-content',
}

const extendedWidths = {
  // Keywords
  'screen': '100vw',
  'screen-1/2': '50vw',

  // Special
  // An approximation of the square root of 2, used in for example square elements that have been rotated 45 degrees.
  'sqrt2': '141%',

  // Common screen width utility
  '-screen-1/2-gap-1/2': `calc(50vw - ${spacing['15']})`,
  'screen-1/2-gap-1/2': `calc(50vw + ${spacing['15']})`,
}

const extendedHeights = {
  // Keywords
  'screen': '100vh',

  // Special
  // Screen height - header height
  'screen-header': 'calc(100vh - 7.5rem)',

  // Full screen videos
  'screen-video-39/50': '128vw', // Very specific, used for MPQ on mobile devices
  'screen-video-144/59': '41vw', // Very specific, used for MPQ on all breakpoints except mobile
}

const config = {
  content: ['./src/**/*.{ts,tsx}', './storybook/**/*.{ts,tsx}'],
  theme: {
    screens: {
      mobileLg: '415px', // Edge-case usage, primarily to load correct videos in MPQ
      tb: '750px', // Tablet
      sm: '1024px',
      md: '1280px',
      lg: '1440px',
      xl: '1920px',
      xxl: '2560px',
    },
    aspectRatio: {
      auto: 'auto',
      square: '1/1',
      wide: '16/9',
    },
    borderRadius: {
      DEFAULT: '0.9375rem', // 15px
      3: '0.1875rem',
      4: '0.25rem',
      5: '0.3125rem',
      8: '0.5rem',
      10: '0.625rem',
      15: '0.9375rem',
      20: '1.25rem',
      30: '1.875rem',
      full: '9999px',
      none: '0px',
    },
    boxShadow: {
      /** @deprecated */
      hover: '0 0 15px rgb(0, 0, 0, 0.2)',
      select: '0px 5px 15px -7px rgba(0, 0, 0, 0.2);',
      'soft-sm': '0px 4px 24px rgba(0, 0, 0, 0.12);',
      sm: '0px 0px 0px 5px var(--tw-shadow-color)',
    },
    fontFamily: {
      default: ['sofia-pro', 'Helvetica', 'Arial', 'sans-serif'],
      cn: [
        'sofia-pro',
        'source-han-sans-simplified-c',
        'Helvetica',
        'Arial',
        'sans-serif',
      ],
      jp: ['sofia-pro', 'noto-sans-cjk-jp', 'Helvetica', 'Arial', 'sans-serif'],
      kr: ['sofia-pro', 'noto-sans-cjk-kr', 'Helvetica', 'Arial', 'sans-serif'],
    },
    fontSize: {
      // "Auto-growing" font-sizes
      h1: [
        'var(--font-size-h1)',
        {
          letterSpacing: 'var(--letter-spacing-h1)',
          lineHeight: 'var(--line-height-h1)',
        },
      ],

      h2: [
        'var(--font-size-h2)',
        {
          letterSpacing: 'var(--letter-spacing-h2)',
          lineHeight: 'var(--line-height-h2)',
        },
      ],

      h3: [
        'var(--font-size-h3)',
        {
          letterSpacing: 'var(--letter-spacing-h3)',
          lineHeight: 'var(--line-height-h3)',
        },
      ],

      h4: [
        'var(--font-size-h4)',
        {
          letterSpacing: 'var(--letter-spacing-h4)',
          lineHeight: 'var(--line-height-h4)',
        },
      ],

      preamble: [
        'var(--font-size-preamble)',
        {
          letterSpacing: 'var(--letter-spacing-preamble)',
          lineHeight: 'var(--line-height-preamble)',
        },
      ],

      body: [
        'var(--font-size-body)',
        {
          letterSpacing: 'var(--letter-spacing-body)',
          lineHeight: 'var(--line-height-body)',
        },
      ],

      'display-500': [
        'var(--font-size-display-500)',
        {
          letterSpacing: 'var(--letter-spacing-display)',
          lineHeight: 'var(--line-height-display)',
        },
      ],

      'display-700': [
        'var(--font-size-display-700)',
        {
          letterSpacing: 'var(--letter-spacing-display)',
          lineHeight: 'var(--line-height-display)',
        },
      ],

      'hero-title': [
        'var(--font-size-hero-title)',
        {
          letterSpacing: 'var(--letter-spacing-hero-title)',
          lineHeight: 'var(--line-height-hero-title)',
        },
      ],

      'hero-subtitle': [
        'var(--font-size-hero-subtitle)',
        {
          letterSpacing: 'var(--letter-spacing-hero-subtitle)',
          lineHeight: 'var(--line-height-hero-subtitle)',
        },
      ],

      'hero-body': [
        'var(--font-size-hero-body)',
        {
          letterSpacing: 'var(--letter-spacing-hero-body)',
          lineHeight: 'var(--line-height-hero-body)',
        },
      ],
      'hero-body-sm': [
        'var(--font-size-hero-body-sm)',
        {
          letterSpacing: 'var(--letter-spacing-hero-body-sm)',
          lineHeight: 'var(--line-height-hero-body-sm)',
        },
      ],

      'navbar-link': [
        'var(--font-size-navbar-link)',
        {
          letterSpacing: 'var(--letter-spacing-navbar-link)',
          lineHeight: 'var(--line-height-navbar-link)',
        },
      ],

      'navbar-pill': [
        'var(--font-size-navbar-pill)',
        {
          letterSpacing: 'var(--letter-spacing-navbar-pill)',
          lineHeight: 'var(--line-height-navbar-pill)',
        },
      ],

      'nav-flyout-level-1': [
        'var(--font-size-nav-flyout-level-1)',
        {
          letterSpacing: 'var(--letter-spacing-nav-flyout-level-1)',
          lineHeight: 'var(--line-height-nav-flyout-level-1)',
        },
      ],

      'nav-flyout-sublevel-title': [
        'var(--font-size-nav-flyout-sublevel-title)',
        {
          letterSpacing: 'var(--letter-spacing-nav-flyout-sublevel-title)',
          lineHeight: 'var(--line-height-nav-flyout-sublevel-title)',
        },
      ],

      'nav-flyout-sublevel': [
        'var(--font-size-nav-flyout-sublevel)',
        {
          letterSpacing: 'var(--letter-spacing-nav-flyout-sublevel)',
          lineHeight: 'var(--line-height-nav-flyout-sublevel)',
        },
      ],

      'nav-flyout-subsegment-link': [
        'var(--font-size-nav-flyout-subsegment-link)',
        {
          letterSpacing: 'var(--letter-spacing-nav-flyout-subsegment-link)',
          lineHeight: 'var(--line-height-nav-flyout-subsegment-link)',
        },
      ],

      'nav-flyout-complementary': [
        'var(--font-size-nav-flyout-complementary)',
        {
          letterSpacing: 'var(--letter-spacing-nav-flyout-complementary)',
          lineHeight: 'var(--line-height-nav-flyout-complementary)',
        },
      ],

      'footer-title': [
        'var(--font-size-footer-title)',
        {
          letterSpacing: 'var(--letter-spacing-footer-title)',
          lineHeight: 'var(--line-height-footer-title)',
        },
      ],

      'footer-subtitle': [
        'var(--font-size-footer-subtitle)',
        {
          letterSpacing: 'var(--letter-spacing-footer-subtitle)',
          lineHeight: 'var(--line-height-footer-subtitle)',
        },
      ],

      'footer-link': [
        'var(--font-size-footer-link)',
        {
          letterSpacing: 'var(--letter-spacing-footer-link)',
          lineHeight: 'var(--line-height-footer-link)',
        },
      ],

      'footer-slogan': [
        'var(--font-size-footer-slogan)',
        {
          letterSpacing: 'var(--letter-spacing-footer-slogan)',
          lineHeight: 'var(--line-height-footer-slogan)',
        },
      ],

      'footer-copyright': [
        'var(--font-size-footer-copyright)',
        {
          letterSpacing: 'var(--letter-spacing-footer-copyright)',
          lineHeight: 'var(--line-height-footer-copyright)',
        },
      ],

      // "Non-auto-growing" font-sizes
      't-100': [
        'var(--font-size-t-100) /* 11px */',
        {
          letterSpacing: 'var(--letter-spacing-t-100)',
          lineHeight: 'var(--line-height-t-100)',
        },
      ],

      't-200': [
        'var(--font-size-t-200) /* 12px */',
        {
          letterSpacing: 'var(--letter-spacing-t-200)',
          lineHeight: 'var(--line-height-t-200)',
        },
      ],

      't-300': [
        'var(--font-size-t-300) /* 14px */',
        {
          letterSpacing: 'var(--letter-spacing-t-300)',
          lineHeight: 'var(--line-height-t-300)',
        },
      ],

      't-400': [
        'var(--font-size-t-400) /* 16px */',
        {
          letterSpacing: 'var(--letter-spacing-t-400)',
          lineHeight: 'var(--line-height-t-400)',
        },
      ],

      't-500': [
        'var(--font-size-t-500) /* 18px */',
        {
          letterSpacing: 'var(--letter-spacing-t-500)',
          lineHeight: 'var(--line-height-t-500)',
        },
      ],

      't-600': [
        'var(--font-size-t-600) /* 24px */',
        {
          letterSpacing: 'var(--letter-spacing-t-600)',
          lineHeight: 'var(--line-height-t-600)',
        },
      ],

      't-700': [
        'var(--font-size-t-700) /* 28px */',
        {
          letterSpacing: 'var(--letter-spacing-t-700)',
          lineHeight: 'var(--line-height-t-700)',
        },
      ],

      't-800': [
        'var(--font-size-t-800) /* 36px */',
        {
          letterSpacing: 'var(--letter-spacing-t-800)',
          lineHeight: 'var(--line-height-t-800)',
        },
      ],

      't-900': [
        'var(--font-size-t-900) /* 44px */',
        {
          letterSpacing: 'var(--letter-spacing-t-900)',
          lineHeight: 'var(--line-height-t-900)',
        },
      ],

      't-1000': [
        'var(--font-size-t-1000) /* 56px */',
        {
          letterSpacing: 'var(--letter-spacing-t-1000)',
          lineHeight: 'var(--line-height-t-1000)',
        },
      ],

      'button-sm': [
        'var(--font-size-button-sm) /* 10px */',
        {
          letterSpacing: 'var(--letter-spacing-button-sm)',
          lineHeight: 'var(--line-height-button-sm)',
        },
      ],

      'button-md': [
        'var(--font-size-button-md) /* 12px */',
        {
          letterSpacing: 'var(--letter-spacing-button-md)',
          lineHeight: 'var(--line-height-button-md)',
        },
      ],

      'button-lg': [
        'var(--font-size-button-lg)  /* 14px */',
        {
          letterSpacing: 'var(--letter-spacing-button-lg)',
          lineHeight: 'var(--line-height-button-lg)',
        },
      ],
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,

      // "Auto-growing" fonts
      h1: 'var(--font-weight-h1)',
      h2: 'var(--font-weight-h2)',
      h3: 'var(--font-weight-h3)',
      preamble: 'var(--font-weight-preamble)',
      body: 'var(--font-weight-body)',
      display: 'var(--font-weight-preamble)',
      'hero-title': 'var(--font-weight-hero-title)',
      'hero-subtitle': 'var(--font-weight-hero-subtitle)',
      'hero-body': 'var(--font-weight-hero-body)',
      'navbar-link': 'var(--font-weight-navbar-link)',
      'navbar-pill': 'var(--font-weight-navbar-pill)',
      'nav-flyout-level-1': 'var(--font-weight-nav-flyout-level-1)',
      'nav-flyout-sublevel': 'var(--font-weight-nav-flyout-sublevel)',
      'nav-flyout-sublevel-title': 'var(--font-weight-nav-flyout-sublevel-title)',
      'nav-flyout-subsegment-link': 'var(--font-weight-nav-flyout-subsegment-link)',
      'nav-flyout-complementary': 'var(--font-weight-nav-flyout-complementary)',
      'footer-title': 'var(--font-weight-footer-title)',
      'footer-subtitle': 'var(--font-weight-footer-subtitle)',
      'footer-link': 'var(--font-weight-footer-link)',
      'footer-slogan': 'var(--font-weight-footer-slogan)',
      'footer-copyright': 'var(--font-weight-footer-copyright)',

      // "Non-auto-growing" fonts
      t: 'var(--font-weight-t)',
      't-light': 'var(--font-weight-t-light)',
      't-bold': 'var(--font-weight-t-bold)',
      button: 'var(--font-weight-button)',
    },
    letterSpacing: {
      'body-mobile': '0px',
      'body-tb': '0.25px',
    },
    lineHeight: {
      'body': '145%',
    },
    spacing,
    ringWidth: {
      DEFAULT: '0.3125rem',
    },
    extend: {
      width: { ...extendedSizes, ...extendedWidths },
      height: { ...extendedSizes, ...extendedHeights },
      minWidth: { ...spacing, ...extendedSizes, ...extendedWidths },
      minHeight: { ...spacing, ...extendedSizes, ...extendedHeights },
      maxWidth: { none: 'none', ...spacing, ...extendedSizes, ...extendedWidths },
      maxHeight: { none: 'none', ...extendedSizes, ...extendedHeights },
      margin: {
        'auto': 'auto',
        'screen-center': 'calc(-50vw + 50%)',
      },
      padding: {
        '4/3': '75%', // aspect ratio 4:3 for iframes
        '16/9': '56.25%', // aspect ratio 16:9 for iframes
        '64/27': '42%', // Specific ultrawide aspect ratio for EQT Network hero ("21/9" in marketing terms)
      },

      // Use similar naming conventions to builtin Tailwind colors
      colors: {
        neutral: {
          contrast: '#000000',
          darker: '#4D4D55',
          'dark-alt': '#7E7E7E',
          dark: '#8B8B90',
          medium: '#9E9E9E',
          'medium-light': '#BEBEBE',
          light: '#DBDBDD',
          lighter: '#ECEAE6',
          'lighter-alt': '#E5E7EB',
          DEFAULT: '#FFFFFF',
        },
        shade: {
          darker: '#4D4634',
          dark: '#584A44',
          'dark-dim': '#685D4F',
          'medium-dim': '#8F8482',
          medium: '#8E6B32',
          alt: '#91835F',
          'alt-2': '#B49682',
          DEFAULT: '#DDA64D',
          light: '#E7D0B1',
          lighter: '#F8F1E7',
          lightest: '#F7F4F2',
          'lightest-warm': '#FAF8F6',
        },
        primary: {
          // orange
          'alpha-25': '#FF570340',
          DEFAULT: '#FF5703',
          light: '#FF6600',
          lighter: '#FF9B59',
          dim: '#FFE0CC',
        },
        secondary: {
          // brown/red
          darkest: '#310705',
          darker: '#420900',
          'dark-alt': '#60180D',
          dark: '#751713',
          'alt': '#84211D',
          DEFAULT: '#B00E23',
          light: '#D94737',
          lightest: '#FCF4F4FA',
        },
        tertiary: {
          // blue
          DEFAULT: '#0033FF',
          light: '#6883FF',
          lighter: '#B6BFFF',
        },
        quaternary: {
          // green
          DEFAULT: '#6EC829',
          light: '#92DD59',
          lighter: '#BEEB9D',
          lightest: '#EFF8ED',
        },
        quinary: {
          // gold
          dark: '#CC9D4F',
          DEFAULT: '#FFC700',
          light: '#F9DA64',
          lighter: '#FCE99C',
          lightest: '#FDF4E9',
        },
        senary: {
          // teal
          darkest: '#011828',
          darker: '#1E242B',
          dark: '#13516C',
          DEFAULT: '#00A5C1',
          light: '#98AEB9',
          lighter: '#96C2D2',
        },
        success: '#4C9F38',
        error: '#B00E23',
        // People-specific, not used anywhere else currently
        people: {
          1: '#E7D0B1',
          2: '#FFE0CC',
          3: '#FCF1CA',
          4: '#F7E6E9',
          5: '#F0F2FF',
        },
        // SDG colors
        sdg: {
          1: '#E5243B',
          2: '#DDA83A',
          3: '#4C9F38',
          4: '#C5192D',
          5: '#FF3A21',
          6: '#26BDE2',
          7: '#FCC30B',
          8: '#A21942',
          9: '#FD6925',
          10: '#DD1367',
          11: '#FD9D24',
          12: '#BF8B2E',
          13: '#3F7E44',
          14: '#0A97D9',
          15: '#56C02B',
          16: '#00689D',
          17: '#19486A',
        },
        'placeholder-image': '#EBE3DB',
        'site-background': '#FFFFFF',
      },
      gridColumn: {
        'none': 'none',
      },
      gridTemplateRows: {
        'navbar-flyout': '6.5625rem',
        'navbar-flyout-lg': '7.5rem',
        'parallax-hero': 'min-content 1fr min-content',
        'parallax-hero-compact': 'minmax(0, 1fr) min-content',
        // For PurposeAndCulture, where the last grid row should take up all the remaining available space
        'purpose-and-culture': 'repeat(4, min-content) 1fr',
        'reports-hero': 'repeat(4, min-content) 1fr',
      },
      gridAutoRows: {
        // repeat not supported in grid-auto-rows
        'fr-autoheader':
          'auto minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
      },
      gridTemplateColumns: {
        'mobile': 'repeat(2, minmax(7.5rem, 9.375rem))',
        'tb': 'repeat(4, 9.375rem)',
        'sm': 'repeat(6, minmax(4rem, 9.375rem))',
        'md': 'repeat(6, 9.375rem)',
        'lg': 'repeat(8, 9.375rem)',
        'xl': 'repeat(10, 9.375rem)',
        'xxl': 'repeat(14, 9.375rem)',

        'mobile-1/2': 'repeat(1, minmax(7.5rem, 9.375rem))',
        'tb-1/2': 'repeat(2, 9.375rem)',
        'sm-1/2': 'repeat(3, minmax(4rem, 9.375rem))',
        'md-1/2': 'repeat(3, 9.375rem)',
        'lg-1/2': 'repeat(4, 9.375rem)',
        'xl-1/2': 'repeat(5, 9.375rem)',
        'xxl-1/2': 'repeat(7, 9.375rem)',

        'business-segments-tb': 'repeat(4, 9.375rem)',
        'business-segments-sm': 'repeat(4, minmax(4rem, 9.375rem))',
        'business-segments-md': 'repeat(6, 9.375rem)',
        'business-segments-lg': 'repeat(6, 9.375rem)',
        'business-segments-xl': 'repeat(8, 9.375rem)',
        'business-segments-xxl': 'repeat(10, 9.375rem)',

        'portfolio-tabs-mobile': 'repeat(2, minmax(7.5rem, 9.375rem))',
        'portfolio-tabs-tb': 'repeat(4, 9.375rem)',
        'portfolio-tabs-sm': 'repeat(4, minmax(4rem, 9.375rem))',
        'portfolio-tabs-md': 'repeat(6, 9.375rem)',
        'portfolio-tabs-lg': 'repeat(6, 9.375rem)',
        'portfolio-tabs-xl': 'repeat(8, 9.375rem)',
        'portfolio-tabs-xxl': 'repeat(10, 9.375rem)',

        // Card sizes for Related component, e.g. for press releases
        'related': 'repeat(3, 16.875rem)',
        'related-tb': 'repeat(3, 20.625rem)',
        'related-sm': 'repeat(3, minmax(17.5rem, 20.625rem))',
        'related-xl': 'repeat(3, 31.875rem)',

        // Card sizes for Relevant investments component
        'relevant-investments': 'repeat(3, 16.875rem)',
        'relevant-investments-tb': 'repeat(3, 20.625rem)',
        'relevant-investments-sm': 'repeat(3, minmax(17.5rem, 20.625rem))',
        'relevant-investments-xl': 'repeat(3, 31.875rem)',

        // People "grid"
        'people-sm': 'repeat(3, 13.125rem)',
        'people-md': 'repeat(4, 13.125rem)',
        'people-lg': 'repeat(4, 13.125rem)',
        'people-xl': 'repeat(5, 13.125rem)',
        'people-xxl': 'repeat(5, 13.4375rem)',

        // Annual reviews
        'annual-reviews-sm': 'repeat(3, 13.125rem)',
        'annual-reviews-md': 'repeat(3, 22.5rem)',
        'annual-reviews-xl': 'repeat(3, 30rem)',
        'annual-reviews-xxl': 'repeat(3, 37.5rem)',

        // Events "grid"
        'events': 'repeat(1, 20.625rem)',
        'events-tb': 'repeat(2, 20.625rem)',
        'events-md': 'repeat(3, 20.625rem)',
        'events-xl': 'repeat(3, 30rem)',
        'events-xxl': 'repeat(3, 37.5rem)',

        // Reports List "grid"
        'report-list': 'repeat(1, 20.625rem)',
        'report-list-tb': 'repeat(2, 20.625rem)',
        'report-list-md': 'repeat(3, 22.5rem)',
        'report-list-xl': 'repeat(3, 30rem)',
        'report-list-xxl': 'repeat(3, 37.5rem)',

        // Grid for societal contributions (SDG)
        'sdg': '7.5rem 7.5rem',

        // Grid for subsection intro heading person cards
        'subsection-heading-mobile': 'repeat(2, 9.375rem)',
        'subsection-heading-md': 'repeat(2, 12.1875rem)',

        // Grid for navbar flyout lg and above
        'navbar-flyout-lg': '39.375rem repeat(3, 16.875rem)',
        'navbar-flyout-xl': '52.5rem repeat(3, 22.5rem)',
        'navbar-flyout-xxl': '69.75rem repeat(3, 29.875rem)',

        'search-results-dropdown-filters': '5.625rem 5.625rem 6.5625rem',
        'search-results-dropdown-filters-tb':
          '5.625rem 6.5625rem 9.375rem 6.5625rem 5.625rem',
        'search-results-dropdown-filters-md': '5.625rem repeat(4, 9.375rem)',
        'search-results-dropdown-filters-xxl': 'repeat(6, 9.375rem)',

        'auto-fit-150': 'repeat(auto-fit, minmax(9.375rem, 1fr))',
        'auto-fit-130': 'repeat(auto-fit, minmax(8.125rem, 1fr))',
      },
      gap: {
        // People specific
        'people-sm': '3.375rem',
        'people-md': '4.375rem',
        'people-lg': '5.625rem',
        'people-xl': '5.625rem',
        'people-xxl': '10.875rem',
      },
      flex: {
        full: '1 1 100%',
      },
      borderWidth: {
        '1/2': '0.5px',
      },
      opacity: {
        7: '0.07',
        15: '0.15',
        // Shared opacity level for most hover effects
        hover: '0.75',
      },
      rotate: {
        '-135': '-135deg',
        '135': '135deg',
        '225': '225deg',
        '270': '270deg',
        '315': '315deg',
      },
      strokeWidth: {
        'mobile': '0.1675rem',
        'md': '0,285625rem',
      },
      transitionDuration: {
        '250': '250ms',
        '400': '400ms',
        '1500': '1500ms',
      },
      transitionProperty: {
        'inset': 'top, bottom, left, right',
      },
      zIndex: {
        '-10': '-10',
        'navbar-scroll-progress-bar': 100,
        'navbar-sticky-sub-menu': 110,
        'navbar': 120,
        'navbar-flyout-wrapper': 140,

        // While closed, keep the action bar underneath the navbar flyout (so that it doesn't overlay the search bar when scrolling)
        // Otherwise when opened it should overlay the entire screen, so keep it on top of the navbar.
        'action-bar': 330,
        'action-bar-slideshow': 320,
        'action-bar-lightbox': 310,

        // Always keep navbar buttons on top of the navbar flyout
        'navbar-buttons': 300,

        // z-index for navbar flyout levels at breakpoint lg. Each level should slide out from underneath the previous one.
        'navbar-flyout-0-lg': 290,
        'navbar-flyout-1-lg': 280,
        'navbar-flyout-2-lg': 270,
        'navbar-flyout-3-lg': 260,

        'navbar-search-bar': 400,
        'highest': 1000,
      },
      keyframes: {
        'mpq-text-enter': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        'mpq-text-exit': {
          '0%': { transform: 'translate3d(0, 0, 0)', opacity: 1, position: 'relative' },
          '95%': {
            transform: 'translate3d(-60rem, 0, 0)',
            opacity: 1,
            position: 'relative',
          },
          '99%': {
            transform: 'translate3d(-60rem, 0, 0)',
            opacity: 0,
            position: 'relative',
          },
          '100%': { transform: 'translate3d(0, 0, 0)', opacity: 0, position: 'absolute' },
        },
        'mpq-inner-loop-entry': {
          '0%': { transform: 'scale(0.1)' },
          '100%': { transform: 'scale(1)' },
        },
        'mpq-video-enter': {
          '0%': { transform: 'scale(0.3)', opacity: 0 },
          '100%': { transform: 'scale(1)', opacity: 1 },
        },
        'mpq-video-exit': {
          '0%': { transform: 'scale(1)', opacity: 1 },
          '100%': { transform: 'scale(3)', opacity: 0 },
        },

        'slideshow-dots': {
          '0%': {
            left: '0',
            width: '0',
          },
          '100%': {
            left: '0',
            width: '100%',
          },
        },

        'slideshow-dots-exit': {
          '0%': {
            right: '0',
            width: '100%',
          },
          '100%': {
            right: '0',
            width: '0',
          },
        },

        'slideshow-dots-fade-in': {
          '0%': {
            opacity: '0%',
            width: '100%',
          },
          '100%': {
            opacity: '100%',
            width: '100%',
          },
        },

        // Note: Tweening between keyframes doesn't work for CSS variables, hence we need to re-set transform with
        // the current scaleX value here.
        'sound-button-wave1': {
          '0%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(61%)',
          },
          '33%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(71%)',
          },
          '67%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(86%)',
          },
          '100%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(61%)',
          },
        },
        'sound-button-wave2': {
          '0%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(100%)',
          },
          '33%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(121%)',
          },
          '67%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(57%)',
          },
          '100%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(100%)',
          },
        },
        'sound-button-wave3': {
          '0%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(64%)',
          },
          '33%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(93%)',
          },
          '67%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(50%)',
          },
          '100%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(64%)',
          },
        },
        'sound-button-wave4': {
          '0%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(89%)',
          },
          '33%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(57%)',
          },
          '67%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(100%)',
          },
          '100%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(89%)',
          },
        },
        'sound-button-wave5': {
          '0%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(57%)',
          },
          '33%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(100%)',
          },
          '67%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(50%)',
          },
          '100%': {
            'transform': 'scaleX(var(--tw-scale-x)) scaleY(57%)',
          },
        },

        'preview-loader-exit': {
          '20%': {
            'transform': 'translateX(20px)',
          },
          '100%': {
            'transform': 'translateX(-200%)',
          },
        },

        'preview-loader-enter': {
          '0%': {
            'transform': 'translateX(-200%)',
          },
          '80%': {
            'transform': 'translateX(20px)',
          },
          '100%': {
            'transform': 'translateX(0px)',
          },
        },
      },
      animation: {
        'preview-loader-exit': 'preview-loader-exit 0.5s ease 1 normal forwards',
        'preview-loader-enter': 'preview-loader-enter 0.5s ease 1 normal forwards',

        'mpq-text-enter': 'mpq-text-enter 1s ease 1 normal forwards',
        'mpq-text-exit': 'mpq-text-exit 0.5s ease-in 1 normal forwards',
        'mpq-video-enter': 'mpq-video-enter 0.5s ease-in 1 normal forwards',
        'mpq-video-exit': 'mpq-video-exit 0.5s ease-in 1 normal forwards',
        'mpq-inner-loop-entry':
          'mpq-inner-loop-entry 0.2s ease-in 0.5s 1 normal forwards',

        'slideshow-dots': 'slideshow-dots 1s ease-in 0s 1 normal forwards',
        'slideshow-dots-exit': 'slideshow-dots-exit 1s ease-in 0s 1 normal forwards',
        'slideshow-dots-fade-in':
          'slideshow-dots-fade-in 1s ease-in 0s 1 normal forwards',
      },
    },
  },
  plugins: [require('./src/tailwind/nextSibling.js')],
}

config.theme.extend.colors['site-background-secondary'] =
  config.theme.extend.colors.shade['lightest-warm']

module.exports = config
