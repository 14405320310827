import { isNullOrWhiteSpace } from './string'

/**
 * Falls back to normal style if style is missing.
 * Style can become undefined when pasting formatted text in Sanity.
 * @param style style property on a portable text block node
 * @returns style or fallback style if the style is invalid
 */
export const getBlockStyleWithFallback = (style?: string): string =>
  isNullOrWhiteSpace(style) ? 'normal' : style
