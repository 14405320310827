import { PortableTextMarkComponent } from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import React from 'react'
import { isNullOrWhiteSpace } from '../../../../utilities/string'
import { Link } from '../../../Link'

interface TableBlockLinkAnnotation extends TypedObject {
  href?: string
}

export const TableBlockLinkSerializer: PortableTextMarkComponent<
  TableBlockLinkAnnotation
> = ({ children, value }) => {
  const { href } = value ?? {}

  if (!isNullOrWhiteSpace(href)) {
    return (
      <Link
        className="text-primary font-t text-t-200 transition-hover hover:opacity-hover sm:text-t-300"
        isExternal
        url={href}
      >
        {children}
      </Link>
    )
  }

  console.error('Invalid link, no href')

  return <>{children}</>
}
