import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import { SitePageContext } from '../../types/generated/graphql-types'
import { isNullOrWhiteSpace } from '../utilities/string'

export type PageContextValues = SitePageContext & {
  errorHandler?: StackdriverErrorReporter
  path?: string
}

const PageContext = createContext<PageContextValues>({} as PageContextValues)

interface PageContextProviderProps {
  pageContext: SitePageContext
  path?: string
}

const PageContextProvider = ({
  children,
  pageContext,
  path,
}: PropsWithChildren<PageContextProviderProps>) => {
  const [errorHandler, setErrorHandler] = useState<StackdriverErrorReporter>()

  useEffect(() => {
    try {
      if (
        !isNullOrWhiteSpace(process.env.GATSBY_STACKDRIVER_ERROR_REPORTER_API_KEY) &&
        !isNullOrWhiteSpace(process.env.GATSBY_GOOGLE_PROJECT_ID)
      ) {
        const errorHandler = new StackdriverErrorReporter()

        errorHandler.start({
          key: process.env.GATSBY_STACKDRIVER_ERROR_REPORTER_API_KEY,
          projectId: process.env.GATSBY_GOOGLE_PROJECT_ID,
          disabled: process.env.NODE_ENV === 'development',
          version: process.env.GATSBY_GIT_HASH ?? 'unknown',
        })

        setErrorHandler(errorHandler)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const value = useMemo(
    () => ({
      ...pageContext,
      errorHandler,
      path,
    }),
    [errorHandler, pageContext, path]
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export { PageContext, PageContextProvider }
