import { DateTime, Settings } from 'luxon'
import { isNullOrWhiteSpace } from './string'

export const homeTimeZone = 'Europe/Stockholm'

const getCurrentYear = (): number => DateTime.now().setZone(homeTimeZone).year

const time = {
  // Ignore global locale settings in toShortDate, always use Swedish according to customer wishes
  toShortDate: (isoDate: string): string =>
    DateTime.fromISO(isoDate)
      .setZone(homeTimeZone)
      .setLocale('sv')
      .toLocaleString(DateTime.DATE_SHORT),

  // Ignore global locale settings in toShortDayAndMonth, always use Swedish according to customer wishes
  toShortDayAndMonth: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).setLocale('sv').toLocaleString({
      day: 'numeric',
      month: 'numeric',
    }),

  toShortMonthAndYear: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toLocaleString({
      month: 'short',
      year: 'numeric',
    }),

  toFullDate: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toLocaleString(DateTime.DATE_FULL),

  toLongDate: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toFormat('dd MMMM yyyy'),

  toEventText: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toFormat('dd MMMM yyyy'),

  toLocalizedEventSentence: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toLocaleString({
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),

  toShortDay: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toFormat('d'),

  toLongMonthAndYear: (isoDate: string): string =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).toFormat('MMMM yyyy'),

  toNewsListFormat: (isoDate: string): string => {
    const currentYear = getCurrentYear()
    const date = DateTime.fromISO(isoDate).setZone(homeTimeZone)
    return date.year < currentYear ? date.toFormat('MMMM yyyy') : date.toFormat('MMMM')
  },

  toLongTime: (isoDate: string): string =>
    DateTime.fromISO(isoDate)
      .setZone(homeTimeZone)
      .toFormat('HH:mm ZZZZ', { locale: 'en-GB' }), // locale: 'en-GB' sets CE(S)T instead of GMT offset

  toYear: (isoDate: string): number =>
    DateTime.fromISO(isoDate).setZone(homeTimeZone).year,

  getCurrentYear: getCurrentYear,
}

export const setGlobalLocale = (locale?: string) => {
  Settings.defaultLocale = isNullOrWhiteSpace(locale) ? 'en' : locale
}

export default time
