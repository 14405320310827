import classNames from 'classnames'
import React from 'react'
// HACK: use a primary-color-filled icon to avoid adding
// more settings to IconButton for one use-case
import MagnifyingGlass from '../../assets/icons/magnifying-glass-primary.svg'
import { useTranslations } from '../../hooks/useTranslations'
import { Grid } from '../Grid'
import { IconButton } from '../IconButton'
import { HamburgerButton } from './HamburgerButton'

export interface NavigationOpenerButtonsProps {
  active: boolean
  onMenuClick: () => void
  onSearchClick: () => void
  renderSearchButton?: boolean
  renderNavigationOpenerButtonAboveMd?: boolean
  top: number
}

export const NavigationOpenerButtons = ({
  active,
  onMenuClick,
  onSearchClick,
  renderSearchButton = true,
  renderNavigationOpenerButtonAboveMd = true,
  top,
}: NavigationOpenerButtonsProps): JSX.Element => {
  const translations = useTranslations()

  return (
    /* These buttons have a high z-index to keep them ontop of the flyout when opened */
    <div
      className="fixed pointer-events-none z-navbar-buttons left-0 w-full h-90 transition-inset duration-200"
      style={{
        top: `${top}px`,
      }}
    >
      <Grid
        additionalClassName="w-full h-full"
        contentAlignment="justify-center items-center"
      >
        <div
          className={classNames(
            'flex justify-end',
            'col-start-2 col-span-1',
            'tb:col-start-4',
            'sm:col-start-6 sm:mr-45',
            'md:col-start-6 md:ml-0 md:-mr-[5.625rem]',
            'lg:col-start-8 lg:justify-start lg:ml-30',
            'xl:col-start-10',
            'xxl:col-start-13 xxl:mr-0'
          )}
        >
          {renderSearchButton && (
            <IconButton
              svg={MagnifyingGlass}
              handleClick={onSearchClick}
              label={translations.searchBarButtonLabel ?? ''}
              additionalClassName="mr-15 pointer-events-auto"
              tabIndex={7}
            />
          )}

          <HamburgerButton
            additionalClassName={classNames({
              'md:hidden': !renderNavigationOpenerButtonAboveMd,
            })}
            onClick={onMenuClick}
            active={active}
            label={
              (active ? translations.closeNavigation : translations.openNavigation) ?? ''
            }
          />
        </div>
      </Grid>
    </div>
  )
}
