import classNames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

interface Props {
  additionalClassName?: string
  as?: keyof JSX.IntrinsicElements
  contentAlignment?: string
  id?: string
}

export const Grid: FC<PropsWithChildren<Props>> = ({
  additionalClassName,
  as: Root = 'div',
  children,
  contentAlignment = 'place-content-center',
  id,
}) => (
  <Root
    className={classNames(
      'grid grid-cols-mobile gap-x-30',
      'tb:grid-cols-tb',
      'sm:grid-cols-sm',
      'md:grid-cols-md',
      'lg:grid-cols-lg',
      'xl:grid-cols-xl',
      'xxl:grid-cols-xxl',
      contentAlignment,
      additionalClassName
    )}
    id={id}
  >
    {children}
  </Root>
)
