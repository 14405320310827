import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export enum AspectRatio {
  Square = 'pb-full',
  Fullscreen = 'pb-4/3',
  Widescreen = 'pb-16/9',
  Ultrawide = 'pb-64/27',
  '16/9' = 'pb-16/9',
  '64/27' = 'pb-64/27',
}

export interface VideoAspectRatioWrapperProps {
  aspectRatio: AspectRatio
  aspectRatioSm?: AspectRatio
  aspectRatioLg?: AspectRatio
}

// Css classes to be applied to the video element (ie iframe)
export const videoAspectRatioClasses = 'absolute w-full h-full left-0 top-0'

// Wrapper for video elements (ie iframes) that enables keeping an aspect ratio
export const VideoAspectRatioWrapper = ({
  aspectRatio: aspectRatioProps,
  aspectRatioSm,
  aspectRatioLg,
  children,
}: PropsWithChildren<VideoAspectRatioWrapperProps>) => {
  let aspectRatio: string = aspectRatioProps

  switch (aspectRatioSm) {
    case AspectRatio.Square:
      aspectRatio = classNames(aspectRatio, 'sm:pb-full')
      break
    case AspectRatio.Fullscreen:
      aspectRatio = classNames(aspectRatio, 'sm:pb-4/3')
      break
    case AspectRatio.Widescreen:
    case AspectRatio['16/9']:
      aspectRatio = classNames(aspectRatio, 'sm:pb-16/9')
      break
    case AspectRatio.Ultrawide:
    case AspectRatio['64/27']:
      aspectRatio = classNames(aspectRatio, 'sm:pb-64/27')
      break
  }

  switch (aspectRatioLg) {
    case AspectRatio.Square:
      aspectRatio = classNames(aspectRatio, 'lg:pb-full')
      break
    case AspectRatio.Fullscreen:
      aspectRatio = classNames(aspectRatio, 'lg:pb-4/3')
      break
    case AspectRatio.Widescreen:
    case AspectRatio['16/9']:
      aspectRatio = classNames(aspectRatio, 'lg:pb-16/9')
      break
    case AspectRatio.Ultrawide:
    case AspectRatio['64/27']:
      aspectRatio = classNames(aspectRatio, 'lg:pb-64/27')
      break
  }

  return <div className={classNames('relative w-full h-0', aspectRatio)}>{children}</div>
}
