import imageUrlBuilder from '@sanity/image-url'
import {
  SanityBasicImage,
  SanityImage,
  SanityPhoto,
} from '../../types/generated/graphql-types'
import { NullCheckedSanityImage } from '../types/NullCheckedSanityImage'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID ?? '',
  dataset: process.env.GATSBY_SANITY_DATASET ?? '',
}

const builder = imageUrlBuilder(sanityConfig)

// Override sanity's imageBuilder::image function to provide stricter typing since it will throw if either
// the supplied image or its asset prop are null.
// We use ReturnType here to get the return type, since the ImageUrlBuilder class is not exported from
// @sanity/image-url.
const imageBuilder = {
  image: (
    image: NullCheckedSanityImage<SanityImage | SanityBasicImage | SanityPhoto>
  ): ReturnType<typeof builder.image> => builder.image(image),
}

export default imageBuilder
