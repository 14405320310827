import { useStaticQueryWithPreview } from '@tedgustaf/gatsby-plugin-sanity-preview'
import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, PropsWithChildren, useMemo } from 'react'
import {
  SanityStandardPageConnection,
  SanityTwoStepDisclaimer,
  SanityTwoStepDisclaimerConnection,
} from '../../types/generated/graphql-types'
import { usePageContext } from '../hooks/usePageContext'
import { siteWideDisclaimerPreviewQueryProjection } from '../preview/staticQueries/siteWideDisclaimer'
import { notEmpty } from '../utilities/filters'

export type SiteWideDisclaimerContextState = {
  disclaimerData?: SanityTwoStepDisclaimer
  publicPagePaths?: string[]
}

type QueryResult = {
  allSanityTwoStepDisclaimer?: SanityTwoStepDisclaimerConnection
  publicPages?: SanityStandardPageConnection
}

const SiteWideDisclaimerContext = createContext<SiteWideDisclaimerContextState>({})

const SiteWideDisclaimerContextProvider = ({ children }: PropsWithChildren) => {
  const queryResult = useStaticQueryWithPreview({
    staticQueryData: useStaticQuery<QueryResult>(graphql`
      query twoStepDisclaimerQuery {
        allSanityTwoStepDisclaimer {
          nodes {
            annexOne {
              _rawBody(resolveReferences: { maxDepth: 12 })
              acceptTermsButtonLabel
              acceptTermsCheckboxLabel
              acceptTermsText
              declineTermsButtonLabel
              declineTermsUrl
              title
            }
            introduction {
              _rawDisclaimerBody(resolveReferences: { maxDepth: 12 })
              countries {
                _rawEligibilityConfirmationText(resolveReferences: { maxDepth: 12 })
                _rawProfessionalDisclaimerBody(resolveReferences: { maxDepth: 12 })
                _rawNonProfessionalRedirectAccessText(resolveReferences: { maxDepth: 12 })
                _rawProfessionalRedirectAccessText(resolveReferences: { maxDepth: 12 })
                name
                nonProfessionalAccess
                nonProfessionalFullAccess
                professionalAccess
                professionalDisclaimerTitle
                simpleAccess
              }
              countriesIntro
              investorIntro
              nonProfessionalInvestorBulletPoints
              nonProfessionalInvestorLabel
              preamble
              preambleLinks {
                ...FileLinkFragment
                ...LinkFragment
              }
              privacyPolicyLabel
              privacyPolicyPage {
                ... on PageBase {
                  _id
                  path
                  title
                }
              }
              professionalInvestorBulletPoints
              professionalInvestorLabel
              selectCountryLabel
              selectCountryPlaceholder
              selectInvestorLabel
              selectInvestorPlaceholder
              submitButtonLabel
              title
            }
            language
            noAccessNotice {
              _rawBody(resolveReferences: { maxDepth: 12 })
              buttonLabel
              title
            }
            nonProfessionals {
              accessiblePage {
                ... on PageBase {
                  _id
                  path
                  title
                }
              }
              confirmEligibilityButtonLabel
              declineEligibilityButtonLabel
              title
            }
            simpleDisclaimer
          }
        }

        publicPages: allSanityStandardPage(filter: { isPublicPage: { eq: true } }) {
          nodes {
            path
          }
        }
      }
    `),
    query: siteWideDisclaimerPreviewQueryProjection,
    options: useMemo(
      () => ({
        addRawFields: true,
        resolveReferences: true,
      }),
      []
    ),
  })

  const pageContext = usePageContext()

  const nodes = queryResult?.allSanityTwoStepDisclaimer?.nodes
  const publicPages = queryResult?.publicPages?.nodes

  const value = useMemo(
    () => ({
      disclaimerData: nodes?.find(
        disclaimer => disclaimer.language === pageContext.language
      ),
      publicPagePaths: publicPages?.map(page => page.path).filter(notEmpty),
    }),
    [nodes, pageContext.language, publicPages]
  )

  return (
    <SiteWideDisclaimerContext.Provider value={value}>
      {children}
    </SiteWideDisclaimerContext.Provider>
  )
}

export { SiteWideDisclaimerContext, SiteWideDisclaimerContextProvider }
