import classNames from 'classnames'
import React, { useState } from 'react'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Breakpoints } from '../../utilities/breakpoints'
import { isNullOrWhiteSpace } from '../../utilities/string'
import { Button, ButtonTheme } from '../Button'
import { Grid } from '../Grid'
import { ExternalIconSize } from '../Link'
import { LinkDropdown, LinksDropdown, LinksDropdownVariant } from '../LinksDropdown'
import { LanguageSelect, LanguageSelectProps } from './LanguageSelect'

export interface SitePickerProps {
  activeItemId?: string
  buttonLabel?: string
  buttonUrl?: string
  dropdownItems?: LinkDropdown[]
  dropdownLabel?: string
  dropdownLabelMobile?: string
  dropdownPlaceholder?: string
}

export interface TopNavBarProps {
  languageSelectProps?: Omit<LanguageSelectProps, 'additionalClassName'>
  sitePickerProps?: SitePickerProps
}

export const shouldRenderSitePicker = ({
  buttonLabel,
  buttonUrl,
  dropdownItems,
  dropdownLabel,
  dropdownPlaceholder,
}: SitePickerProps) =>
  !isNullOrWhiteSpace(dropdownLabel) ||
  (!!dropdownItems?.length && !isNullOrWhiteSpace(dropdownPlaceholder)) ||
  (!isNullOrWhiteSpace(buttonLabel) && !isNullOrWhiteSpace(buttonUrl))

export const TopNavBar = ({ languageSelectProps, sitePickerProps }: TopNavBarProps) => {
  const [aboveTb, setAboveTb] = useState(false)
  useBreakpoint(Breakpoints.tb, setAboveTb)

  let sitePickerDropdownLabel: string | undefined

  if (aboveTb) {
    sitePickerDropdownLabel = sitePickerProps?.dropdownLabel
  } else {
    sitePickerDropdownLabel = !isNullOrWhiteSpace(sitePickerProps?.dropdownLabelMobile)
      ? sitePickerProps?.dropdownLabelMobile
      : sitePickerProps?.dropdownLabel
  }

  const renderSitePicker = sitePickerProps && shouldRenderSitePicker(sitePickerProps)

  const renderLanguageSelect = !!languageSelectProps?.languageVersions?.length

  const largerHeightInMobile = renderSitePicker && renderLanguageSelect

  return (
    // Need relative and a z-index here to make sure the dropdown box is clickable
    <Grid
      additionalClassName={classNames('relative z-navbar bg-shade-lightest-warm', {
        'h-90 tb:h-[3.125rem]': largerHeightInMobile,
        'h-[3.125rem]': !largerHeightInMobile,
      })}
    >
      {(renderLanguageSelect || sitePickerProps) && (
        <div
          className={classNames(
            'col-span-full flex flex-col gap-10 -mx-30 px-8',
            'tb:col-start-2 tb:col-span-2 tb:flex-row tb:justify-between tb:items-center tb:-mx-60 tb:gap-30',
            'sm:col-start-2 sm:col-span-4 sm:mx-0',
            'md:col-start-1 md:col-span-6',
            'lg:col-start-2 lg:col-span-6',
            'xl:col-start-2 xl:col-span-8',
            'xxl:col-start-3 xxl:col-span-10'
          )}
        >
          {renderSitePicker && (
            <div className="flex items-center gap-15 justify-between tb:justify-start">
              <div className="flex items-center gap-10">
                {!isNullOrWhiteSpace(sitePickerDropdownLabel) && (
                  <div className="text-neutral-dark font-t-bold text-t-200">
                    {sitePickerDropdownLabel}
                  </div>
                )}

                {!!sitePickerProps.dropdownItems?.length &&
                  !isNullOrWhiteSpace(sitePickerProps.dropdownPlaceholder) && (
                    <LinksDropdown
                      activeItemId={sitePickerProps.activeItemId}
                      isFixed
                      items={sitePickerProps.dropdownItems}
                      placeholderLabel={sitePickerProps.dropdownPlaceholder}
                      variant={LinksDropdownVariant.Compact}
                    />
                  )}
              </div>

              {!isNullOrWhiteSpace(sitePickerProps.buttonLabel) &&
                !isNullOrWhiteSpace(sitePickerProps.buttonUrl) && (
                  <Button
                    externalIconSize={ExternalIconSize.None}
                    isExternalUrl
                    lowerCase
                    size="small"
                    theme={ButtonTheme.SecondaryGhost}
                    url={sitePickerProps.buttonUrl}
                  >
                    {sitePickerProps.buttonLabel}
                  </Button>
                )}
            </div>
          )}

          {renderLanguageSelect && (
            <LanguageSelect
              {...languageSelectProps}
              additionalClassName="self-end tb:self-auto"
            />
          )}
        </div>
      )}
    </Grid>
  )
}
