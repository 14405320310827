import React, { createContext, ReactNode, useState } from 'react'
import { BASE_HEADER_HEIGHT } from '../components/Header/Header'

export type HeaderDataContextState = number
export type HeaderAPIContextState = React.Dispatch<React.SetStateAction<number>>

export interface HeaderContextProviderProps {
  children?: ReactNode
}

const HeaderDataContext = createContext<HeaderDataContextState>(BASE_HEADER_HEIGHT)

// eslint-disable-next-line @typescript-eslint/no-empty-function
const HeaderAPIContext = createContext<HeaderAPIContextState>(() => {})

const HeaderContextProvider = ({ children }: HeaderContextProviderProps) => {
  const [headerHeight, setHeaderHeight] = useState(BASE_HEADER_HEIGHT)

  return (
    <HeaderAPIContext.Provider value={setHeaderHeight}>
      <HeaderDataContext.Provider value={headerHeight}>
        {children}
      </HeaderDataContext.Provider>
    </HeaderAPIContext.Provider>
  )
}

export { HeaderAPIContext, HeaderDataContext, HeaderContextProvider }
