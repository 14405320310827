import { useCallback, useContext, useEffect, useState } from 'react'
import { WindowContext } from '../context/WindowContext'
import { useResizeObserver } from './useResizeObserver'

export interface UseWindowReturnValue {
  documentClientWidth: number
  documentHeight: number
  screenHeight: number
  screenWidth: number
}

export const useWindowDimensions = (): UseWindowReturnValue => {
  const { bodyElement } = useContext(WindowContext)
  const [documentClientWidth, setDocumentClientWidth] = useState<number>(0)
  const [documentHeight, setDocumentHeight] = useState<number>(0)
  const [screenHeight, setScreenHeight] = useState<number>(0)
  const [screenWidth, setScreenWidth] = useState<number>(0)

  const resizeCallback = useCallback(
    entries => {
      if (bodyElement && entries.length > 0) {
        setDocumentClientWidth(bodyElement.clientWidth)
        setDocumentHeight(bodyElement.scrollHeight)
      }
    },
    [bodyElement]
  )

  const setResizeObserverRef = useResizeObserver(resizeCallback)

  useEffect(() => {
    setResizeObserverRef(bodyElement)
  }, [bodyElement, setResizeObserverRef])

  useEffect(() => {
    const onWindowResize = () => {
      setScreenHeight(window.innerHeight)
      setScreenWidth(window.innerWidth)
    }

    onWindowResize()
    window.addEventListener('resize', onWindowResize, { passive: true })
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return {
    documentClientWidth,
    documentHeight,
    screenHeight,
    screenWidth,
  }
}
