import React, { useEffect } from 'react'

export const useOnClickOutside = (
  ref:
    | React.MutableRefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | null>[],
  callback: (e: Event) => void
) => {
  useEffect(() => {
    const listener = (e: Event) => {
      const refs = Array.isArray(ref) ? ref : [ref]

      if (refs.every(r => r.current && r.current.contains(e.target as Node) === false)) {
        callback(e)
      }
    }

    document.addEventListener('mouseup', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mouseup', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, callback])
}
