import { PortableTextBlockComponent } from '@portabletext/react'
import React from 'react'
import { getBlockStyleWithFallback } from '../../../../utilities/portableText'

/**
 * Default "serializer" (renderer) for standard "blocks" (i.e. rich text)
 */
export const DefaultBlockSerializer: PortableTextBlockComponent = ({
  children,
  value,
}) => {
  let HtmlElement: keyof JSX.IntrinsicElements | null = null
  let className: string | undefined = undefined

  if (!value) {
    return null
  }

  const style = getBlockStyleWithFallback(value.style)

  switch (style) {
    case 'normal':
      HtmlElement = 'p'
      className = 'font-body text-body mb-30 last:mb-0 text-secondary-darker'
      break

    case 'blockquote':
      HtmlElement = 'blockquote'
      className =
        'font-t-light text-t-300 mb-30 text-secondary-darker px-15 border-l border-primary xl:text-t-500'
      break

    case 'h1':
      HtmlElement = 'h4'
      className = 'font-h3 text-h3 text-secondary-darker mb-30'
      break

    default:
      // In most use cases, the "style" maps to an actual HTML tag name
      HtmlElement = style as keyof JSX.IntrinsicElements
      break
  }

  return <HtmlElement className={className}>{children}</HtmlElement>
}
