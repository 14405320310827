import { PortableTextMarkComponent } from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import React from 'react'
import { Maybe, SanityAttribute } from '../../../../../types/generated/graphql-types'
import { mapSanityAttributeArrayToCustomAttributes } from '../../../../mapping/LinkMapper'
import { notEmpty } from '../../../../utilities/filters'
import { isNullOrWhiteSpace } from '../../../../utilities/string'
import { Link } from '../../../Link'

interface LinkAnnotation extends TypedObject {
  customAttributes?: Maybe<SanityAttribute>[]
  href?: string
}

export const linkClassName =
  'font-normal border-b border-quinary transition-hover hover:opacity-hover hover:border-opacity-50 hover:border-secondary-darker'

export const DefaultLinkSerializer: PortableTextMarkComponent<LinkAnnotation> = ({
  children,
  value,
}) => {
  const { customAttributes, href } = value ?? {}

  if (!isNullOrWhiteSpace(href)) {
    const customAttributesDict = mapSanityAttributeArrayToCustomAttributes(
      customAttributes?.filter(notEmpty) ?? []
    )

    return (
      <Link
        className={linkClassName}
        customAttributes={customAttributesDict}
        isExternal
        url={href}
      >
        {children}
      </Link>
    )
  }

  console.error('Invalid link, no href')

  return <>{children}</>
}
