import { PortableTextMarkComponent } from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import React from 'react'
import { Maybe, SanityAttribute } from '../../../../../types/generated/graphql-types'
import { mapSanityAttributeArrayToCustomAttributes } from '../../../../mapping/LinkMapper'
import { notEmpty } from '../../../../utilities/filters'
import { getUrlFragments, SanityNode } from '../../../../utilities/routing'
import { Link } from '../../../Link'
import { linkClassName } from './DefaultLinkSerializer'

interface PageAnnotation extends TypedObject {
  customAttributes?: Maybe<SanityAttribute>[]
  page?: SanityNode
}

// NOTE: to resolve any properties other than _ref from the annotation,
// resolveReferences with a maxDepth must be set in the page query
// when querying _rawBody that has pageAnnotation in Sanity.
export const PageReferenceSerializer: PortableTextMarkComponent<PageAnnotation> = ({
  children,
  value,
}) => {
  const { customAttributes, page } = value ?? {}

  if (page) {
    // TODO: Investigate if useUrlMap would work here without any build time issues
    const urlFragments = getUrlFragments(page)

    if (urlFragments.length > 0 || page._type === 'homePage') {
      const url = `/${urlFragments.join('/')}`

      const customAttributesDict = mapSanityAttributeArrayToCustomAttributes(
        customAttributes?.filter(notEmpty) ?? []
      )

      return (
        <Link className={linkClassName} customAttributes={customAttributesDict} url={url}>
          {children}
        </Link>
      )
    }
  }

  console.error('Invalid page reference')

  return <>{children}</>
}
