import { navigate } from 'gatsby'
import { useCallback, useContext, useMemo, useState } from 'react'
import { SiteWideDisclaimerContext } from '../../../context/SiteWideDisclaimerContext'
import { usePageContext } from '../../../hooks/usePageContext'
import { getCookieValue, setCookie } from '../../../utilities/cookie'
import { isBrowser } from '../../../utilities/isBrowser'
import { isNullOrWhiteSpace } from '../../../utilities/string'
import { OnAcceptDisclaimerCallback } from './TwoStepDisclaimer'

const COOKIE_TIMEOUT = 60 * 60 * 24 * 365

export const useSiteWideDisclaimer = () => {
  const pageContext = usePageContext()

  const [accessGranted, setAccessGranted] = useState(false)
  const { disclaimerData, publicPagePaths } = useContext(SiteWideDisclaimerContext)

  const enabled = !!disclaimerData
  const privacyPolicyPagePath = disclaimerData?.introduction?.privacyPolicyPage?.path

  const onGrantAccess = useCallback<OnAcceptDisclaimerCallback>(
    async ({ accessiblePageUrl, country, investorType }) => {
      setCookie('eqt_tsd', accessiblePageUrl ?? 'true', COOKIE_TIMEOUT)
      setCookie('eqt_tsd_investor_origin', country, COOKIE_TIMEOUT)

      if (investorType) {
        setCookie('eqt_tsd_investor_type', investorType, COOKIE_TIMEOUT)
      }

      if (!isNullOrWhiteSpace(accessiblePageUrl)) {
        // Note: Setting hasConsented to true before or after the navigate call causes the current page to flicker briefly,
        // so don't set it at all here (the above useLayoutEffect will be re-run upon page change anyway, checking the cookie).
        await navigate(accessiblePageUrl)
      } else {
        setAccessGranted(true)
      }
    },
    []
  )

  const isPagePublic = useCallback(
    (path: string) => {
      if (!path.endsWith('/')) {
        // TODO: Paths from PageReferenceSerializer do not end in a trailing slash, while paths generated by Gatsby do.
        // We should probably also add a trailing slash directly inside PageReferenceSerializer, but let's do it here
        // just in case.
        path = `${path}/`
      }

      return path === privacyPolicyPagePath || publicPagePaths?.includes(path) === true
    },
    [privacyPolicyPagePath, publicPagePaths]
  )

  const hasAccessToPath = useCallback(
    (path: string) => {
      if (enabled && !isPagePublic(path)) {
        const cookieValue = isBrowser() ? getCookieValue('eqt_tsd') : undefined

        if (!isNullOrWhiteSpace(cookieValue)) {
          return cookieValue === 'true' || cookieValue === path
        } else {
          return false
        }
      } else {
        return true
      }
    },
    [enabled, isPagePublic]
  )

  const currentPagePublic = !!pageContext.path && isPagePublic(pageContext.path)

  return useMemo(
    () => ({
      accessGranted,
      currentPagePublic,
      disclaimerData,
      enabled,
      hasAccessToPath,
      onGrantAccess,
      setAccessGranted,
    }),
    [
      accessGranted,
      currentPagePublic,
      disclaimerData,
      enabled,
      hasAccessToPath,
      onGrantAccess,
      setAccessGranted,
    ]
  )
}
