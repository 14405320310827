import { TypedObject } from '@portabletext/types'
import { isNullOrWhiteSpace } from './string'

// We go through some hoops in this file to avoid importing types from generated/graphql-types.
// This is due to gatsby using functions from this file at SSR, where the typings have not been
// generated yet.

export interface ImageWithNullableAsset {
  asset?: Record<string, unknown> | null
}

export interface ImageWithAsset {
  asset: Record<string, unknown>
}

export type NullableBlock = {
  _type?: string | null
}

/**
 * Filter empty values (null, undefined, or empty strings).
 * Also handles Maybe<T>.
 */
export const notEmpty = <T>(item: T | null | undefined): item is T => {
  if (item === null || item === undefined) {
    return false
  }

  if (typeof item === 'string' && isNullOrWhiteSpace(item as string)) {
    return false
  }

  return true
}

export const validBlock = (item: NullableBlock | null | undefined): item is TypedObject =>
  !isNullOrWhiteSpace(item?._type)

/**
 * Filter empty values (null, undefined, empty strings or empty span) in raw body.
 */
export const notEmptyTextBlock = (
  item: object | { _type: string; children: [] } | { text: string } | null | undefined
): boolean => {
  if (item === null || item === undefined) {
    return false
  }

  if ('_type' in item) {
    if (item._type === 'block') {
      if (!!item.children && !!item.children.length) {
        return item.children.filter(notEmptyTextBlock).length > 0
      } else {
        return false
      }
    }
  }

  if ('text' in item) {
    return !isNullOrWhiteSpace(item.text)
  }

  if (typeof item === 'string' && isNullOrWhiteSpace(item as string)) {
    return false
  }

  return true
}

/**
 * Filter empty values (null, undefined, empty strings or '-').
 * Also handles Maybe<T>.
 */
export const notEmptyCells = <T>(cell: T | null | undefined): cell is T => {
  const cellPlaceholder = '-'
  if (cell === null || cell === undefined) {
    return false
  }

  if (
    typeof cell === 'string' &&
    (isNullOrWhiteSpace(cell as string) || cell === cellPlaceholder)
  ) {
    return false
  }

  return true
}

type NullableRow = {
  _key?: string | null
  cells?: (string | null)[] | null
}

/**
 * Filter empty table rows (null, undefined, or empty cells).
 * Also handles Maybe<T>.
 */
export const notEmptyRows = (
  row: NullableRow | null | undefined
): row is { cells: string[]; _key: string } => {
  if (row) {
    if ('cells' in row) {
      return !!row.cells?.filter(notEmptyCells).length
    }

    return true
  }

  return false
}

/**
 * Null checks image objects from sanity
 */
export const validImage = <T extends ImageWithNullableAsset>(
  image: T | null | undefined
): image is T & ImageWithAsset => !!image?.asset
