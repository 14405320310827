module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EQT","short_name":"EQT","start_url":"/","background_color":"#FFFFFF","theme_color":"#FF5703","display":"minimal-ui","icon":"src/assets/icons/favicon/eqt_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"053916a594749c0f4b06f8f5570b61bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Access-Control-Allow-Origin: https://eqt-athens-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-china-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-foundation-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-japan-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-korea-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-odin-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-sweden-sanity-studio.eqtpartners.com","Access-Control-Allow-Origin: https://eqt-web-sanity-studio.eqtpartners.com"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
