import { PortableText, PortableTextProps } from '@portabletext/react'
import React from 'react'
import { CookieTractorTable } from '../CookieTractor/CookieTractorTable'
import { DefaultBlockSerializer } from './serializers/blocks/DefaultBlockSerializer'
import { BasicImageSerializer } from './serializers/componentTypes/BasicImageSerializer'
import { HtmlSerializer } from './serializers/componentTypes/HtmlSerializer'
import { PhotoSerializer } from './serializers/componentTypes/PhotoSerializer'
import { PillSerializer } from './serializers/componentTypes/PillSerializer'
import { TableBlockSerializer } from './serializers/componentTypes/TableBlockSerializer'
import { VideoSerializer } from './serializers/componentTypes/VideoSerializer'
import { DefaultListItemSerializer } from './serializers/lists/DefaultListItemSerializer'
import { DefaultListSerializer } from './serializers/lists/DefaultListSerializer'
import { AssetReferenceSerializer } from './serializers/marks/AssetReferenceSerializer'
import { ColoredTextSerializer } from './serializers/marks/ColoredTextSerializer'
import { DefaultLinkSerializer } from './serializers/marks/DefaultLinkSerializer'
import { PageReferenceSerializer } from './serializers/marks/PageReferenceSerializer'

export type RichTextAreaProps = {
  additionalBlockProps?: Record<string, Record<string, unknown>>
} & Omit<PortableTextProps, 'listNestingMode' | 'onMissingComponent'>

export const RichTextArea = ({
  additionalBlockProps,
  components,
  value,
}: RichTextAreaProps) => {
  const { block, marks, list, listItem, types } = components ?? {}

  return (
    <PortableText
      components={{
        block: block ?? DefaultBlockSerializer,
        marks: {
          colored: ColoredTextSerializer,
          fileAnnotation: AssetReferenceSerializer,
          imageAnnotation: AssetReferenceSerializer,
          link: DefaultLinkSerializer,
          pageAnnotation: PageReferenceSerializer,
          subscript: ({ children }) => <sub>{children}</sub>,
          superscript: ({ children }) => <sup>{children}</sup>,
          ...marks,
        },
        list: list ?? DefaultListSerializer,
        listItem: listItem ?? DefaultListItemSerializer,
        types: {
          basicImage: ({ value }) => (
            <BasicImageSerializer {...value} {...additionalBlockProps?.basicImage} />
          ),
          html: ({ value }) => (
            <HtmlSerializer {...value} {...additionalBlockProps?.html} />
          ),
          cookieTractorTable: ({ value }) => <CookieTractorTable {...value} />,
          photo: ({ value }) => (
            <PhotoSerializer {...value} {...additionalBlockProps?.photo} />
          ),
          pill: ({ value }) => (
            <PillSerializer {...value} {...additionalBlockProps?.pill} />
          ),
          tableBlock: ({ value }) => (
            <TableBlockSerializer {...value} {...additionalBlockProps?.tableBlock} />
          ),
          video: ({ value }) => (
            <VideoSerializer {...value} {...additionalBlockProps?.video} />
          ),
          ...types,
        },
      }}
      value={value}
    />
  )
}
