import React from 'react'
import { SanityBasicImage } from '../../../../../types/generated/graphql-types'
import { validImage } from '../../../../utilities/imageFilters'
import { getImageProps } from '../../../../utilities/imagePropUtils'
import { Image } from '../../../Image'

export type PhotoSerializerProps = SanityBasicImage

export const PhotoSerializer = (image: PhotoSerializerProps) => {
  if (validImage(image)) {
    const imageProps = getImageProps(image, null)

    return <Image className="mb-30 last:mb-0" {...imageProps} />
  } else {
    return null
  }
}
