import { addHook, removeHook, sanitize } from 'isomorphic-dompurify'

export const isNullOrWhiteSpace = (
  input: string | undefined | null
): input is undefined | null => !input || !input.trim()

export const trimMultilineString = (value: string) => value.replace(/\s\s+/g, ' ').trim()

export const isInternalLink = (url: string): boolean =>
  (url.startsWith('/') && !url.startsWith('//')) || url.startsWith('#')

export const stripNonAlphaNumeric = (s: string): string => s?.replace(/[^A-Za-z0-9]/g, '')

export const stripWhiteSpace = (s: string): string => s?.replace(/\s/g, '')

/** True if all characters are identical, e.g. 'aaa' */
export const isAllEqualCharacters = (s: string): boolean =>
  s?.split('').every(char => char === s[0]) ?? true

/**
 * Returns string value cleaned from dangerous html such as script tags.
 *
 * @param value - The original string value
 * @param allowedDomains - (Optional) String value containing comma separated list with
 * domains other that youtube and vimeo allowed as src in iframe.
 * @param replaceSup - (Optional) default value is true, replaces all [] containing numbers with <sup> element
 */
export const sanitizeHtmlAndReplaceNewlines = (
  value: string,
  allowedDomains?: string | undefined | null,
  replaceSup = true
) => {
  if (replaceSup) {
    value = replaceSupEntries(value ?? '')
  }

  let allowedIframeDomains = ['youtube.com', 'vimeo.com']

  if (allowedDomains) {
    allowedIframeDomains = allowedIframeDomains.concat(allowedDomains.split(','))
  }

  // Validate iframe domains
  addHook('uponSanitizeAttribute', (node, event) => {
    if (node.tagName === 'IFRAME' && event.attrName === 'src' && event.attrValue) {
      try {
        const url = new URL(event.attrValue)

        if (
          !allowedIframeDomains.includes(url.hostname) &&
          !allowedIframeDomains.includes(url.origin)
        ) {
          event.keepAttr = false
        }
      } catch {
        event.keepAttr = false
      }
    }
  })

  // Workaround to allow malformed iframe elements such as self-closing iframes - unset their innerHTML
  addHook('uponSanitizeElement', (node, _data) => {
    if (node.tagName === 'IFRAME' && node.innerHTML) {
      node.innerHTML = ''
    }
  })

  const sanitizedHtml = sanitize(value.replace(/\r?\n/g, '<br />'), {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'loading'],
  })

  // Cleanup the uponSanitizeAttribute hook so that it can be set again the next time this function runs,
  // since uponSanitizeAttribute relies upon external data (the allowedIframeDomains array)
  removeHook('uponSanitizeAttribute')

  return sanitizedHtml
}

/** Replaces text written with [0-9] in text as <sup> elements */
export const replaceSupEntries = (value: string): string =>
  value
    ?.split(/(\[[0-9,]+\])/)
    .map(token =>
      token.startsWith('[') && token.endsWith(']')
        ? `<sup>${token.slice(1, token.length - 1)}</sup>`
        : token
    )
    ?.join('')

export const hasFileExtension = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || isNullOrWhiteSpace(value)) {
    return false
  }

  return !!value.match(/\.([a-zA-Z0-9]{3,4})+$/)
}
