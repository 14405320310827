import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react'

export type WindowContextType = {
  bodyElement: HTMLElement | null
  setBodyElement: Dispatch<SetStateAction<HTMLElement | null>>
  setWindowElement: Dispatch<SetStateAction<HTMLElement | Window | null>>
  windowElement: HTMLElement | Window | null
}

// Dummy defaultValue is required here for "gatsby build" to succeed. The "real" initial value is set below with useState though.
const WindowContext = createContext<WindowContextType>({} as unknown as WindowContextType)

const WindowContextProvider = ({ children }: PropsWithChildren) => {
  const [bodyElement, setBodyElement] = useState<HTMLElement | null>(null)
  const [windowElement, setWindowElement] = useState<HTMLElement | Window | null>(null)

  const memoized = useMemo(() => {
    let bodyEl: HTMLElement | null
    let windowEl: HTMLElement | Window | null

    if (bodyElement) {
      bodyEl = bodyElement
    } else if (typeof window !== 'undefined') {
      bodyEl = document.body
    } else {
      bodyEl = null
    }

    if (windowElement) {
      windowEl = windowElement
    } else if (typeof window !== 'undefined') {
      windowEl = window
    } else {
      windowEl = null
    }

    return {
      bodyElement: bodyEl,
      windowElement: windowEl,
      setBodyElement,
      setWindowElement,
    }
  }, [bodyElement, windowElement])

  return <WindowContext.Provider value={memoized}>{children}</WindowContext.Provider>
}

export { WindowContext, WindowContextProvider }
