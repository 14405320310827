import classNames from 'classnames'
import React from 'react'
import { SanityVideo } from '../../../../../types/generated/graphql-types'
import {
  sanityVideoToVideoComponentProps,
  VideoType,
} from '../../../../mapping/VideoMapper'
import { BlockWithId } from '../../../../utilities/blockId'
import { isNullOrWhiteSpace } from '../../../../utilities/string'
import { Section } from '../../../Section'
import { VideoComponent } from '../../../VideoComponent'

export interface VideoSerializerProps extends SanityVideo, BlockWithId {
  isSection?: boolean
  videoStyle?: 'normal' | 'rounded'
}

export const VideoSerializer = ({
  id,
  isSection,
  videoStyle,
  ...node
}: VideoSerializerProps) => {
  let Wrapper: keyof JSX.IntrinsicElements
  let Title: keyof JSX.IntrinsicElements

  if (isSection) {
    Wrapper = 'section'
    Title = 'h1'
  } else {
    Wrapper = 'div'
    Title = 'h2'
  }

  if (videoStyle === 'rounded' && !node?.displayTitle) {
    return (
      <Section id={id} as={Wrapper}>
        <div
          className={classNames(
            'rounded overflow-hidden mx-auto',
            'tb:max-w-[31.25rem]',
            'sm:max-w-[37.5rem]',
            'md:max-w-[46.875rem]',
            'lg:max-w-[52.5rem]',
            'xl:max-w-[65.625rem]'
          )}
        >
          <VideoComponent {...sanityVideoToVideoComponentProps(node, VideoType.Large)} />
        </div>
      </Section>
    )
  }

  return (
    <Section additionalClassName="xxl:max-w-[2560px] xxl:mx-auto" id={id} as={Wrapper}>
      {node.displayTitle && !isNullOrWhiteSpace(node.title) && (
        <Title
          className={classNames(
            'block w-240 mx-auto mt-30 mb-45 font-h1 text-h1 text-center text-primary',
            'tb:hidden'
          )}
        >
          {node.title}
        </Title>
      )}

      <VideoComponent {...sanityVideoToVideoComponentProps(node, VideoType.Large)}>
        {node.displayTitle && !isNullOrWhiteSpace(node.title) && (
          <Title
            className={classNames(
              'text-neutral col-start-1 col-span-2 font-h1 text-h1 capitalize pointer-events-auto',
              'sm:col-start-2 sm:col-span-3 sm:-ml-30',
              'md:col-start-1 md:col-span-3 md:ml-0',
              'lg:col-start-2 lg:col-span-3',
              'xxl:col-start-3 xxl:col-span-3'
            )}
          >
            {node.title}
          </Title>
        )}
      </VideoComponent>
    </Section>
  )
}
