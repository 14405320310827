import React, { RefObject, useEffect, useState } from 'react'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { useWindowScroll } from '../../hooks/useWindowScroll'

export interface ScrollProgressBarProps {
  parentRef?: RefObject<HTMLSpanElement>
}

export const ScrollProgressBar = ({ parentRef }: ScrollProgressBarProps): JSX.Element => {
  const [width, setWidth] = useState(0)
  const { documentHeight, screenHeight } = useWindowDimensions()
  const scrollYPosition = useWindowScroll()

  useEffect(() => {
    const maxHeight = documentHeight - screenHeight
    const maxWidth = parentRef?.current
      ? parentRef.current.scrollWidth
      : document.body.scrollWidth
    setWidth((scrollYPosition / maxHeight) * maxWidth)
  }, [documentHeight, parentRef, screenHeight, scrollYPosition])

  return (
    <div
      className="h-2 absolute left-0 bottom-0 -mb-2 bg-primary"
      style={{
        width: `${width}px`,
      }}
    ></div>
  )
}
