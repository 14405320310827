import { useStaticQueryWithPreview } from '@tedgustaf/gatsby-plugin-sanity-preview'
import { graphql, useStaticQuery } from 'gatsby'
import { Settings } from 'luxon'
import { SanitySiteBannerConnection } from '../../types/generated/graphql-types'
import { siteBannerPreviewQueryProjection } from '../preview/staticQueries/siteBanner'

type QueryResult = {
  allSanitySiteBanner?: SanitySiteBannerConnection
}

export const useSiteBanner = () => {
  const queryResult = useStaticQueryWithPreview({
    staticQueryData: useStaticQuery<QueryResult>(graphql`
      query siteBannerQuery {
        allSanitySiteBanner {
          nodes {
            id
            title
            _rawMessage(resolveReferences: { maxDepth: 12 })
            active
            links {
              ...LinkFragment
            }
            language
            visibilityRules {
              ...BannerCountryRuleFragment
            }
          }
        }
      }
    `),
    query: siteBannerPreviewQueryProjection,
  })

  let banners = queryResult?.allSanitySiteBanner?.nodes.filter(
    node => node.language === 'en'
  )
  if (!!Settings.defaultLocale && Settings.defaultLocale !== 'en') {
    banners = queryResult?.allSanitySiteBanner?.nodes?.filter(
      node => node.language === Settings.defaultLocale
    )
  }

  return banners
}
