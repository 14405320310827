import classNames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'
import { isNullOrWhiteSpace } from '../utilities/string'
import { ExternalIconSize, Link } from './Link'

export interface PillButtonProps extends PropsWithChildren<unknown> {
  active?: boolean
  additionalClassName?: string
  isExternalUrl?: boolean
  /**
   * Click handler, turns component into a button.
   * If property 'url' is set, this will be ignored
   */
  onClick?: () => void
  tabIndex?: number
  title?: string
  /**
   * Link URL, turns component into a link.
   * Prioritized over 'onClick' handler
   */
  url?: string
}

export const PillButton: FC<PillButtonProps> = ({
  active,
  additionalClassName,
  children,
  isExternalUrl,
  onClick,
  tabIndex,
  title,
  url,
}) => {
  let className = classNames(
    'font-navbar-pill text-navbar-pill inline-flex items-center px-10 py-3 uppercase text-secondary-darker border rounded transition-hover',
    'hover:text-neutral hover:bg-primary hover:border-primary',
    'focus:text-neutral focus:bg-primary focus:border-primary focus:ring focus:ring-primary/40 focus:outline-none',
    {
      'border-quinary': active,
      'border-secondary-darker': !active,
    }
  )

  if (!isNullOrWhiteSpace(additionalClassName)) {
    className += ` ${additionalClassName}`
  }

  if (url?.length) {
    return (
      <Link
        className={className}
        isExternal={isExternalUrl}
        externalIconSize={ExternalIconSize.Lg}
        hideContentIfNoAccess
        title={title}
        url={url}
        tabIndex={tabIndex}
      >
        {children}
      </Link>
    )
  }

  return (
    <button className={className} onClick={onClick} title={title} tabIndex={tabIndex}>
      {children}
    </button>
  )
}
