import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { CustomAttributes } from '../types/CustomAttributes'
import { isNullOrWhiteSpace } from '../utilities/string'
import { ExternalIconSize, Link } from './Link'

export enum InteractionType {
  FadeOut,
  None,
}

export interface LinkWrapperProps {
  additionalClassName?: string
  additionalLinkClassName?: string
  customAttributes?: CustomAttributes
  externalIconSize?: ExternalIconSize
  fallbackElement?: keyof JSX.IntrinsicElements | typeof React.Fragment
  interactionType?: InteractionType
  isExternal?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  url?: string
}

export const LinkWrapper = ({
  additionalClassName,
  additionalLinkClassName = '',
  children,
  customAttributes,
  externalIconSize,
  fallbackElement: FallbackElement = 'span',
  interactionType = InteractionType.FadeOut,
  isExternal,
  onClick,
  url,
}: PropsWithChildren<LinkWrapperProps>) => {
  let linkClassName = ''

  switch (interactionType) {
    case InteractionType.FadeOut:
      linkClassName =
        'transition-hover hover:opacity-hover focus:opacity-hover focus:outline-none'
      break
  }

  return !isNullOrWhiteSpace(url) ? (
    <Link
      className={classNames(linkClassName, additionalClassName, additionalLinkClassName)}
      customAttributes={customAttributes}
      isExternal={isExternal}
      externalIconSize={externalIconSize}
      onClick={onClick}
      url={url}
    >
      {children}
    </Link>
  ) : (
    <FallbackElement className={additionalClassName}>{children}</FallbackElement>
  )
}
