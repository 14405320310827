import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { SanitySiteBanner } from '../../../types/generated/graphql-types'
import Cross from '../../assets/icons/cross.svg'
import Info from '../../assets/icons/info.svg'
import { useSiteBanner } from '../../hooks/useBanner'
import { usePageContext } from '../../hooks/usePageContext'
import { mapSanityLinkToInternalExternalLink } from '../../mapping/LinkMapper'
import { getCookieValue, setCookie } from '../../utilities/cookie'
import { notEmpty } from '../../utilities/filters'
import { Button, ButtonTheme } from '../Button'
import { Grid } from '../Grid'
import { RichTextArea } from '../PortableText/RichTextArea'
import { checkBannerVisibility, VisibilityRules } from './SiteBannerUtilities'

export interface SiteBannerThemeSettings {
  border: string
  heading: string
  bgColor: string
  iconBg: string
}

const getTheme = (theme = 'alert'): SiteBannerThemeSettings => {
  const colorSettings = {
    border: '',
    heading: '',
    bgColor: '',
    iconBg: '',
  }

  switch (theme) {
    case 'information':
      colorSettings.border = 'border-success'
      colorSettings.iconBg = 'bg-success text-white'
      colorSettings.bgColor = 'bg-quaternary-lightest'
      colorSettings.heading = 'text-success'
      break
    case 'warning':
      colorSettings.border = 'border-sdg-12'
      colorSettings.iconBg = 'bg-sdg-12 text-white'
      colorSettings.bgColor = 'bg-quinary-lightest'
      colorSettings.heading = 'text-sdg-12'
      break
    case 'alert':
    default:
      colorSettings.border = 'border-secondary'
      colorSettings.iconBg = 'bg-secondary text-white'
      colorSettings.bgColor = 'bg-secondary-lightest'
      colorSettings.heading = 'text-secondary'
      break
  }

  return colorSettings
}

export const SiteBanner: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [close, setClose] = useState<boolean>(false)
  const [colorSettings, setColorSettings] = useState<SiteBannerThemeSettings>(getTheme())
  const [currentBanner, setCurrentBanner] = useState<SanitySiteBanner | null>(null)
  const pageContext = usePageContext()
  const siteBanner = useSiteBanner()
  const visibilityCookieName = 'eqt_sitebanner_'

  useEffect(() => {
    if (!!currentBanner && currentBanner !== null) {
      const hasSeen =
        getCookieValue(`${visibilityCookieName}${currentBanner?.id}`) === 'true'
      if (!hasSeen) {
        setVisible(true)
      }
    }
  }, [pageContext.path, currentBanner])

  useEffect(() => {
    if (typeof window !== undefined && !!siteBanner) {
      const matchingBanners = siteBanner.filter(
        banner =>
          banner.active &&
          pageContext.language === banner.language &&
          checkBannerVisibility(
            banner.visibilityRules as VisibilityRules[],
            window.location.search
          )
      )
      const banner =
        !!matchingBanners && matchingBanners.length > 0 ? matchingBanners[0] : null
      setCurrentBanner(banner)
    }
  }, [siteBanner, pageContext.language])

  useEffect(() => {
    if (!!currentBanner) {
      const colorSettings = getTheme(currentBanner?.theme ?? 'alert')
      setColorSettings(colorSettings)
    }
  }, [currentBanner])

  if (!siteBanner || siteBanner.length === 0) {
    return <></>
  }

  const onClose = () => {
    if (!!currentBanner) {
      setCookie(`${visibilityCookieName}${currentBanner?.id}`, 'true')
      setTimeout(() => {
        setVisible(false)
      }, 2500)
      setClose(true)
    }
  }

  const links =
    currentBanner?.links
      ?.filter(notEmpty)
      .map(link => mapSanityLinkToInternalExternalLink(link)) ?? undefined

  return (
    <Grid
      additionalClassName={classNames(
        'rounded-b-15 w-full absolute top-2 p-20 tb:p-30 opacity-1 transition-all mt-0 duration-500',
        { 'hidden': !visible },
        { 'opacity-0 mt-[-50px]': close },
        `border ${colorSettings.border} ${colorSettings.bgColor}`
      )}
    >
      <div className={classNames('col-span-full flex flex-row w-full gap-10')}>
        <div className={classNames('flex flex-1 flex-row gap-10 p-10')}>
          <div
            className={classNames(
              'rounded-5 p-5 h-[1.375rem] w-[1.375rem] flex items-center justify-center mt-2 tb:mt-5',
              `${colorSettings.iconBg}`
            )}
          >
            <Info />
          </div>
          <div className={classNames('flex flex-col gap-30')}>
            <div className={classNames('flex flex-col gap-15 tb:gap-20')}>
              <div className={classNames('flex flex-row', `${colorSettings.heading}`)}>
                <h3 className={classNames('text-t-500 tb:text-t-600')}>
                  {currentBanner?.title ?? 'Important notice'}
                </h3>
              </div>
              <div className={classNames('text-secondary-darkest')}>
                {currentBanner?._rawMessage && (
                  <RichTextArea value={currentBanner?._rawMessage} />
                )}
              </div>
            </div>
            <div
              onClick={onClose}
              className={classNames(
                'flex flex-col tb:flex-row gap-15 items-center justify-center tb:justify-start'
              )}
            >
              {!!links &&
                links.map((link, index) => (
                  <Button
                    {...link}
                    key={index}
                    size="large"
                    theme={index === 0 ? ButtonTheme.Primary : ButtonTheme.SecondaryGhost}
                    additionalClassName={`w-full tb:w-auto justify-center ${
                      index !== 0 ? 'border-transparent' : ''
                    }`}
                  >
                    {link.title}
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div
          className={classNames('w-30 h-30 pl-8 pr-8 cursor-pointer')}
          onClick={onClose}
        >
          <Cross className="fill-current text-secondary-darkest" />
        </div>
      </div>
    </Grid>
  )
}
