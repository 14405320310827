import React, { FC } from 'react'
import { Maybe } from '../../../../../types/generated/graphql-types'
import { useSiteSettings } from '../../../../hooks/useSiteSettings'
import { sanitizeHtmlAndReplaceNewlines } from '../../../../utilities/string'

export interface HtmlSerializerProps {
  code?: Maybe<string> | undefined
  wrapperClassNames?: string
}

export const HtmlSerializer: FC<HtmlSerializerProps> = ({
  code,
  wrapperClassNames = '',
}) => {
  const siteSettings = useSiteSettings()
  const allowedDomains = siteSettings?.allowedIframeDomains ?? ''

  return code ? (
    <div
      className={wrapperClassNames}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtmlAndReplaceNewlines(code, allowedDomains),
      }}
    />
  ) : null
}
