import classNames from 'classnames'
import React from 'react'

export interface HamburgerButtonProps {
  additionalClassName?: string
  active: boolean
  label: string
  onClick: () => void
}

const commonClassName =
  'bg-primary h-2 absolute left-0 top-0 transform origin-left duration-200'

export const HamburgerButton = ({
  additionalClassName,
  active,
  label,
  onClick,
}: HamburgerButtonProps): JSX.Element => (
  <button
    className={classNames(
      'relative w-45 h-45 flex items-center justify-center rounded-15 pointer-events-auto bg-neutral border border-neutral-light transition-hover',
      'hover:border-primary',
      'focus:outline-none focus:border-primary focus:ring focus:ring-primary/40',
      additionalClassName
    )}
    aria-label={label}
    onClick={onClick}
    tabIndex={8}
  >
    <span className="relative w-15 h-15 block">
      <span
        className={`${commonClassName} ${active ? 'rotate-45 w-sqrt2 -mt-1' : 'w-full'}`}
      ></span>
      <span
        className={`${commonClassName} mt-7 ${active ? 'left-1/2 w-0' : 'w-full'}`}
      ></span>
      <span
        className={`${commonClassName} mt-15 ${active ? '-rotate-45 w-sqrt2' : 'w-full'}`}
      ></span>
    </span>
  </button>
)
