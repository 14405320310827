import { useContext, useEffect, useState } from 'react'
import { WindowContext } from '../context/WindowContext'

export const useWindowScroll = (): number => {
  const { windowElement } = useContext(WindowContext)
  const [scrollYPosition, setScrollYPosition] = useState<number>(0)

  useEffect(() => {
    const onScroll = () => {
      if (windowElement) {
        if ('scrollTop' in windowElement) {
          setScrollYPosition(windowElement.scrollTop)
        } else {
          setScrollYPosition(windowElement.scrollY)
        }
      }
    }

    onScroll()

    windowElement?.addEventListener('scroll', onScroll, { passive: true })
    return () => windowElement?.removeEventListener('scroll', onScroll)
  }, [windowElement])

  return scrollYPosition
}
