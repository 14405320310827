import { pathProjection } from '../pages/common'

export const siteWideDisclaimerPreviewQueryProjection = `
    {
      'allSanityTwoStepDisclaimer': {
        'nodes': *[_type == "twoStepDisclaimer"]
      },
      'publicPages': {
        'nodes': *[_type == "standardPage"] {
          'path': ${pathProjection}
        }
      }
    }
  `
