import {
  fetchAndPostProcess,
  GetPageTemplateExtraDataProps,
} from '@tedgustaf/gatsby-plugin-sanity-preview'
import { trimMultilineString } from '../../utilities/string'

export const pathProjection = trimMultilineString(`
  select(
    _type == "homePage" || defined(slug.current) => 
      select(
        defined(language) && language != '${process.env.GATSBY_BASE_LANGUAGE}' => '/' + language,
        ''
      ) + 
      coalesce(
        '/' + parentPage.page->parentPage.page->slug.current,
        ''
      ) + 
      coalesce(
        '/' + parentPage.page->slug.current,
        ''
      ) +
      select(
        (
          _type == "pressReleasePage" || 
          _type == "updatePage"
        ) && defined(publishDate) => '/' + string::split(publishDate, "-")[0],
        ''
      ) + 
      coalesce(
        '/' + slug.current,
        ''
      ) + '/',
    null
  )
`)

export const pageLinkProjection = trimMultilineString(`
  page->{
    _id,
    'path': ${pathProjection},
    preamble,
    title
  }
`)

export const linkProjection = trimMultilineString(`
  ...,
  pageReference {
    ${pageLinkProjection}
  }
`)

export const getPageBaseFragmentResult = async ({
  document,
  sanityClient,
  signal,
}: GetPageTemplateExtraDataProps) => {
  const homePage = await fetchAndPostProcess(
    `
      *[
        _type == "homePage" && 
        language == "${document.language}"
      ]
      { 
        metaDescription,
        metaImage,
        metaTitle,
        'path': ${pathProjection}
      }[0]
    `,
    sanityClient,
    { signal }
  )

  const homePageLanguageVersions = await fetchAndPostProcess(
    `
      *[
        _type == "homePage" && 
        language != "${document.language}"
      ]
      { 
        language,
        'path': ${pathProjection}
      }
    `,
    sanityClient,
    { signal }
  )

  const searchResultsPage = await fetchAndPostProcess(
    `
      *[
        _type == "searchResultsPage" && 
        language == "${document.language}"
      ]
      { 
        'path': ${pathProjection}
      }[0]
    `,
    sanityClient,
    { signal }
  )

  return {
    homePage,
    homePageLanguageVersions: {
      nodes: homePageLanguageVersions,
    },
    searchResultsPage,
  }
}

export type GetLatestNewsFragmentResultProps = GetPageTemplateExtraDataProps & {
  fetchUpdates?: boolean
  tag?: string
  theme?: string
}

export const getLatestNewsFragmentResult = async ({
  document,
  fetchUpdates,
  nonDraftId,
  sanityClient,
  signal,
  tag,
  theme,
}: GetLatestNewsFragmentResultProps) => {
  const latestPressReleasesFilters = [
    '_type == "pressReleasePage"',
    `_id != "${nonDraftId}"`,
    `language == "${document.language}"`,
    'defined(publishDate)',
  ]

  if (tag) {
    latestPressReleasesFilters.push(`"${tag}" in tags[]._id`)
  }

  if (theme) {
    latestPressReleasesFilters.push(`theme.title in "${theme}"`)
  }

  const latestPressReleases = await fetchAndPostProcess(
    `
      *[${latestPressReleasesFilters.join(' && ')}]
      { 
        _id,
        'path': ${pathProjection},
        publishDate,
        slug {
          current
        },
        title
      } | order(publishDate desc)[0..9]
    `,
    sanityClient,
    { signal }
  )

  const newsListPageLink = await fetchAndPostProcess(
    `
      *[
        _type == "newsListPage" && 
        language == "${document.language}"
      ]
      { 
        'path': ${pathProjection},
        title
      }[0]
    `,
    sanityClient,
    { signal }
  )

  let latestUpdates: unknown | undefined

  if (fetchUpdates) {
    const latestUpdatesFilters = [
      '_type == "updatePage"',
      `language == "${document.language}"`,
      'defined(publishDate)',
    ]

    if (tag) {
      latestUpdatesFilters.push(`tags[].title in "${tag}"`)
    }

    latestUpdates = await fetchAndPostProcess(
      `*[${latestUpdatesFilters.join(' && ')}] | order(publishDate desc) [0..4]`,
      sanityClient,
      { signal }
    )
  }

  return {
    latestPressReleases: {
      nodes: latestPressReleases,
    },
    latestUpdates: !!latestUpdates
      ? {
          nodes: latestUpdates,
        }
      : undefined,
    newsListPageLink,
  }
}
