import React, { PropsWithChildren } from 'react'
import { PortableTextBodyElement } from '../utilities/blockContent'
import { RichTextArea } from './PortableText/RichTextArea'
import { TableBlockContentSerializer } from './PortableText/serializers/blocks/TableBlockContentSerializer'
import { TableBlockLinkSerializer } from './PortableText/serializers/marks/TableBlockLinkSerializer'

interface TableBlockCell {
  content: PortableTextBodyElement[]
}

interface TableBlockRow {
  cells: TableBlockCell[]
}

interface CellProps {
  as?: React.ElementType
}

export type TableHeadingType = 'row' | 'column' | 'both'

export interface TableBlockProps {
  headingType?: TableHeadingType
  rows: TableBlockRow[]
}

const Row = ({ children }: PropsWithChildren) => (
  <tr className="border-b border-neutral-lighter">{children}</tr>
)

const Cell = ({ as, children }: PropsWithChildren<CellProps>) => {
  const Element = as ?? 'td'
  return (
    <Element className="text-left px-4 py-7 first:pl-0 last:pr-0">{children}</Element>
  )
}

export const TableBlock = ({ headingType, rows: rowsProps }: TableBlockProps) => {
  let header: JSX.Element | null = null
  let rows = rowsProps

  if (rows.length > 0 && (headingType === 'row' || headingType === 'both')) {
    header = (
      <thead>
        <Row>
          {rows[0].cells.map((cell, index) => (
            <Cell as="th" key={index}>
              <RichTextArea
                components={{
                  block: TableBlockContentSerializer,
                  marks: {
                    link: TableBlockLinkSerializer,
                  },
                }}
                value={cell.content}
              />
            </Cell>
          ))}
        </Row>
      </thead>
    )

    rows = rows.slice(1)
  }

  return (
    <div className="overflow-x-auto mb-30 last:mb-0">
      <table className="w-full border-t border-neutral-lighter">
        {header}
        <tbody>
          {rows.map((row, rowIndex) => (
            <Row key={rowIndex}>
              {row.cells.map((cell, cellIndex) => {
                let cellType: React.ElementType
                if (
                  (headingType === 'column' || headingType === 'both') &&
                  cellIndex === 0
                ) {
                  cellType = 'th'
                } else {
                  cellType = 'td'
                }

                return (
                  <Cell as={cellType} key={`${rowIndex}-${cellIndex}`}>
                    <RichTextArea
                      components={{
                        block: TableBlockContentSerializer,
                        marks: {
                          link: TableBlockLinkSerializer,
                        },
                      }}
                      value={cell.content}
                    />
                  </Cell>
                )
              })}
            </Row>
          ))}
        </tbody>
      </table>
    </div>
  )
}
