import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { CustomAttributes } from '../types/CustomAttributes'
import { ExternalIconSize, Link } from './Link'

export enum ButtonTheme {
  Primary,
  PrimaryDimmed,
  PrimaryInverted,
  Secondary,
  SecondaryGhost,
  SecondaryDark,
  WhiteGhost,
}

export interface ButtonProps {
  additionalClassName?: string
  customAttributes?: CustomAttributes
  disabled?: boolean
  externalIconSize?: ExternalIconSize
  id?: string
  isExternalUrl?: boolean
  /**
   * Controls basically whether to hide the border or not - only supported for ButtonTheme.Primary.
   * */
  lightBorder?: boolean
  lowerCase?: boolean
  /**
   * Click handler, turns component into a button.
   * If property 'url' is set, this will be ignored
   */
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
  square?: boolean
  theme?: ButtonTheme
  title?: string
  type?: 'submit' | 'reset' | 'button'
  /**
   * Link URL, turns component into a link.
   * Prioritized over 'onClick' handler.
   */
  url?: string
}

export const Button = ({
  additionalClassName = '',
  children,
  customAttributes,
  disabled,
  externalIconSize = ExternalIconSize.Lg,
  id,
  isExternalUrl,
  lightBorder,
  lowerCase,
  onClick,
  size = 'medium',
  square,
  theme = ButtonTheme.Primary,
  title,
  type,
  url,
}: PropsWithChildren<ButtonProps>) => {
  let className = classNames(
    additionalClassName,
    'inline-flex items-center group border font-button transition-hover',
    {
      'uppercase': !lowerCase,
      'cursor-not-allowed': disabled,
      'px-30 py-15 text-button-lg': size === 'large',
      'px-30 py-15 text-button-md': size === 'medium',
      'px-10 py-7 text-button-sm': size === 'small',
      'rounded-8': square,
      'rounded': !square,
    }
  )

  switch (theme) {
    case ButtonTheme.Primary:
      className = classNames(className, 'bg-neutral text-primary', {
        'border-neutral-lighter-alt': !lightBorder,
        'border-neutral': lightBorder,

        [classNames(
          'hover:bg-primary hover:text-neutral',
          'focus:ring focus:border-primary focus:outline-none'
        )]: !disabled,

        [classNames(
          'hover:bg-primary hover:border-primary',
          'focus:ring-primary/40 focus:border-primary'
        )]: !lightBorder && !disabled,

        'focus:ring-neutral-medium-light/40': lightBorder && !disabled,

        'text-neutral-medium': disabled,
      })
      break
    case ButtonTheme.PrimaryDimmed:
      className = classNames(className, 'bg-neutral text-secondary-darkest', {
        'border-neutral-lighter-alt': !lightBorder,
        'border-neutral': lightBorder,

        [classNames(
          'hover:bg-primary hover:text-neutral',
          'focus:ring focus:border-primary focus:outline-none'
        )]: !disabled,

        [classNames(
          'hover:bg-primary hover:border-primary',
          'focus:ring-primary/40 focus:border-primary'
        )]: !lightBorder && !disabled,

        'focus:ring-neutral-medium-light/40': lightBorder && !disabled,

        'text-neutral-medium': disabled,
      })
      break
    case ButtonTheme.PrimaryInverted:
      className = classNames(className, {
        [classNames(
          'bg-primary text-neutral border-primary',
          'hover:bg-neutral hover:text-primary',
          'focus:ring focus:ring-primary/40 focus:border-primary focus:outline-none'
        )]: !disabled,

        'bg-neutral-lighter-alt text-neutral-dark-alt border-neutral-lighter-alt':
          disabled,
      })
      break
    case ButtonTheme.Secondary:
      className = classNames(className, 'bg-neutral', {
        [classNames(
          'border-secondary-darker',
          'hover:bg-primary hover:text-neutral hover:border-primary',
          'focus:ring focus:ring-primary/40 focus:border-primary focus:outline-none'
        )]: !disabled,

        'text-neutral-medium border-neutral-lighter-alt': disabled,
      })
      break
    case ButtonTheme.SecondaryDark:
      className = classNames(
        className,
        'text-primary bg-white border-none sm:bg-secondary-darker sm:text-white',
        {
          [classNames(
            'hover:bg-primary sm:hover:bg-secondary-darker/80 hover:text-white',
            'focus:ring focus:ring-primary/40 focus:outline-none'
          )]: !disabled,

          'text-neutral-medium border-neutral-lighter-alt': disabled,
        }
      )
      break
    case ButtonTheme.SecondaryGhost:
      className = classNames(className, 'bg-transparent', {
        [classNames(
          'border-neutral-lighter text-secondary-darker',
          'hover:bg-neutral-lighter',
          'focus:ring focus:ring-neutral-medium-light/40 focus:outline-none'
        )]: !disabled,

        'text-neutral-medium border-neutral-lighter-alt': disabled,
      })
      break
    case ButtonTheme.WhiteGhost:
      className = classNames(className, 'bg-transparent', {
        [classNames(
          'border-neutral-lighter text-neutral-lighter',
          'hover:bg-neutral-lighter hover:text-secondary-darker',
          'focus:ring focus:ring-neutral-medium-light/40 focus:outline-none'
        )]: !disabled,

        'text-neutral-medium border-neutral-lighter-alt': disabled,
      })
  }

  if (url?.length) {
    if (disabled) {
      return <span className={className}>{children}</span>
    }

    return (
      <Link
        className={className}
        customAttributes={customAttributes}
        id={id}
        isExternal={isExternalUrl}
        externalIconSize={externalIconSize}
        title={title}
        url={url}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      className={className}
      id={id}
      onClick={onClick}
      title={title}
      disabled={disabled}
      type={type}
      {...customAttributes}
    >
      {children}
    </button>
  )
}
