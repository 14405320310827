import React from 'react'
import { PageLanguageVersion } from '../../gatsby/utils'
import { useTranslations } from '../../hooks/useTranslations'
import { LinksDropdown, LinksDropdownVariant } from '../LinksDropdown'

export interface LanguageSelectProps {
  additionalClassName?: string
  languageVersions?: PageLanguageVersion[]
}

const languageIdLabelMap = {
  en: 'English',
  sv: 'Swedish',
}

// Note: Since the LanguageSelect is part of the top nav bar, its contents are always in English.
// This might be changed later to display languages in each respective language.
export const LanguageSelect = ({
  additionalClassName,
  languageVersions,
}: LanguageSelectProps) => {
  const translations = useTranslations()

  if (!!languageVersions?.length) {
    const currentLanguageId = translations.language ?? 'en'
    const currentLanguageLabel =
      languageIdLabelMap[currentLanguageId] ?? currentLanguageId

    return (
      <LinksDropdown
        additionalClassName={additionalClassName}
        isFixed
        items={languageVersions.map(languageVersion => ({
          id: languageVersion.language ?? '',
          label:
            languageIdLabelMap[languageVersion.language ?? 'en'] ??
            languageVersion.language,
          url: languageVersion.path ?? '',
        }))}
        placeholderLabel={currentLanguageLabel}
        variant={LinksDropdownVariant.Inline}
      />
    )
  } else {
    return null
  }
}
