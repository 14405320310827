import { useCallback, useEffect, useMemo, useRef } from 'react'

export interface TimerHook {
  isRunning: () => boolean
  start: (handler: (() => void) | null, timeout: number) => void
  stop: () => void
}

export const useTimer = (): TimerHook => {
  const timerRef = useRef<number | null>(null)

  const stop = useCallback(() => {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [])

  // Clear timer on unmount
  useEffect(() => stop, [stop])

  return useMemo(
    () => ({
      isRunning: () => timerRef.current !== null,
      start: (handler: (() => void) | null, timeout: number) => {
        stop()

        timerRef.current = window.setTimeout(() => {
          timerRef.current = null
          handler?.()
        }, timeout)
      },
      stop,
    }),
    [stop]
  )
}
