import { SanityImage } from '../../types/generated/graphql-types'
import { NullCheckedSanityImage } from '../types/NullCheckedSanityImage'

/**
 * Null checks image objects from sanity.
 * Note: This filter is not put into filters.ts due to dependencies to generated typings.
 */
export const validImage = <T extends SanityImage = SanityImage>(
  image: T | null | undefined
): image is NullCheckedSanityImage<T> => !!image?.asset
