import tailwindConfig from '../../tailwind.config'

export enum Breakpoints {
  'mobile' = 'mobile',
  'mobileLg' = 'mobileLg',
  'tb' = 'tb',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  'xxl' = 'xxl',
}

/**
 * Get numerical breakpoint value from a given breakpoint name
 * @param breakpoint Target breakpoint
 */
export const resolveBreakpointName: (breakpoint: Breakpoints) => number = breakpoint => {
  const breakpointWithUnit = tailwindConfig.theme.screens[breakpoint]

  if (!breakpointWithUnit) {
    throw Error(`Breakpoint '${breakpoint}' is not defined`)
  }

  // We are only interested in pixel-based values, but without the unit
  const matches = /(\d+)px/i.exec(breakpointWithUnit)

  // First match is always whole input, second is capture group
  // which is the one we want
  if (!matches?.length || matches.length < 2) {
    throw Error(`Invalid value for breakpoint '${breakpoint}'`)
  }

  return Number(matches[1])
}

/**
 * Compare window size and target breakpoint.
 * Returns a match (true) if window size equals or is greater
 * than target breakpoint value.
 * @param breakpoint Target breakpoint
 */
export const compareToBreakpoint: (breakpoint: Breakpoints) => boolean = breakpoint => {
  // window object does not exist in SSR context
  if (typeof window === undefined) {
    return false
  }

  const breakpointValue = resolveBreakpointName(breakpoint)

  return window.innerWidth >= breakpointValue
}
