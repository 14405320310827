import { Nullable } from '../types/nullable'
import { isNullOrWhiteSpace } from './string'
import time from './time'

export interface SanityNode {
  _id: string
  indexing?: Nullable<string>
  language?: Nullable<string>
  title?: Nullable<string>
  slug?: Nullable<{
    current?: Nullable<string>
  }>
  parentPage?: Nullable<{
    page?: Nullable<SanityNode>
  }>
  _rawParentPage?: Nullable<{
    page?: Nullable<SanityNode>
  }>
  _rawRedirectPage?: {
    page?: Nullable<SanityNode>
  }
  redirectType?: Nullable<string>
  _type?: Nullable<string>
  publishDate?: Nullable<string>
  _updatedAt?: Nullable<string>
}

const addUrlFragments = (node: SanityNode, urlFragments: string[]) => {
  if (node) {
    const parent = node._rawParentPage?.page ?? node.parentPage?.page

    if (parent) {
      addUrlFragments(parent, urlFragments)
    }

    const slug = node.slug?.current

    if (!isNullOrWhiteSpace(slug)) {
      urlFragments.push(slug)
    }
  }
}

export const getUrlFragments = (node: SanityNode): string[] => {
  const urlFragments: string[] = []

  if (
    !isNullOrWhiteSpace(node?.language) &&
    node.language !== process.env.GATSBY_BASE_LANGUAGE
  ) {
    // TODO: If we want to support additional languages in market sites, we need to add ISO 639-1 to
    // URL path mapping here, ie "sv-SE" -> "sv".
    urlFragments.push(node.language.toLowerCase())
  }

  addUrlFragments(node, urlFragments)

  switch (node._type) {
    case 'pressReleasePage':
    case 'updatePage':
      addYearUrlSegment(urlFragments, node)
      break
    default:
      break
  }

  return urlFragments
}

export const getNodeUrl = (node: SanityNode): string =>
  `/${getUrlFragments(node).join('/')}`

/** Takes the team from a SanityPerson and creates a friendly URL segment */
export const getTeamUrlSegment = (team: string | null): string | null =>
  team?.length
    ? team.replace(/[A-Z]/g, upperCaseChar => `-${upperCaseChar.toLocaleLowerCase()}`)
    : null

export const getBasePageId = (pageId: string) => {
  if (pageId.startsWith('drafts.')) {
    pageId = pageId.substring('drafts.'.length)
  }

  return pageId.startsWith('i18n.') ? pageId.split('.')[1] : pageId
}

/**
 * Get the ID of the translated version of the page in the supplied language.
 * Translated pages have the _id i18n.<the default language page _id>.sv
 * @param pageId the _id of the current page
 * @param language the requested language version of the page
 * @returns the _id of the language version of the current page (may or may not exist)
 */
export const getTranslatedPageId = (pageId: string, language: string) => {
  const basePageId = getBasePageId(pageId)

  if (language === process.env.GATSBY_BASE_LANGUAGE) {
    return basePageId
  } else {
    return `i18n.${basePageId}.${language}`
  }
}

/**
 * Adds publish year as the last URL segment (before the page slug)
 * i.e. "/newsroom/news/an-example-page"
 * becomes "/newsroom/news/2021/an-example-page"
 * Requirement: queried node must have a "publishDate" field
 */
export const addYearUrlSegment = (urlFragments: string[], node: SanityNode) => {
  if (urlFragments.length && node?.publishDate) {
    const year = time.toYear(node.publishDate)

    if (year) {
      const slug = urlFragments.pop()

      if (slug) {
        urlFragments.push(year.toString())
        urlFragments.push(slug)
      }
    }
  }
}
