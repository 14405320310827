import {
  SanityAttribute,
  SanityInternalLink,
  SanityLink,
} from '../../types/generated/graphql-types'
import { CustomAttributes } from '../types/CustomAttributes'
import { InternalExternalLink } from '../types/InternalExternalLink'
import { InternalLink } from '../types/InternalLink'
import { BlockIdMap } from '../utilities/blockId'
import { notEmpty } from '../utilities/filters'
import { isNullOrWhiteSpace } from '../utilities/string'

export const mapSanityAttributeArrayToCustomAttributes = (
  attributeArray: SanityAttribute[]
): CustomAttributes =>
  attributeArray.reduce((obj: CustomAttributes, attr: SanityAttribute) => {
    if (!attr || isNullOrWhiteSpace(attr.name)) {
      return obj
    }
    return {
      ...obj,
      [attr.name]: attr.value ?? '',
    }
  }, {})

export const mapSanityInternalLinkToInternalLink = (
  link: SanityInternalLink
): InternalLink => ({
  title: !isNullOrWhiteSpace(link.title)
    ? link.title
    : link.pageReference?.page?.title ?? '',
  url: link.pageReference?.page?.path ?? '',
})

export const mapSanityLinkToInternalExternalLink = (
  link: SanityLink,
  blockIdMap?: BlockIdMap
): InternalExternalLink => {
  const customAttributes = mapSanityAttributeArrayToCustomAttributes(
    link.customAttributes?.filter(notEmpty) ?? []
  )

  let url: string | undefined | null
  let title = link.title

  if (link.isExternalUrl) {
    url = link.url
  } else if (link.pageReference) {
    if (isNullOrWhiteSpace(title)) {
      title = link.pageReference?.page?.title
    }

    url = link.pageReference?.page?.path
  } else if (link.anchorBlock && blockIdMap) {
    const blockName = blockIdMap[link.anchorBlock]
    if (!customAttributes['data-isanchor']) {
      customAttributes['data-isanchor'] = 'true'
    }
    url = blockName ? `#${blockName}` : undefined
  }

  return {
    customAttributes,
    isExternal: link.isExternalUrl ?? false,
    title: title ?? '',
    url: url ?? '',
  }
}
