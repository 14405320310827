import getVideoId from 'get-video-id'
import { isNullOrWhiteSpace } from './string'

export type VideoType = 'vimeo' | 'youtube'

export type VideoInfo = {
  url: URL
  service: VideoType
} | null

export const getVideoUrl = (id: string, type: VideoType): URL => {
  switch (type) {
    case 'vimeo':
      return new URL(`https://player.vimeo.com/video/${id}`)
    case 'youtube':
      return new URL(`https://www.youtube.com/embed/${id}`)
  }
}

export const getValidVideo = (
  url: string | undefined | null,
  videoTypes?: VideoType[]
): VideoInfo | null => {
  if (!isNullOrWhiteSpace(url)) {
    const { id, service } = getVideoId(url)

    if (!isNullOrWhiteSpace(id) && (service === 'vimeo' || service === 'youtube')) {
      if (videoTypes && !videoTypes.includes(service)) {
        return null
      }

      return { url: getVideoUrl(id, service), service }
    }
  }

  return null
}
