import { PortableTextMarkComponent } from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import React from 'react'
import {
  Maybe,
  SanityAttribute,
  SanityDisclaimerPage,
  SanityFileAsset,
  SanityImageAsset,
} from '../../../../../types/generated/graphql-types'
import { mapSanityAttributeArrayToCustomAttributes } from '../../../../mapping/LinkMapper'
import { rewriteDisclaimerFileUrl } from '../../../../utilities/attachment'
import { notEmpty } from '../../../../utilities/filters'
import { getUrlFragments, SanityNode } from '../../../../utilities/routing'
import { isNullOrWhiteSpace } from '../../../../utilities/string'
import { useSiteWideDisclaimer } from '../../../Disclaimer/SiteWide/useSiteWideDisclaimer'
import { Link } from '../../../Link'
import { linkClassName } from './DefaultLinkSerializer'

interface SanityFileAnnotation extends TypedObject {
  asset?: SanityFileAsset | SanityImageAsset
  customAttributes?: Maybe<SanityAttribute>[]
  disclaimerPage?: SanityDisclaimerPage
  isPublic?: boolean
}

// NOTE: to resolve any properties other than _ref from the annotation,
// resolveReferences with a maxDepth must be set in the page query
// when querying _rawBody that has fileAnnotation or imageAnnotation in Sanity.
export const AssetReferenceSerializer: PortableTextMarkComponent<
  SanityFileAnnotation
> = ({ children, value }) => {
  const { asset, customAttributes, disclaimerPage, isPublic } = value ?? {}

  const { currentPagePublic, enabled } = useSiteWideDisclaimer()

  // No need to rewrite file url's if the current page is public
  const siteWideDisclaimerEnabledForThisPage = !currentPagePublic && enabled

  if (asset && !isNullOrWhiteSpace(asset.url)) {
    let url: string | null

    if (
      !isPublic &&
      (!isNullOrWhiteSpace(disclaimerPage?.slug?.current) ||
        siteWideDisclaimerEnabledForThisPage)
    ) {
      if (!isNullOrWhiteSpace(disclaimerPage?.slug?.current)) {
        const disclaimerUrlFragments = getUrlFragments(disclaimerPage as SanityNode)
        const disclaimerPath = `/${disclaimerUrlFragments.join('/')}`

        url = rewriteDisclaimerFileUrl(asset, disclaimerPath)
      } else {
        url = rewriteDisclaimerFileUrl(asset)
      }

      if (url) {
        url = `${url}&dl`
      }
    } else {
      url = `${asset.url}?dl=${asset.originalFilename}`
    }

    if (url) {
      const customAttributesDict = mapSanityAttributeArrayToCustomAttributes(
        customAttributes?.filter(notEmpty) ?? []
      )

      return (
        <Link
          className={linkClassName}
          customAttributes={customAttributesDict}
          download
          isExternal
          url={url}
        >
          {children}
        </Link>
      )
    }
  }

  console.error('Invalid asset reference')

  return <>{children}</>
}
