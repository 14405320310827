import React, { FC, RefObject } from 'react'
import { Pill, Sizes } from '../../../Pill'
import { PortalWrapper } from '../../../PortalWrapper'

export interface PillSerializerProps {
  heading: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portalRef?: RefObject<any>
  shouldRender?: boolean
  size?: Sizes
  text: string
}

export const PillSerializer: FC<PillSerializerProps> = ({
  heading,
  portalRef,
  shouldRender = true,
  size,
  text,
}) => {
  if (!shouldRender) {
    return null
  }

  return (
    <PortalWrapper portalRef={portalRef}>
      <Pill text={text} size={size} heading={heading} />
    </PortalWrapper>
  )
}
