import classNames from 'classnames'
import React, { FC } from 'react'
import { SanityPill } from '../../types/generated/graphql-types'

export enum Sizes {
  'standard' = 'standard',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
}

export interface PillProps {
  additionalClassName?: string
  margins?: string
  size?: Sizes
  heading: string
  text: string
}

export const SanityPillToPillProps = ({
  heading,
  size,
  text,
}: SanityPill): PillProps => ({
  heading: heading ?? '',
  size: (size as Sizes) ?? undefined,
  text: text ?? '',
})

export const Pill: FC<PillProps> = ({
  additionalClassName = '',
  margins = 'my-45 sm:flex-col sm:my-15',
  size = Sizes.standard,
  heading,
  text,
}) => {
  let headingStyle = ''

  switch (size) {
    case Sizes.standard:
    default:
      headingStyle = 'text-t-400 font-t-bold'
      break
    case Sizes.sm:
      headingStyle = 'text-t-700 font-t-light'
      break
    case Sizes.md:
      headingStyle = 'text-t-800 font-t-light'
      break
    case Sizes.lg:
      headingStyle = 'text-t-1000 font-t-light'
      break
  }

  return (
    <div className={classNames('flex justify-start', additionalClassName, margins)}>
      <h3 className={classNames('text-secondary-dark', headingStyle)}>{heading}</h3>

      <p className="ml-30 text-secondary-dark font-t-bold text-t-200 sm:ml-0 sm:mt-15">
        {text}
      </p>
    </div>
  )
}
