import { MainNavigationNode } from '../types/MainNavigationNode'

export const findCurrentPageUrlNodeIndex = (
  nodes: MainNavigationNode[],
  currentPageUrl: string
): number | undefined => {
  for (let i = 0; i < nodes.length; ++i) {
    const node = nodes[i]
    if (node.link.url === currentPageUrl) {
      return i
    } else {
      if (findCurrentPageUrlNodeIndex(node.subitems, currentPageUrl) !== undefined) {
        return i
      }
    }
  }

  return undefined
}
