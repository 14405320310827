import React from 'react'
import { SanityBasicImage } from '../../../../../types/generated/graphql-types'
import { Breakpoints } from '../../../../utilities/breakpoints'
import { validImage } from '../../../../utilities/imageFilters'
import { Presets } from '../../../../utilities/imagePreset'
import { getImagePropsWithSources } from '../../../../utilities/imagePropUtils'
import { Image, ImageProps } from '../../../Image'

export type BasicImageSerializerProps = {
  variant?: 'factBox'
} & SanityBasicImage

export const BasicImageSerializer = ({
  variant,
  ...image
}: BasicImageSerializerProps) => {
  if (validImage(image)) {
    let imageProps: ImageProps | undefined

    switch (variant) {
      default:
      case 'factBox':
        imageProps = getImagePropsWithSources(
          image,
          {
            small: Presets.factBoxImage.small,
            medium: Presets.factBoxImage.medium,
          },
          Breakpoints.tb
        )
        break
    }

    if (imageProps) {
      return (
        <Image
          className="mb-30 last:mb-0"
          imgClassName="w-full rounded-15"
          {...imageProps}
        />
      )
    }
  }

  return null
}
