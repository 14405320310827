import { Script, ScriptStrategy } from 'gatsby'
import React, { FC } from 'react'
import { useSiteSettings } from '../../hooks/useSiteSettings'
import { isNullOrWhiteSpace } from '../../utilities/string'

export interface CookieTractorTableProps {
  declarationUrl?: string
  useSiteSettings?: boolean
  declarationId?: string
  declarationLang?: string
}

/**
 * Table-formatted cookie declaration, fetched from Cookie Tractor.
 */
export const CookieTractorTable: FC<CookieTractorTableProps> = ({
  declarationUrl,
  useSiteSettings: getFromSiteSettings,
  declarationId,
  declarationLang,
}) => {
  const siteSettings = useSiteSettings()
  const cookieTractorId = getFromSiteSettings
    ? siteSettings?.cookieTractorId
    : declarationId

  const cookieTractorLang = getFromSiteSettings
    ? siteSettings?.cookieTractorLang
    : declarationLang

  const url = declarationUrl ?? ''

  return !isNullOrWhiteSpace(cookieTractorId) ? (
    <div>
      <Script
        src={url}
        data-lang={cookieTractorLang}
        data-id={cookieTractorId}
        strategy={ScriptStrategy.idle}
      ></Script>
      <div id="CookieDeclaration"></div>
    </div>
  ) : null
}
