import { RefCallback, useCallback, useEffect, useRef } from 'react'

export const useResizeObserver = <T extends Element>(
  callback: ResizeObserverCallback
): RefCallback<T> => {
  const elementRef = useRef<T | null>(null)

  // Note: ResizeObserver may be undefined in older Safari verisons
  const observerRef = useRef<ResizeObserver | null>(
    typeof window !== 'undefined' && typeof ResizeObserver !== 'undefined'
      ? new ResizeObserver(callback)
      : null
  )

  useEffect(() => {
    if (typeof ResizeObserver !== 'undefined') {
      observerRef.current = new ResizeObserver(callback)
    }
  }, [callback])

  const setRef = useCallback(node => {
    if (observerRef.current) {
      if (elementRef.current) {
        observerRef.current.unobserve(elementRef.current)
      }

      if (node) {
        observerRef.current.observe(node)
      }

      elementRef.current = node
    }
  }, [])

  return setRef
}
