import { Maybe, SanityImage, SanityPhoto } from '../../types/generated/graphql-types'
import imageBuilder from '../sanity/imageBuilder'
import { validImage } from './imageFilters'

/**
 * Preset definition with a specific width and height.
 * Intended to use in conjunction with "fit", so consider this a base type.
 */
export type ExactPresetDefinition = {
  /** Explicit width */
  width: number
  /** Explicit height */
  height: number
}

/**
 * Preset definition for images that should be cropped
 */
export type CropPresetDefinition = ExactPresetDefinition & {
  /** Process image with cropping */
  fit: 'crop'
  /**
   * Crop towards a certain point. Default is 'center'.
   * 'entropy' means image processor will guess what's most important
   * in the image, and attempt to keep it within the cropping range.
   */
  gravity?: 'center' | 'entropy'
}

type FillPresetDefinition = ExactPresetDefinition & {
  /** Scale image to fit, keep dimensions, and fill 'letterbox' with background color */
  fit: 'fill' | 'fillmax'
  /**
   * Background color, any empty space caused by 'letterboxing' will be filled with this color.
   * Valid formats: RGB, ARGB, RRGGBB, AARRGGBB.
   * @example 'ffffff'
   */
  backgroundColor: string

  /**
   * (optional) Pad image with a specified amount of pixels
   */
  pad?: number
}

type ScaleMaxWidthPresetDefinition = {
  /** Scale to maximum width */
  fit: 'scale'
  maxWidth: number
}

export type SourceDefinition<T = PresetDefinition> = {
  extraLarge?: T
  large?: T
  medium?: T
  small: T
}

export type PresetDefinition =
  | CropPresetDefinition
  | FillPresetDefinition
  | ScaleMaxWidthPresetDefinition

export type AspectRatioPresetCollection<T = PresetDefinition> = {
  landscape: T
  portrait: T
  square: T
}

export type AspectRatioSourceCollection<T = PresetDefinition> = {
  landscape: SourceDefinition<T>
  portrait: SourceDefinition<T>
  square: SourceDefinition<T>
}

interface PresetCollection {
  landscape: PresetDefinition

  square: PresetDefinition

  portrait: {
    large: CropPresetDefinition
    tiny: CropPresetDefinition
    medium: CropPresetDefinition
  }

  related: {
    landscape: {
      large: CropPresetDefinition
      medium: CropPresetDefinition
      small: CropPresetDefinition
    }
    portrait: {
      large: CropPresetDefinition
      medium: CropPresetDefinition
      small: CropPresetDefinition
    }
    square: {
      large: CropPresetDefinition
      medium: CropPresetDefinition
      small: CropPresetDefinition
    }
  }

  presetImage: AspectRatioSourceCollection

  thumbnail: PresetDefinition

  window: PresetDefinition

  banner: {
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  businessLine: PresetDefinition

  twoColumnBanner: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  reportsHero: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  categoryTableBlock: {
    landscape: {
      extraLarge: CropPresetDefinition
      large: CropPresetDefinition
      medium: CropPresetDefinition
    }
    portrait: {
      small: CropPresetDefinition
    }
  }

  newsList: {
    landscape: PresetDefinition
    portrait: PresetDefinition
    square: PresetDefinition
  }

  videoPreviewLarge: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  videoPreviewSmall: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  videoPreviewHeader: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  videoHero: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  videoTeaser: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  standardPageHero: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    small: PresetDefinition
  }

  marketParallaxHero: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  parallaxHero: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  parallaxHeroBusinessLine: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  logo: PresetDefinition

  searchResults: AspectRatioPresetCollection

  imageGrid: CropPresetDefinition

  imageTextBox: {
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  imageTextBackground: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  sectionHero: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  largeTeaser: {
    extraLarge: PresetDefinition
    large: PresetDefinition
    medium: PresetDefinition
    small: PresetDefinition
  }

  flashcardPreviewImage: {
    large: PresetDefinition
    small: PresetDefinition
  }

  alternatingTeaser: {
    large: AspectRatioPresetCollection<CropPresetDefinition>
    medium: AspectRatioPresetCollection<CropPresetDefinition>
    small: AspectRatioPresetCollection<CropPresetDefinition>
  }

  contentFeed: {
    extraLarge: AspectRatioPresetCollection<CropPresetDefinition>
    large: AspectRatioPresetCollection<CropPresetDefinition>
    medium: AspectRatioPresetCollection<CropPresetDefinition>
    small: AspectRatioPresetCollection<CropPresetDefinition>
  }

  shareholderTargets: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  motherPowerQFallbackImage: {
    tablet: PresetDefinition
    mobile: PresetDefinition
  }

  subscriptionFormImage: {
    xxLarge: PresetDefinition
    extraLarge: PresetDefinition
    small: PresetDefinition
    mobile: PresetDefinition
  }

  marketArticleContentImage: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    small: CropPresetDefinition
  }

  marketContactPersonsSectionImage: {
    large: CropPresetDefinition
    small: CropPresetDefinition
  }

  careersLandscape: {
    small: CropPresetDefinition
    medium: CropPresetDefinition
    large: CropPresetDefinition
    extraLarge: CropPresetDefinition
  }

  careersPortrait: {
    small: CropPresetDefinition
    medium: CropPresetDefinition
    large: CropPresetDefinition
    extraLarge: CropPresetDefinition
  }

  imageCarousel: {
    image: {
      small: CropPresetDefinition
      medium: CropPresetDefinition
      large: CropPresetDefinition
      extraLarge: CropPresetDefinition
    }
    thumbnail: {
      small: CropPresetDefinition
      medium: CropPresetDefinition
    }
  }

  imagesShowCase: {
    small: CropPresetDefinition
    medium: CropPresetDefinition
    large: CropPresetDefinition
  }

  fullWidthTeaser: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  pageIntroImage: {
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  smallHero: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  factBoxImage: {
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  imageSlideshow: {
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  locationHero: {
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }

  zigzag: {
    extraLarge: CropPresetDefinition
    large: CropPresetDefinition
    medium: CropPresetDefinition
    small: CropPresetDefinition
  }
}

export const Presets: PresetCollection = {
  landscape: { fit: 'crop', width: 705, height: 480 },
  portrait: {
    large: { fit: 'crop', width: 690, height: 840 },
    tiny: { fit: 'crop', width: 45, height: 45 },
    medium: { fit: 'crop', width: 165, height: 165 },
  },
  square: { fit: 'crop', width: 735, height: 735 },

  presetImage: {
    portrait: {
      large: { fit: 'crop', width: 1228, height: 1495 },
      medium: { fit: 'crop', width: 1051, height: 1280 },
      small: { fit: 'crop', width: 690, height: 840 },
    },
    landscape: {
      large: { fit: 'crop', width: 1495, height: 1017 },
      medium: { fit: 'crop', width: 1280, height: 871 },
      small: { fit: 'crop', width: 705, height: 480 },
    },
    square: {
      large: { fit: 'crop', width: 1495, height: 1495 },
      medium: { fit: 'crop', width: 1280, height: 1280 },
      small: { fit: 'crop', width: 735, height: 735 },
    },
  },
  related: {
    landscape: {
      large: { fit: 'crop', width: 330, height: 210 },
      medium: { fit: 'crop', width: 240, height: 163 },
      small: { fit: 'crop', width: 195, height: 120 },
    },
    portrait: {
      large: { fit: 'crop', width: 230, height: 285 },
      medium: { fit: 'crop', width: 180, height: 219 },
      small: { fit: 'crop', width: 150, height: 185 },
    },
    square: {
      large: { fit: 'crop', width: 165, height: 165 },
      medium: { fit: 'crop', width: 105, height: 105 },
      small: { fit: 'crop', width: 85, height: 85 },
    },
  },

  thumbnail: { fit: 'crop', width: 215, height: 215 },

  window: { fit: 'crop', width: 840, height: 570 },

  banner: {
    large: { fit: 'crop', width: 1755, height: 1755 },
    medium: { fit: 'crop', width: 1200, height: 1200 },
    small: { fit: 'crop', width: 840, height: 840 },
  },

  businessLine: { fit: 'scale', maxWidth: 500 },

  twoColumnBanner: {
    extraLarge: { fit: 'crop', width: 1228, height: 1495 },
    large: { fit: 'crop', width: 924, height: 1125 },
    medium: { fit: 'crop', width: 690, height: 840 },
    small: { fit: 'crop', width: 407, height: 495 },
  },

  reportsHero: {
    extraLarge: { fit: 'crop', width: 900, height: 900 },
    large: { fit: 'crop', width: 800, height: 800 },
    medium: { fit: 'crop', width: 700, height: 700 },
    small: { fit: 'crop', width: 600, height: 600 },
  },

  categoryTableBlock: {
    landscape: {
      extraLarge: { fit: 'crop', width: 1050, height: 720 },
      large: { fit: 'crop', width: 840, height: 570 },
      medium: { fit: 'crop', width: 750, height: 510 },
    },
    portrait: {
      small: { fit: 'crop', width: 330, height: 490 },
    },
  },

  newsList: {
    landscape: { fit: 'crop', width: 150, height: 95 },
    portrait: { fit: 'crop', width: 105, height: 150 },
    square: { fit: 'crop', width: 105, height: 105 },
  },

  videoPreviewLarge: {
    extraLarge: { fit: 'crop', width: 2550, height: 1320 },
    large: { fit: 'crop', width: 1920, height: 1005 },
    medium: { fit: 'crop', width: 1050, height: 545 },
    small: { fit: 'crop', width: 390, height: 270 },
  },

  videoPreviewSmall: {
    extraLarge: { fit: 'crop', width: 1245, height: 700 },
    large: { fit: 'crop', width: 930, height: 523 },
    medium: { fit: 'crop', width: 600, height: 338 },
    small: { fit: 'crop', width: 390, height: 220 },
  },

  videoPreviewHeader: {
    extraLarge: { fit: 'crop', width: 2560, height: 1080 },
    large: { fit: 'crop', width: 1440, height: 608 },
    medium: { fit: 'crop', width: 1024, height: 430 },
    small: { fit: 'crop', width: 750, height: 315 },
  },

  videoHero: {
    extraLarge: { fit: 'crop', width: 2560, height: 1440 }, // xl+ 16:9
    large: { fit: 'crop', width: 1920, height: 1080 }, // md, lg 16:9
    medium: { fit: 'crop', width: 1280, height: 720 }, // sm 16:9
    small: { fit: 'crop', width: 1120, height: 630 }, // xs, tb 16:9
  },

  videoTeaser: {
    extraLarge: { fit: 'crop', width: 700, height: 394 }, // lg+
    large: { fit: 'crop', width: 510, height: 287 }, // md
    medium: { fit: 'crop', width: 480, height: 270 }, // sm
    small: { fit: 'crop', width: 390, height: 220 }, // xs, tb
  },

  logo: {
    fit: 'fill',
    width: 120,
    height: 120,
    pad: 20,
    backgroundColor: 'ffffff',
  },

  standardPageHero: {
    extraLarge: { fit: 'crop', width: 805, height: 545 },
    large: { fit: 'crop', width: 690, height: 470 },
    small: { fit: 'crop', width: 330, height: 225 },
  },

  marketParallaxHero: {
    extraLarge: { fit: 'crop', width: 2560, height: 1152 },
    large: { fit: 'crop', width: 1440, height: 591 },
    medium: { fit: 'crop', width: 1050, height: 740 },
    small: { fit: 'crop', width: 750, height: 600 },
  },

  parallaxHero: {
    extraLarge: { fit: 'crop', width: 2550, height: 2760 },
    large: { fit: 'crop', width: 1920, height: 2055 },
    medium: { fit: 'crop', width: 1440, height: 1500 },
    small: { fit: 'crop', width: 750, height: 940 },
  },

  parallaxHeroBusinessLine: {
    extraLarge: { fit: 'crop', width: 2560, height: 1400 },
    large: { fit: 'crop', width: 1920, height: 1000 },
    medium: { fit: 'crop', width: 1440, height: 1230 },
    small: { fit: 'crop', width: 750, height: 990 },
  },

  searchResults: {
    landscape: { fit: 'crop', width: 150, height: 95 },
    portrait: { fit: 'crop', width: 105, height: 150 },
    square: { fit: 'crop', width: 75, height: 75 },
  },

  imageGrid: { fit: 'crop', width: 330, height: 215 },

  imageTextBox: {
    large: { fit: 'crop', width: 1050, height: 712 },
    medium: { fit: 'crop', width: 840, height: 570 },
    small: { fit: 'crop', width: 330, height: 490 },
  },

  imageTextBackground: {
    extraLarge: { fit: 'crop', width: 2550, height: 1487 },
    large: { fit: 'crop', width: 1920, height: 1120 },
    medium: { fit: 'crop', width: 1440, height: 840 },
    small: { fit: 'crop', width: 390, height: 495 },
  },

  sectionHero: {
    extraLarge: { fit: 'crop', width: 1260, height: 855 },
    large: { fit: 'crop', width: 945, height: 645 },
    medium: { fit: 'crop', width: 705, height: 480 },
    small: { fit: 'crop', width: 390, height: 270 },
  },

  largeTeaser: {
    extraLarge: { fit: 'crop', width: 1000, height: 800 },
    large: { fit: 'crop', width: 720, height: 576 },
    medium: { fit: 'crop', width: 600, height: 480 },
    small: { fit: 'crop', width: 500, height: 400 },
  },

  flashcardPreviewImage: {
    large: { fit: 'crop', width: 645, height: 645 },
    small: { fit: 'crop', width: 210, height: 210 },
  },

  alternatingTeaser: {
    large: {
      landscape: { width: 945, height: 746, fit: 'crop' },
      portrait: { width: 945, height: 1197, fit: 'crop' },
      square: { width: 945, height: 945, fit: 'crop' },
    },
    medium: {
      landscape: { width: 450, height: 355, fit: 'crop' },
      portrait: { width: 450, height: 570, fit: 'crop' },
      square: { width: 350, height: 350, fit: 'crop' },
    },
    small: {
      landscape: { width: 395, height: 312, fit: 'crop' },
      portrait: { width: 395, height: 500, fit: 'crop' },
      square: { width: 395, height: 395, fit: 'crop' },
    },
  },

  contentFeed: {
    extraLarge: {
      landscape: { width: 385, height: 247, fit: 'crop' },
      portrait: { width: 285, height: 399, fit: 'crop' },
      square: { width: 195, height: 195, fit: 'crop' },
    },
    large: {
      landscape: { width: 210, height: 135, fit: 'crop' },
      portrait: { width: 150, height: 210, fit: 'crop' },
      square: { width: 105, height: 105, fit: 'crop' },
    },
    medium: {
      landscape: { width: 185, height: 119, fit: 'crop' },
      portrait: { width: 140, height: 196, fit: 'crop' },
      square: { width: 95, height: 95, fit: 'crop' },
    },
    small: {
      landscape: { width: 150, height: 90, fit: 'crop' },
      portrait: { width: 105, height: 150, fit: 'crop' },
      square: { width: 75, height: 75, fit: 'crop' },
    },
  },

  shareholderTargets: {
    extraLarge: { fit: 'crop', width: 2560, height: 1490 },
    large: { fit: 'crop', width: 1440, height: 1125 },
    medium: { fit: 'crop', width: 1024, height: 840 },
    small: { fit: 'crop', width: 750, height: 915 },
  },

  motherPowerQFallbackImage: {
    tablet: { fit: 'crop', width: 1440, height: 590 },
    mobile: { fit: 'crop', width: 390, height: 500 },
  },

  subscriptionFormImage: {
    xxLarge: { fit: 'crop', width: 1228, height: 1495 },
    extraLarge: { fit: 'crop', width: 1051, height: 1280 },
    small: { fit: 'crop', width: 540, height: 405 },
    mobile: { fit: 'crop', width: 330, height: 330 },
  },

  marketArticleContentImage: {
    extraLarge: {
      fit: 'crop',
      width: 853,
      height: 683,
    },
    large: {
      fit: 'crop',
      width: 587,
      height: 470,
    },
    small: {
      fit: 'crop',
      width: 390,
      height: 470,
    },
  },

  marketContactPersonsSectionImage: {
    large: { fit: 'crop', width: 413, height: 401 },
    small: { fit: 'crop', width: 251, height: 244 },
  },

  careersLandscape: {
    small: {
      fit: 'crop',
      width: 750,
      height: 510,
    },
    medium: {
      fit: 'crop',
      width: 640,
      height: 445,
    },
    large: {
      fit: 'crop',
      width: 960,
      height: 667,
    },
    extraLarge: {
      fit: 'crop',
      width: 1280,
      height: 890,
    },
  },

  careersPortrait: {
    small: {
      fit: 'crop',
      width: 750,
      height: 890,
    },
    medium: {
      fit: 'crop',
      width: 640,
      height: 780,
    },
    large: {
      fit: 'crop',
      width: 960,
      height: 1170,
    },
    extraLarge: {
      fit: 'crop',
      // Apply _at most_ a height that makes the image square in xl, more than 1280 height is way too large
      width: 1280,
      height: 1280,
    },
  },

  imageCarousel: {
    image: {
      small: {
        fit: 'crop',
        width: 690,
        height: 517,
      },
      medium: {
        fit: 'crop',
        width: 767,
        height: 432,
      },
      large: {
        fit: 'crop',
        width: 1050,
        height: 591,
      },
      extraLarge: {
        fit: 'crop',
        width: 1770,
        height: 996,
      },
    },
    thumbnail: {
      small: {
        fit: 'crop',
        width: 239,
        height: 135,
      },
      medium: {
        fit: 'crop',
        width: 419,
        height: 236,
      },
    },
  },

  imagesShowCase: {
    small: {
      fit: 'crop',
      width: 360,
      height: 203,
    },
    medium: {
      fit: 'crop',
      width: 510,
      height: 287,
    },
    large: {
      fit: 'crop',
      width: 600,
      height: 338,
    },
  },

  fullWidthTeaser: {
    extraLarge: { fit: 'crop', width: 855, height: 634 },
    large: { fit: 'crop', width: 590, height: 472 },
    medium: { fit: 'crop', width: 810, height: 601 },
    small: { fit: 'crop', width: 330, height: 264 },
  },

  pageIntroImage: {
    medium: { fit: 'crop', width: 590, height: 472 },
    small: { fit: 'crop', width: 284, height: 211 },
  },

  smallHero: {
    extraLarge: { fit: 'crop', width: 2560, height: 275 },
    large: { fit: 'crop', width: 1920, height: 275 },
    medium: { fit: 'crop', width: 1280, height: 275 },
    small: { fit: 'crop', width: 750, height: 275 },
  },

  factBoxImage: {
    medium: { fit: 'crop', width: 584, height: 329 },
    small: { fit: 'crop', width: 442, height: 248 },
  },

  imageSlideshow: {
    large: { fit: 'crop', width: 1000, height: 571 },
    medium: { fit: 'crop', width: 920, height: 526 },
    small: { fit: 'crop', width: 480, height: 320 },
  },

  locationHero: {
    large: { fit: 'crop', width: 1600, height: 1200 },
    medium: { fit: 'crop', width: 920, height: 374 },
    small: { fit: 'crop', width: 618, height: 792 },
  },

  zigzag: {
    extraLarge: { fit: 'crop', width: 1000, height: 800 },
    large: { fit: 'crop', width: 720, height: 576 },
    medium: { fit: 'crop', width: 600, height: 480 },
    small: { fit: 'crop', width: 500, height: 400 },
  },
}

export type ImageTypes =
  | Maybe<SanityPhoto>
  | Maybe<SanityImage>
  | SanityPhoto
  | SanityImage
  | undefined

/**
 * Get a full image URL for a processed image based on a preset
 * @param image The image to process
 * @param preset Predefined dimensions
 * @param quality (optional) Quality of the image, a number with the range 0-100
 */
export const imagePresetUrl = (
  image: ImageTypes,
  preset: PresetDefinition | null,
  quality?: number
): string | null => {
  if (validImage(image)) {
    if (!preset) {
      return imageBuilder.image(image).auto('format').url()
    }

    let builder = imageBuilder.image(image)

    switch (preset.fit) {
      case 'crop':
        builder = builder.size(preset.width, preset.height).fit(preset.fit)

        if (preset.gravity) {
          builder = builder.crop(preset.gravity)
        }
        break

      case 'fill':
      case 'fillmax':
        builder = builder
          .size(preset.width, preset.height)
          .fit(preset.fit)
          .focalPoint(0.5, 0.5) // Center
          .bg(preset.backgroundColor)

        if (preset.pad) {
          builder = builder.pad(preset.pad)
        }

        break

      case 'scale':
        builder = builder.maxWidth(preset.maxWidth).fit(preset.fit)
        break
    }

    if (quality !== undefined && quality >= 0 && quality <= 100) {
      builder = builder.quality(quality)
    }

    return builder.auto('format').url()
  } else {
    return null
  }
}

/**
 * Get closest matching preset for a sourced image
 * @param image The image to match with a preset
 * @param preferSmall (Optional) Prefer variant of resulting preset with smaller dimension values, if applicable
 */
export const getMatchingPreset = (
  image: SanityPhoto | SanityImage,
  presets: AspectRatioPresetCollection
): PresetDefinition | null => {
  const aspectRatio = image.asset?.metadata?.dimensions?.aspectRatio
  if (!aspectRatio) {
    return null
  }

  if (aspectRatio > 1) {
    return presets.landscape
  }

  if (aspectRatio < 1) {
    return presets.portrait
  }

  return presets.square
}

/**
 * Get closest matching preset for a sourced image
 * @param image The image to match with a preset
 * @param AspectRatioSourceCollection The preset collection to return SourceDefinition from depending on landscape, portrait, square
 */
export const getMatchingSourcePreset = (
  image: SanityPhoto | SanityImage,
  presets: AspectRatioSourceCollection
): SourceDefinition | null => {
  const aspectRatio = image.asset?.metadata?.dimensions?.aspectRatio
  if (!aspectRatio) {
    return null
  }

  if (aspectRatio > 1) {
    return presets.landscape
  }

  if (aspectRatio < 1) {
    return presets.portrait
  }

  return presets.square
}
