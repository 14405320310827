import { useStaticQueryWithPreview } from '@tedgustaf/gatsby-plugin-sanity-preview'
import { graphql, useStaticQuery } from 'gatsby'
import { Settings } from 'luxon'
import { SanitySiteSettingsConnection } from '../../types/generated/graphql-types'
import { siteSettingsPreviewQueryProjection } from '../preview/staticQueries/siteSettings'

type QueryResult = {
  allSanitySiteSettings?: SanitySiteSettingsConnection
}

export const useSiteSettings = () => {
  const queryResult = useStaticQueryWithPreview({
    staticQueryData: useStaticQuery<QueryResult>(graphql`
      query siteSettingsQuery {
        allSanitySiteSettings {
          nodes {
            gtmId
            allowedIframeDomains
            greenHouseJobToken
            cookieTractorId
            cookieTractorLang
            cookieTractorUrl
            language
          }
        }
      }
    `),
    query: siteSettingsPreviewQueryProjection,
  })

  let siteSettings = queryResult?.allSanitySiteSettings?.nodes?.[0]
  if (
    !!Settings.defaultLocale &&
    Settings.defaultLocale !== siteSettings?.language &&
    !!queryResult?.allSanitySiteSettings?.nodes &&
    queryResult?.allSanitySiteSettings?.nodes?.findIndex(
      node => node.language === Settings.defaultLocale
    ) > -1
  ) {
    siteSettings = queryResult?.allSanitySiteSettings?.nodes?.find(
      node => node.language === Settings.defaultLocale
    )
  }

  return siteSettings
}
