exports.components = {
  "component---src-pages-404-not-found-tsx": () => import("./../../../src/pages/404-not-found.tsx" /* webpackChunkName: "component---src-pages-404-not-found-tsx" */),
  "component---src-templates-business-line-tsx": () => import("./../../../src/templates/BusinessLine.tsx" /* webpackChunkName: "component---src-templates-business-line-tsx" */),
  "component---src-templates-business-segment-tsx": () => import("./../../../src/templates/BusinessSegment.tsx" /* webpackChunkName: "component---src-templates-business-segment-tsx" */),
  "component---src-templates-campaign-page-tsx": () => import("./../../../src/templates/CampaignPage.tsx" /* webpackChunkName: "component---src-templates-campaign-page-tsx" */),
  "component---src-templates-career-list-page-tsx": () => import("./../../../src/templates/CareerListPage.tsx" /* webpackChunkName: "component---src-templates-career-list-page-tsx" */),
  "component---src-templates-company-page-tsx": () => import("./../../../src/templates/CompanyPage.tsx" /* webpackChunkName: "component---src-templates-company-page-tsx" */),
  "component---src-templates-eqt-network-page-tsx": () => import("./../../../src/templates/EqtNetworkPage.tsx" /* webpackChunkName: "component---src-templates-eqt-network-page-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../../../src/templates/EventPage.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-media-news-list-page-tsx": () => import("./../../../src/templates/media/NewsListPage.tsx" /* webpackChunkName: "component---src-templates-media-news-list-page-tsx" */),
  "component---src-templates-media-reports-and-presentations-page-tsx": () => import("./../../../src/templates/media/ReportsAndPresentationsPage.tsx" /* webpackChunkName: "component---src-templates-media-reports-and-presentations-page-tsx" */),
  "component---src-templates-media-reports-page-tsx": () => import("./../../../src/templates/media/ReportsPage.tsx" /* webpackChunkName: "component---src-templates-media-reports-page-tsx" */),
  "component---src-templates-on-demand-press-release-page-tsx": () => import("./../../../src/templates/OnDemandPressReleasePage.tsx" /* webpackChunkName: "component---src-templates-on-demand-press-release-page-tsx" */),
  "component---src-templates-people-page-tsx": () => import("./../../../src/templates/PeoplePage.tsx" /* webpackChunkName: "component---src-templates-people-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/Person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-portfolio-current-portfolio-page-tsx": () => import("./../../../src/templates/portfolio/CurrentPortfolioPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-current-portfolio-page-tsx" */),
  "component---src-templates-portfolio-divestments-page-tsx": () => import("./../../../src/templates/portfolio/DivestmentsPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-divestments-page-tsx" */),
  "component---src-templates-portfolio-fund-page-tsx": () => import("./../../../src/templates/portfolio/FundPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-fund-page-tsx" */),
  "component---src-templates-portfolio-funds-list-page-tsx": () => import("./../../../src/templates/portfolio/FundsListPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-funds-list-page-tsx" */),
  "component---src-templates-press-release-page-tsx": () => import("./../../../src/templates/PressReleasePage.tsx" /* webpackChunkName: "component---src-templates-press-release-page-tsx" */),
  "component---src-templates-search-results-page-tsx": () => import("./../../../src/templates/SearchResultsPage.tsx" /* webpackChunkName: "component---src-templates-search-results-page-tsx" */),
  "component---src-templates-sectioned-article-page-tsx": () => import("./../../../src/templates/SectionedArticlePage.tsx" /* webpackChunkName: "component---src-templates-sectioned-article-page-tsx" */),
  "component---src-templates-shareholders-articles-of-association-page-tsx": () => import("./../../../src/templates/shareholders/ArticlesOfAssociationPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-articles-of-association-page-tsx" */),
  "component---src-templates-shareholders-calendars-and-events-page-tsx": () => import("./../../../src/templates/shareholders/CalendarsAndEventsPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-calendars-and-events-page-tsx" */),
  "component---src-templates-shareholders-dividend-page-tsx": () => import("./../../../src/templates/shareholders/DividendPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-dividend-page-tsx" */),
  "component---src-templates-shareholders-share-capital-development-page-tsx": () => import("./../../../src/templates/shareholders/ShareCapitalDevelopmentPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-share-capital-development-page-tsx" */),
  "component---src-templates-shareholders-share-price-page-tsx": () => import("./../../../src/templates/shareholders/SharePricePage.tsx" /* webpackChunkName: "component---src-templates-shareholders-share-price-page-tsx" */),
  "component---src-templates-shareholders-shareholder-people-tsx": () => import("./../../../src/templates/shareholders/ShareholderPeople.tsx" /* webpackChunkName: "component---src-templates-shareholders-shareholder-people-tsx" */),
  "component---src-templates-shareholders-shareholder-structure-page-tsx": () => import("./../../../src/templates/shareholders/ShareholderStructurePage.tsx" /* webpackChunkName: "component---src-templates-shareholders-shareholder-structure-page-tsx" */),
  "component---src-templates-shareholders-shareholders-main-page-tsx": () => import("./../../../src/templates/shareholders/ShareholdersMainPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-shareholders-main-page-tsx" */),
  "component---src-templates-shareholders-shareholders-meeting-page-tsx": () => import("./../../../src/templates/shareholders/ShareholdersMeetingPage.tsx" /* webpackChunkName: "component---src-templates-shareholders-shareholders-meeting-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/StandardPage.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */),
  "component---src-templates-update-page-tsx": () => import("./../../../src/templates/UpdatePage.tsx" /* webpackChunkName: "component---src-templates-update-page-tsx" */),
  "component---src-templates-video-campaign-page-tsx": () => import("./../../../src/templates/VideoCampaignPage.tsx" /* webpackChunkName: "component---src-templates-video-campaign-page-tsx" */)
}

